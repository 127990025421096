﻿
/*font-size*/
$font-size-main: 10px;
$font-size-x-large: 2.2rem;
$font-size-large: 1.6rem;
$font-size-fs15:1.5rem;
$font-size-normal: 1.4rem;
$font-size-fs13:1.3rem;
$font-size-small: 1.2rem;
$font-size-fs11:1.1rem;
$font-size-x-small: 1rem;
$font-size-fs16: 1.6rem;
$font-size-fs18: 1.8rem;
$font-size-fs24: 2.4rem;
$font-size-fs20: 2rem;
$font-size-fs28: 2.8rem;


/*font-weight*/
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/*font-family*/
$font-family-standard: 'Open Sans', sans-serif;

$table-header-text-size: 1.6rem;
$table-content-text-size: $font-size-large !default;

$button-content-text-size:1.6rem;

$header-height: 58px;
$sub-header-height: 162px;
