.filters-container {
  background: $orion-steel-blue;
  color: $white;
  padding-left: 0;
  // height: auto;
  height: calc(100vh - 270px);
  .filter-icon {
    position: absolute;
    top: 22px;
  }
  &.collapse-filter {
    height: 40px;
    margin-top: 14px;
    background: #0c2340;
    .filter-icon-container {
      // height: inherit;
      // padding: 0.5rem 2rem;
      top: -10px;
    }
  }
  .filter-icon-container {
    display: flex;
    align-items: center;
    padding: 2rem;
    position: relative;
  }
  .filter-header {
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    padding-left: 5rem;
  }
  .title {
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    font-weight: normal;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    //position: relative;
    border-bottom: 1px solid $white;
    text-align: left;
    .click-scope {
      height: 3.2rem;
    }
    &.is-expanded {
      transition: background 0.5s;
    }
  }

  .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s;

    ul {
      margin: 0;
      opacity: 0;
      transition: 0.8s;
      // max-height: 100vh;
      li {
        list-style: none;
        padding: 0.3rem 0;
        max-height: 100vh;
        // min-height:4rem;
      }
    }

    &.is-expanded {
      max-height: 5000px;
      border-bottom: 1px solid $white;
      // height: 44.5rem;
      ul {
        opacity: 1;
      }
    }
  }
  .accordion-item-icon {
    transform: rotate(180deg);
    height: 18px;
    width: 22px;
    cursor: pointer;
  }
  .accordion-item-icon-closed {
    transform: rotate(90deg);
    height: 18px;
    width: 22px;
    cursor: pointer;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .checkmark {
    border: 1px solid $white;
    background-color: $orion-steel-blue;
  }
}

.ata-container {
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid $white;
  padding: 0 2rem 2rem;
  .input-field {
    padding-top: 0px;
  }
  input {
    background: #41748d;
    color: $white;
    border: 1px solid $white;
    border-radius: 4px;
    height: 4rem;
    &::placeholder,
    &:focus {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white;
      opacity: 1; /* Firefox */
      background: #41748d;
    }
    &:-ms-input-placeholder,
    &:focus {
      /* Internet Explorer 10-11 */
      color: $white;
      border-color: $white;
      background: #41748d;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.filter-icon {
  cursor: pointer;
}
/* Create a custom checkbox */

.cusutomCheckbox {
  font-size: 1.4rem;
  position: relative;
  display: flex;
  input {
    height: 0px;
  }
  &:hover input ~ .checkmark {
    background-color: $white;
    outline: 0;
    border: 2px solid $orion-steel-blue;
    border-radius: 6px;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}
/* When the checkbox is checked, add a purple background */
.cusutomCheckbox input:checked ~ .checkmark {
  background-color: $white;
  border: 1px solid $orion-steel-blue;
}
.cusutomCheckbox input:disabled ~ .checkmark {
  background-color: $orion-steel-blue;
  border: 1px solid $orion-steel-blue;
  cursor: not-allowed;
  box-shadow: none;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.cusutomCheckbox input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.cusutomCheckbox .checkmark:after {
  left: 30%;
  top: 2%;
  width: 7px;
  height: 13px;
  border: solid $orion-steel-blue;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(41deg);
  -ms-transform: rotate(41deg);
  transform: rotate(41deg);
}
.cusutomCheckbox .customDays-HrsFilter {
  width: 45px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #000;
}
.days-filter.RadioButton label{
  padding: 0 5px 0 30px;
}
.days-filter.RadioButton{
  input[type=number]{
    width: 35px;
    margin-right: 5px;
  }
  input[type="radio"]:checked + label:after {
    background: #FFFFFF!important;
  }
}
.days-filter.RadioButton label:before {
  border-color: #FFFFFF!important;
}
.zone {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $white;
}
.border-top-white {
  border-top: 1px solid $white;
}
.subzone {
  border-bottom: 1px solid $white;
}
.btn-section {
  display: flex;
  justify-content: space-between;
  margin: 2em 1rem;
  button {
    width: 14rem;
    &.btn-primary {
      color: $white;
      background: $orion-steel-blue;
      border: 1px solid $white;
      &:hover {
        width: 14rem;
      }
    }
    &.btn-secondary {
      height: 4.6rem;
      color: $orion-steel-blue;
      background: $white;
      border: 0.1px solid $orion-steel-blue;
    }
  }
}
.rotate-90 {
  height: 16px;
  cursor: pointer;
  svg {
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
  }
}
.rotate-270 {
  height: 16px;
  cursor: pointer;
  svg {
    transform: rotate(270deg);
    transition: transform 0.3s ease-in-out;
  }
}
.nonroutine-filters {
  min-height: calc(100vh - 700px);
  max-height: calc(100vh - 425px);
  // height: calc(100vh - 400px);
  overflow-y: auto;
  overflow-x: hidden;
  .ata-container{
    .ata-lookup-wrapper{
      .ata-lookup{
        border: 1px solid #fff;
        background-color: #41748d;
      }
    }
  }
  .day-hr-input {
    margin-left: 20px;
    margin-top: 10px;
    color: #950426;
  }
}
.history-filters {
  padding: 0 2rem 1rem 2rem;
  .radio-container {
    flex-direction: column;
    .RadioButton {
      margin: 8px 0;
      &:last-child{
        margin-bottom: 18px;
      }
      label:before {
        border-color: $white;
      }
      input[type="radio"]:checked + label:before {
        border-color: $white;
      }
      input[type="radio"]:checked + label:after {
        background: $white;
      }
    }
  }
}
