﻿@import "../utilities/_variables.scss";

fieldset {
    font-size: $font-size-normal;

    legend {
        font-size: 2.4rem;
        font-weight: $font-weight-light;
        border-bottom: 1px solid $silver-gray;
        padding-top: 20px;
        //margin-bottom: 20px;
    }

    label {
        font-weight: $font-weight-normal;
        display: block;
        margin-bottom: 7px;
        color: $dove-gray;

        &.disabled {
            color: $nobel-gray;
        }

        &.forecast-confirm {
            color: $black;
        }
    }

    input[type="checkbox"] {
        &.disabled {
            opacity: 0.6;
        }
    }

    .row,
    .form-row {
        margin-bottom: 1rem;
    }

    h3 {
        margin-left: 15px;
    }
}

select,
textarea {
    border-radius: 0;
    font-size: $font-size-normal;
    display: inline-block;
    padding: 6px 9px;
    line-height: 20px;
    vertical-align: middle;
    border: 1px solid $silver-gray;
    -webkit-appearance: none;
}

select,
textarea {
    height: 40px;
}

.form-control {
    border-radius: 0;

    &:disabled {
        border: 1px solid $silver-gray;
        color: $nobel-gray;
        background-color: $white;
    }
}

.custom-select {
    border-radius: 0;
    // background: $white url('../../../images/global/icons/icon-dropdown.png') no-repeat right 5px center;
    padding: 0.375rem 2rem 0.375rem 0.75rem;
    height: 40px;
    color: $content-color;

    &:disabled {
        // background: $white url('../../../images/global/icons/dropdown-disabled.png') no-repeat right;
        border: 1px solid $silver-gray;
        color: $nobel-gray;
        background-color: $white;
    }
}

.remarks-character-count {
    margin-top: -20px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 500;
    color: $dove-gray;
}

.remarks-character-count-reached {
    color: $cardinal-red;
}

.password-container,
.comments-container {
    margin: 2rem 0;
    font-size: $font-size-normal;

    label {
        display: block;
        font-weight: $font-weight-normal;

        &.disabled {
            color: $nobel-gray;
        }
    }
}
input[type="text"],
input[type="password"] {
    border: 1px solid $night-rider-gray;
    border-radius: 6px;
    border-radius: 6px;
    width: 20rem;
    height: 4.5rem;
    &:focus {
        outline: 0;
        border: 2px solid $united-purple;
        border-radius: 6px;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    &:disabled {
        border: 1px solid #666666;
        border-radius: 6px;
        background-color: #e6e6e6;
        cursor: not-allowed;
    }
}
label.disabled {
    color: #333333;
    opacity: 0.7;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
    margin: 0;
}
