.visit-details {
    margin: 0 auto;
    .visit-details-section {
        min-width: 46rem;
        background-color: rgba(12, 35, 64, 0.9);
        border-bottom-right-radius: 0.6rem;
        border-bottom-left-radius: 0.6rem;
        &-content {
            font-size: 1.4rem;
            color: #ffffff;
            min-width: 450px;
            .aircraft-no {
                font-size: 1.6rem;
                text-align: center;
                .link {
                    text-decoration: underline;
                    color: $white;
                    cursor: pointer;
                }
            }
            .aircraft-label {
                padding-right: 0.5rem;
            }
            .aircraft-no span {
                font-weight: $font-weight-bold;
            }
            .aircraft-details {
                padding-bottom: 2rem;
            }
            .plane-img {
                transform: rotate(-90deg);
                height: 40px;
            }
            .value {
                font-size: 2.4rem;
                font-weight: $font-weight-bold;
            }
            .equip-value {
                font-size: 1.6rem;
                font-weight: $font-weight-bold;
            }
            label {
                margin-bottom: 0rem;
            }
            .icon-sets {
                padding-bottom: 1rem;
                svg {
                    height: 2.4rem;
                }
                span {
                    padding-left: 0.5rem;
                }
            }
            .visit-scheduling-details {
                display: flex;
                justify-content: space-between;
                padding: 20px;
                & > div {
                    padding-right: 20px;
                }
                .visit-workload-info {
                    width: 160px;
                    .workload-value {
                        font-weight: 600;
                    }
                }
                .inducted-ac-icon,
                .preinducted-ac-icon,
                .etr-ac-icon {
                    height: 24px;
                }
            }
        }
    }
}

.chevron-click {
    cursor: pointer;
    margin: 0 auto;
    border-radius: 0 0 6px 6px;
    background-color: rgba(12, 35, 64, 0.9);
    height: 2.4rem;
    width: 4.5rem;
    text-align: center;
}

.expand-visit {
    svg {
        transform: rotate(90deg);
    }
}
.collapse-visit {
    svg {
        transform: rotate(-90deg);
    }
}
