.addvisit.form-group.acinfo.row { 
 .rbt-input-hint {
    color: rgba(0, 0, 0, 0) !important;
  }
    margin-left: 0px;
    height: 75px;
  .col-xs-2 {
      margin-right: 100px;
  }
  .col-xs-2 label {
    font-family: Open Sans;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
  }  
  .form-control {
      font-size: 14px;
  }
  #location {
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 6px;
    height: 45px;
  }
}