.shift-selector{
    //display:inline-block;
    position:relative;
    border: 1px solid $dusty-gray;
    width:auto;
    background-color: #E6E6E6;
    border-radius: 6px;
    white-space: nowrap;
}
.shift-date>.date, .shift>.shift-number, .shift>.shift-type {
    justify-content: center;
    align-items: center;
    display:flex;
    font-weight: $font-weight-bold;
}
.shift-date {
    border-right: 1px solid $dusty-gray;
    display:inline-block;
    position:relative;
    .date{
        width:210px;
        height:40px;
    }
    &:last-child
    {
        border-width: 0px;
    }
}

.shift{
    height:70px;
    width:70px;
    border-color: $dusty-gray;
    border-right: 1px solid $dusty-gray;
    cursor: pointer;
    .shift-type {
        background-color: $orion-steel-blue;
        height:40px;
    }
    .shift-number {
        height:30px;
    }
    &:last-child{
        border-width: 0px;
    }
    &:hover{
        .shift-number {
            background-color: $united-purple;
            color: #FFFFFF;
        }
        .shift-type {
            background-color: $rhapsody-blue;
        }
    }
}
.shift-disabled{
    .shift-type {
        background-color: rgba(65,116,141,0.73);
    }
    pointer-events: none;
}
.selected-shift {
    .shift-number {
        background-color: $united-purple;
        color: #FFFFFF;
    }
    .shift-type {
        background-color: $rhapsody-blue;
    }
}