.accountability-report {
    height: calc(100vh - 225px);
    overflow-y: auto;
    .printable-visit-details {
        display: none;
    }
}

@media print {
    .search-accordion {
        display: block;
        page-break-before: auto;
        page-break-inside: avoid;
    }
}

.accountability-report-print {
    .accountability-date {
        svg {
            height: 24px;
        }
    }
    .printable-visit-details {
        display: block;
        max-width: 1577px;
        margin: 0 auto;
        width: 100%;
        padding: 0 40px;
        .visit-info {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            padding-bottom: 20px;
            .value {
                font-weight: $font-weight-bold;
            }
            svg {
                height: 24px;
            }
        }
    }
    .search-accordion {
        .search-accordion-item {
            border: 2px solid #666666;
            box-shadow: none;
        }
        .search-accordion-item-header {
            background-color: $white;
            .accordion-icon {
                display: none;
            }
            .dash8-card .card-desc-holder {
                max-width: calc(100% - 550px);
            }
        }
        // .search-accordion-item-header.searchactive + .search-accordion-item-body {
        //     max-height: none;
        // }
        .search-accordion-item-body {
            border-color: $white;
            border-width: 0px;
            //max-height: none;
        }

        // .search-accordion-item-body > div:last-child {
        //     margin-bottom: 67px !important;
        // }
        // .actions-container:last-child {
        //     top:55px;
        //     left: -50px;
        // }
        .search-accordion-item-body-content {
            display: block;
        }
        .routine-card .card-details .position-serial .position-code,
        .routine-card .card-details .position-serial .status-code,
        .routine-card .card-details .position-serial .date,
        .routine-card .card-details .position-serial .serial-number,
        .routine-card .card-details .accountability-status .position-code,
        .routine-card .card-details .accountability-status .status-code,
        .routine-card .card-details .accountability-status .date,
        .routine-card .card-details .accountability-status .serial-number,
        .routine-card .card-details .accountability-date .position-code,
        .routine-card .card-details .accountability-date .status-code,
        .routine-card .card-details .accountability-date .date,
        .routine-card .card-details .accountability-date .serial-number {
            align-self: center;
        }
        .routine-card {
            border-color: #666666;

            .card-details .accountability-status .status-code .accountability-status-value {
                background-color: $white;
                border: 1px solid #666666;
                border-radius: 6px;
            }
        }
        .accountability-status-value {
            background-color: red;
        }
    }
}
.accountability-expand-collapse {
    position: fixed;
    top: 170px;
    right: 179px;
    color: #6244bb;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
}


