.subtask-card,
.wrkload-group-card {
    display: flex;
    font-size: 16px;
    margin-top: 10px;

    .sort-icon {
        padding-right: 2rem;
        height: 16px;
    }

    .subtask-details-container {
        width: 100%;
    }

    .subtask-details-container,
    .wrkload-group-details-container {
        // width: calc(100% - 65px);
        border: 1px solid #949494;
        border-radius: 6px;
        display: flex;
        padding: 0px 20px 10px 20px;
        // margin-left: auto;
        // margin-right: auto;
        background: $white;

        .subtask-details,
        .wrkload-group-details {
            display: flex;
            flex-wrap: wrap;

            .subtask-number {
                padding-top: 10px;
                font-size: 20px;
                font-weight: 600;
                padding-right: 20px;
                min-width: 150px;
                align-self: center;
            }

            .subtask-desc,
            .wrkload-group-desc {
                padding-top: 10px;
                flex-grow: 10;
                min-width: 40%;
                line-height: normal;
                align-self: center;
                padding-right: 30px;
                word-break: break-all;
            }

            .wrkload-group-desc {
                font-weight: 600;
                min-width: 100px;
                padding-top: 0px;
                flex: 40% 0 0;
            }

            .subtask-contact {
                padding-top: 10px;
                min-width: 250px;
                max-width: 250px;
                line-height: normal;
                align-self: center;
                padding-right: 30px;
            }

            .wrkload-group-taskcard {
                //padding-top: 10px;
                min-width: 16rem;
                max-width: 16rem;
                line-height: normal;
                align-self: center;
                padding-right: 3rem;

                .wkload-dash-number-label {
                    font-size: 1.4rem;
                    color: #333333;
                }

                .wkload-dash-number-value {
                    font-size: 1.4rem;
                    color: #333333;
                    font-weight: bold;

                    a {
                        color: $united-purple;
                    }
                }
            }

            .wrkload-group-workstop {
                width: 60px;
                text-align: center;
                padding-right: 15px;
            }

            .wrkload-group-card-ddl {
                //padding-top: 1rem;
                min-width: 6.5rem;
                max-width: 6.5rem;
                line-height: normal;
                align-self: center;
                margin-right: 2.5rem;
            }

            .subtask-icon-holder {
                padding-top: 10px;
                min-width: 200px;
                max-width: 200px;
                display: flex;
                justify-content: space-between;
                padding-right: 30px;
                align-self: center;

                .insp-icon {
                    width: 30px;
                    height: 20px;
                }
            }

            .wrkload-group-icon-holder {
                //padding-top: 10px;
                min-width: 100px;
                max-width: 100px;
                justify-content: space-between;
                padding-right: 30px;
                align-self: center;

                .insp-icon {
                    width: 30px;
                    height: 20px;
                }
            }

            .wrkload-transfer-icon-holder {
                justify-content: space-between;
                padding-right: 15px;
                align-self: center;

                svg {
                    width: 18px;
                    height: 24px;
                }
            }
        }

        .subtask-more-info {
            align-self: center;
            padding-top: 10px;

            .info-icon {
                width: 24px;
                height: 24px;
                border: 1px solid #333333;
                border-radius: 12px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .wrkload-group-details-container {
        padding: 10px;
        display: block;
        width: 100%;

        .wrkload-group-details {
            flex-wrap: nowrap;
        }
    }

    .subtask-actions {
        width: 65px;
        padding-left: 15px;
        min-width: 65px;
        min-width: 65px;
        align-self: center;
    }
}

.subtask-history-card {
    font-size: 16px;
    margin-top: 10px;
    border: 1px solid #949494;
    border-radius: 6px;
    padding: 0px 20px 10px 20px;

    .subtask-history-info {
        display: flex;
        flex-wrap: wrap;

        .icon-container {
            svg {
                height: 25px;
            }
        }
    }

    .activity-type {
        font-weight: $font-weight-bold;
        padding-right: 100px;
        padding-top: 15px;
        color: #0C2340;
        width: 150px;
    }

    .activity-contact {
        padding-top: 15px;
    }

    .activity-comments {
        padding-top: 20px;
    }

    .insp-type {
        padding-top: 15px;
        font-weight: $font-weight-bold;
        color: #0C2340;
    }
}

.nr-signoff-modal,
.nr-subtask-info-modal,
.nr-addsubtask-modal {
    min-height: 75rem;
    max-width: 120rem;
    margin: 4rem;
    padding: 0;

    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 5rem;
        padding: 1rem;

        h3 {
            color: $white;
            padding: 0;
            font-weight: 400;
            margin-left: 1rem;
        }
    }

    .modal-content-body {
        padding: 2rem;

        // max-height: 75rem;
        .subtask-card {
            margin-top: 0;
        }

        .attributes-section {
            padding: 2rem 0;
            font-size: 1.4rem;

            .input-signoff-modal {
                display: flex;
                flex-direction: column;

                & textarea {
                    max-width: 70rem;
                    height: 17rem;
                }
            }
        }

        .password-section {
            font-size: 1.6rem;
        }

        .st-label {
            font-size: 2rem;
            font-weight: $font-weight-semi-bold;
        }

        .st-value {}
    }
}

.nr-subtask-info-modal {
    min-height: auto;
    max-width: 76rem;

    .modal-content-body {
        max-height: 70vh;
        overflow-y: auto;
    }
}

.subtask-reorder-modal {
    min-height: 45rem;
    max-width: 120rem;
    margin: 4rem;
    padding: 0;

    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 5rem;
        padding: 1rem;

        h3 {
            color: $white;
            padding: 0;
            font-weight: 400;
            margin-left: 1rem;
        }
    }

    .modal-content-body {
        padding: 2rem;
        max-height: 60rem;
        overflow-y: auto;

        .reorder-text {
            margin-bottom: 3rem;
        }

        .subtask-card {
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }

    .btn-section-modal {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;
        margin-bottom: 3rem;

        .btn,
        .btn-link {
            margin: 0 2rem;
            width: 4.5rem;
        }
    }
}

.nr-addsubtask-modal {
    min-height: 25rem;
    max-width: 100rem;

    .add-link {
        .link-container {
            display: flex;
            cursor: pointer;
        }

        .icon {
            font-size: 3rem;
            padding-right: 10px;
            font-weight: $font-weight-normal;
        }

        display: inline-flex;
        cursor: pointer;
        color: $united-purple;
        font-weight: $font-weight-semi-bold;
        align-self: center;
        line-height: 30px;
    }

    .radio-field-container {
        padding-left: 3rem;
    }

    .modal-content-body {
        max-height: 60rem;
        overflow-y: auto;
    }
}