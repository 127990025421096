.assoc-d8-modal{
    padding: 0;
    border: none;
    width: 95rem;
    height: auto;
    max-height: 60vh;
    border-radius: 10px 10px 6px 6px;
    overflow-y: auto;
    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 6rem;
        padding: 1rem;

        h3 {
            color: white;
            font-weight: 600;
            margin-left: 1rem;
            font-size: 24px;
            font-family: 'Open Sans' Arial, Helvetica, sans-serif;
        }

        svg {
            margin-right: 1rem;
            height: 1.5rem;
        }
    }
    .modal-content-body {
        overflow-y: auto;
        .d1-header {
            display: flex;
            flex-direction: column;
            margin: 1.6rem 2rem 2rem 2rem;
            .ddl-label {
                font-size: 20px;
            }
        }
        .header-details {
            font-size: $font-size-fs16;
            .header {                
                font-weight: $font-weight-semi-bold;
                height: 40px;
                background-color: #002244;                           
                color: $white;
                padding: 8px 0px 5px 25px;
            }              
            .number {
                display: flex;
                flex-direction: column;
                width: 20%;
            }
            .description {
                width: 80%;
            }  
            .d1-details {
                min-height: 55px;
                padding: 15px 0px 5px 25px;
                border: 1px solid #E6E6E6;
                border-top: none;
                background-color: $white;
            }
            .details{
                min-height: 55px;                   
                padding: 15px 0px 5px 25px;
            }   
            .details:nth-child(even){
                background-color: #E6E6E6;
            }
            .details:nth-child(odd){
                background-color: $white;
                border: 1px solid #E6E6E6;
                border-top: none;
                border-bottom: none;
            }
            .details:last-child:nth-child(odd){
                border: 1px solid #E6E6E6;
                border-top: none;
            }
        }
    }
}