.dashboard-header, .landing-page-header {
    color: $white;
    background: #0C2340;
    padding-left:15px;
    padding-right:15px;
    height:58px;    
    position:relative;
    z-index:10009;
    .navbar {
        color: $white;
        background: #0C2340;  
    }   
    .header-logo {
        z-index:4;
    }
    .header-title {
        //margin: 0px auto 0px auto !important;
        position: absolute;
        width:100%;
        text-align:center;
        top:0px;
        left:0px;
    }
    .header-profile {
        display:flex;
        align-items: center;
        z-index:4;
        margin-left:auto;
    }
    .header
    .titlerealign {
        margin: 0px 400px 0px 0px !important;
    }
    .app-title {
        font-size: 32px;
        text-align: center;
    }
    
    .clear-background{
        background: none;
        border: none;
    }
    
    .navbar-actions{
        display:flex;
        position: relative;
        right: 1%;
    }

      .searchContainer {
        display: inline-flex;
        position: relative;
        border-bottom: 2px solid #ccc;
       // overflow: hidden;
        margin: 0px 60px 0px 0px;
        height:26px;
      }
      .searchContainer input {          
        border-bottom: 0px !important;
      }
      .searchContainer img {
          background-color:#0C2340;
          width:14px;
          height:24px;
      }
      .searchIcon {
        padding: 0.5rem;
      }

      input {
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: groove;
        //background-color: #eee;
        }        
        .united-logo {
            position: relative;
            width:221px;
            height:41px;
            cursor: pointer;
        }
        .row {
            margin-left: 0px !important;
            margin-right: 0px !important;
        } 
        .hide {
            display: none;
        }
}

.landing-page-header {
    background: transparent;
    .navbar {
        background: transparent;  
    } 
}

.last-refreshed {
    display:flex;
    color:#FFFFFF;
    font-size:14px;
    padding-right:30px;
    > div {
        padding-left:10px;
    }
}

.refresh-icon {
    width:27px;
    height:27px;
    margin:auto;
    cursor: pointer;
}

.release-notes{
    margin-right:15px;
.info-icon {
    width: 18px;
    height:18px;
    cursor: pointer;
}
}

.ac-lookup {
    background-color: transparent;
    // color:#ffffff;
    font-size: 14px;
    width: 175px;
    padding-left: 5px;
    -webkit-appearance: none;
    border:none;
    &:focus{
    border: 0px solid transparent;
    outline:none;
    }
}
