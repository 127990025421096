$summaryHeight: 340px;
$headerHeight: 80px;
$dash8PanelWidth: 465px;
@import "./Dash8/dash8panel";
@import "./Dash1/dash1panel";
@import "./PaperCards/paperCard";
@import "./ScheduledLogPages/scheduledLogpages";
@import "./FlifoSummary/flifosummary";
@import "./OpenLogPages/openlogpagecard";

.scheduled-work {
    padding: 0px 2rem;
}

.routine-register {
    padding-left: 10px;
    position: relative;
}

.workload-header {
    margin-top: 15px;
}

.taskcard-header {
    height: 158px;
}

.workload-container {
    border-top: 2px solid #d4d4d0;

    .workload-search-info {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
    }
}

.workload-line-container {
    border-top: 1px solid #41748d;
}

.full-screen-container {
    background-color: #ffffff;
    width: 40px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 2rem;

    .full-screen {
        cursor: pointer;
        vertical-align: middle;
        box-sizing: border-box;
        display: inline-block;
        border: 0.1em solid currentColor;
        width: 1em;
        height: 1em;
        position: relative;
    }

    .full-screen:before,
    .full-screen:after {
        content: "";
        background: #ffffff;
        position: absolute;
    }

    .full-screen:before {
        width: 0.333em;
        height: 1em;
        left: 0.233em;
        top: -0.1em;
    }

    .full-screen:after {
        width: 1em;
        height: 0.333em;
        top: 0.233em;
        left: -0.1em;
    }
}

.dash8-panel {
    .accordion-content {
        padding-top: 30px;

        .routine-card {
            cursor: pointer;
        }

        .chevron-right-icon:hover {
            border: 3px solid #6244bb !important;
        }

        .routine-card:hover {
            border: 3px solid #6244bb !important;
        }

        .accordion-info {
            padding-left: 35px;
            font-size: 16px;
        }
    }
}

.card-selected {
    border: 3px solid #6244bb !important;
    position: relative;
}

.card-selected::after {
    width: 28px;
    height: 28px;
    content: " ";
    right: -16px;
    position: absolute;
    border-top: 3px solid #6244bb;
    border-right: 3px solid #6244bb;
    transform: rotate(45deg);
    background-color: #ffffff;
}

.routine-card {
    cursor: default;
    color: #333333;
    font-size: 14px;
    border: 2px solid #e6e6e6;
    border-radius: 6px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    //padding:20px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
    width: 100%;

    .card-desc {
        font-weight: $font-weight-bold;
        //margin-bottom:15px;
    }

    .icon-container {
        font-size: 10px;
        color: #000000;
        text-align: center;
        display: flex;
        // justify-content: center;
        align-items: center;

        .quick-actions-etask {
            right: 20px !important;
           
        }
    }

    .notes-container-value {
        // height: 40px;
        margin-right: 1rem;
        position: relative;
        width: 50px;
        top: 1px;

        .notes {
            padding: 0px;
        }

        .notes .notes-count {
            right: 14px;
        }

        svg {
            height: 20px;
        }

        label {
            display: block;
            margin-bottom: 0px;
        }

    }

    .card-details {
        color: #0c2340;

        //margin-bottom:20px;
        .dash-number-label {
            font-size: 12px;
        }

        .dash-number-value {
            font-size: 18px;
            color: #333333;
        }

        .paperImageIcon,
        .accountability-time {
            padding-right: 3px;
        }

        .slp-mel-label {
            font-size: 12px;
        }

        .slp-mel-value {
            font-weight: $font-weight-bold;
            font-size: 18px;
            color: #333333;
        }

        .dash1-note {
            color: #572c5f;
            font-size: 12px;
            font-weight: $font-weight-bold;
        }

        .dash8-note {
            color: #d50032;
        }

        .tech-icon {
            width: 24px;
            height: 16px;
            margin-top: 8px;
        }

        .insp-icon {
            width: 16px;
            height: 17px;
            margin-top: 8px;
            position: absolute;
            right: 10px;
            bottom: 10px;
        }

        .insp-icon-req {
            height: 17px;
            margin-top: 8px;
            position: absolute;
            right: 10px;
            bottom: 10px;
        }

        .icon-label {
            font-size: 10px;
            color: #000000;
        }

        // .status-bar {
        //     margin: 0px 0px 0px auto;
        // }
        .status-bardash1 {
            margin: 0px 0px 0px auto;
            padding-left: 50px;
        }

        .dash8-number {
            height: 86px;
            padding-right: 12px;
        }

        .job-number {
            height: 64px;
            margin-right: 10px;
        }

        .slp-tile {
            .slp-title {
                padding-right: 50px;
            }

            .dash8-number {
                padding-right: 10px;
            }
        }

        .slp-title {
            font-size: 1.7rem;
            color: #0c2340;
            height: 86px;
            padding-right: 60px;

            &.nr-title {
                padding-right: unset;
                margin-right: 4rem;
            }

            .data-count,
            .npr-link {
                display: flex;
                //justify-content: end;
                gap: 10px;
            }
        }

        .card-info {
            display: flex;
            flex-direction: column;
        }

        .card-info::before,
        .card-info::after {
            content: "";
            flex: 1;
        }

        .position-serial,
        .accountability-status,
        .accountability-date {
            display: flex;
            height: 86px;
            padding-right: 10px;

            .position-code,
            .status-code,
            .date,
            .serial-number {
                padding-right: 10px;
                margin: auto;
                line-height: 82px;

                label {
                    line-height: normal;
                }
            }

            .accountability-status-value {
                font-size: 1.4rem;
                font-weight: 600;
                width: auto;
                text-align: center;
                border-radius: 4px;
                background-color: #b6b8dc;
                padding: 5px 10px;
            }

            // .added > .accountability-status-value {
            //     // background-color: #333333;
            // }
            .deferred>.acountability-status-value {
                background-color: rgba(0, 51, 160, 0.75);
            }

            .removed>.accountability-status-value {
                background-color: #333333;
                color: $white;
            }

            .rescheduled>.acountability-status-value {
                background-color: #f46335;
                color: $white;
            }

            .accountability-date-value {
                font-size: 1.6rem;
                font-weight: 600;
                width: 173px;
            }
        }

        .position-code {
            padding-right: 20px;
        }
    }

    .chev-right {
        padding-top: 30px;
    }

    @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {

        // IE9+ CSS
        .chev-right {
            padding-top: 0px;
        }
    }
}

.detached-dash8 {
    height: 22px;
}

.card-contact {
    font-size: 12px;
    color: #666666;
    padding: 0px 18px 12px 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;

    .contact-icon {
        width: 16px;
        height: 17px;
        margin-right: 5px;
    }

    span {
        position: relative;
        top: 1px;
    }
}

.sceptre-date {
    font-size: 14px;
    color: #666666;
    padding: 0px 18px 12px 18px;
}

.chevron-right-icon {
    cursor: pointer;
    height: 4.4rem;
    width: 1.4rem;
}



.taskcard-flifo {
    display: flex;
    width: 100%;
    background-color: rgba(65, 116, 141, 0.2);
    padding: 15px 25px;
    flex-wrap: wrap;
    justify-content: space-between;

    >div {
        padding: 0px 15px 0px 0px;
        min-width: 120px;

        label {
            font-size: 1.4rem;
            color: #666666;
        }

        div {
            font-size: 1.6rem;
            font-weight: $font-weight-bold;
            color: #333333;
        }

        .clock-icon {
            width: 17px;
            height: 17px;
        }

        .date {
            padding-right: 10px;
        }

        .time {
            padding-left: 5px;
        }
    }

    .large-details {
        min-width: 170px;
    }

    .small-details {
        min-width: 60px;
    }

    .aircraft-number {
        min-width: 120px;
        display: flex;
        font-size: 2.4rem;
        font-weight: $font-weight-bold;
        align-self: center;

        svg {
            transform: rotate(-90deg);
            height: 24px;
            width: 32px;
            padding-right: 8px;
            align-self: center;
        }
    }

    .print {
        min-width: 120px;
        display: flex;

        svg {
            height: 28px;
        }
    }

    .seperator {
        border-left: 1px solid #000000;
        padding-left: 50px;
    }

    .cec { //line       
        text-align: left;
        background-color: $united-blue;
        color: $white;
        height: 75px;
        padding: 5px !important;
        line-height: 22px;
        .details{
            display: flex;
            div {
                width: 100%;
                .title {
                    font-weight: 700;
                    float: left;
                }
                .info{
                    margin-left: 5px;
                    font-weight: 400;
                }
            }
        }

        div {
            color: #FFFFFF !important;
        }
    }
}


.taskcard-info-section {
    min-width: 80%;
    padding-right: 50px;
    padding-left: 20px;
    padding-bottom: 20px;

    &-header {
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 2rem;
        font-weight: $font-weight-semi-bold;
    }

    &-content {
        font-size: 14px;
        padding-left: 0px;

        .value {
            font-size: 14px;
            color: #0c2340;
        }

        .datevalue {
            font-size: 16px;
            display: block;
        }

        label {
            font-size: 14px;
            margin-bottom: 0px;
            color: #333333;
        }

        .row {
            justify-content: space-between;
        }
    }

    .cardtitle {
        max-width: 500px;
    }

    .cardtitle,
    .aircrafvalue {
        font-size: 24px;
        font-weight: $font-weight-bold;
        color: #333333;
        text-align: left;
    }

    .taskcardvalue {
        font-size: 18px;
        font-weight: $font-weight-bold;
    }

    .taskcardnumber,
    .aircraftnumber,
    .arrivaldate,
    .scheduleddate,
    .archiveddate {
        &-label {
            z-index: 1;
        }

        &-value {
            z-index: 2;
        }

        &-emptydiv {
            z-index: 3;
            height: 21px;
        }

        position: relative;

        div div {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .dash8number-content {
        display: flex;
    }

    .cec { // base
        background-color: $united-blue;
        color: $white;
        height: 75px;
        padding: 5px !important;
        line-height: 22px;
        text-align: left;
        .details{
            display: flex;
            div {
                width: 100%;
                .title {
                    font-weight: 700;
                    float: left;
                }
                .info{
                    margin-left: 5px;
                }
            }
        }
    }

    .jobnumber {
        &-label {
            z-index: 1;
        }

        &-value {
            z-index: 2;
            font-size: 1.8rem;
        }

        &-content {
            z-index: 3;
            padding-left: 0px;
            display: flex;
        }

        position: relative;

        .positionCode,
        .serialNumber {
            font-size: 14px;
            color: #0c2340;
        }

        .positionCodediv {
            padding-left: 0px;
            padding-right: 1rem;
        }
    }

    .tech-icon,
    .insp-icon,
    .stop-icon {
        height: 30px;
        padding-right: 0px;
    }
}

.signedby-info-section {
    min-width: 20%;
    margin-bottom: 2rem;

    &-header {
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 2rem;
        font-weight: $font-weight-semi-bold;
    }

    &-content {
        font-size: 14px;

        // padding: 0px;
        .value {
            font-size: 14px;
            color: #0c2340;
        }

        .datevalue {
            font-size: 16px;
            display: block;
        }

        label {
            font-size: 14px;
            margin-bottom: 0px;
            color: #333333;
        }
    }

    .signedby-viewmore {
        cursor: pointer;
        padding-top: 20px;
        margin-left: auto;
        margin-right: 0;

        span {
            font-size: 14px;
            color: #6244bb;
        }
    }
}

.signedby {
    width: 344px;
    height: 398px;
    font-size: 14px;
    overflow: auto;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 20px;

    &-title {
        font-size: 28px;
        font-weight: $font-weight-bold;
        padding-bottom: 20px;
    }

    .signedby-title img {
        float: right;
        cursor: pointer;
    }

    .signedby-section {
        .input-set {
            margin-bottom: 20px;
        }
    }
}

.dash8-card {
    margin-bottom: 20px;
    width: 96%;

    .card-desc {
        height: auto;
        min-width: 350px;
        max-width: 375px;
    }

    .card-desc-holder {
        padding: 12px 12px 12px 12px;
        background-color: #0c2340;
        color: white;
        border-radius: 6px 6px 0px 0px;
        width: 100%;
    }

    .card-desc-line {
        background-color: #666666;
    }

    .card-content-holder {
        padding: 12px 12px 12px 12px;

        &.nr-content {
            display: flex;
        }
    }
}

.dash1-card {
    // width: 100%;
    padding: 1rem 2rem;
    margin: 1rem 0;
    width: 100%;
    &:last-child{
        .w-100{
            .move-action-button{
                .actions-container{
                    top:0;
                    bottom: -55px;
                    left: 0!important;
                }
            }
        }
    }
    // padding: 12px 20px;
    // margin-bottom:20px;
    // margin-right: 12px;
    // margin-top: auto;
    // margin-bottom: 12px;
    //background-color: rgba(182,184,220,0.10);
    // .card-desc {
    //     // height: 40px;
    //     // width: calc(100% - 600px);
    //     // max-width: 500px;
    // }
    // .card-details {
    //     // height: 82px;
    //     // width: 200px;
    // }
    .icon-container {
        // font-size: 10px;
        // color: #000000;
        // text-align: center;
        display: flex;
        flex-wrap: wrap;
        // height: 65px;
    }

    .icon-container-value {
        // height: 40px;
        width: 40px;
        margin-right: 1rem;

        svg {
            height: 20px;
        }
    }

    // .stop-container {
    //     // width: 20px;
    //     // height: 35px;
    // }
    .card-contact {
        padding: 1rem 0 0;
    }

    .dash1-card-details-holder {

        // min-height: 6.5rem;        
        >div {
            padding: 1rem 1rem 0 0;
        }

        .card-details {
            .card-info {
                .task-card-section {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    // .dash1-card-contacts-holder {
    //     // height: 20px;
    // }
    .dash1-progress-bar {
        // padding-right: 35px;
        // padding-left: 35px;
        // height: 65px;
        //display: flex;
        align-items: center;
    }

    .dash1-progress-bar:before {
        /* create a full-height inline block pseudo-element */
        content: " ";
        display: inline-block;
        vertical-align: middle;
        /* vertical alignment of the inline element */
        height: 100%;
    }

    .progress-bar-holder {
        display: inline-block;
    }

    .partial-indicator {
        position: relative;
        left: 0px;
        top: -14px;
        align-items: center;
        text-align: center;
        background-color: $united-purple;
        color: #ffffff;
        font-size: 12px;
    }

    .chev-right {
        order: 2;
        margin-left: auto;
        margin-top: 30px;
        margin-bottom: auto;

        svg {
            width: 14px;
            height: 25px;
        }
    }
}

.searchtaskcard {
    margin: 20px auto;

    .search-taskcard-info {
        font-size: 16px;
    }
}

.search-accordion {
    //width: 1549px;
    max-width: 1600px;
    padding: 10px;
    margin: 0 auto;

    .search-accordion-item {
        background-color: #fff;
        color: #111;
        // margin: 1rem 0;
        border-radius: 6px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    }

    .search-accordion-item-header {
        // padding-top: 10px;
        background: rgba(230, 230, 230, 0.3);
        // min-height: 3.5rem;
        // line-height: 1.25rem;
        // font-weight: bold;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        .card-desc-holder {
            background-color: transparent;
            color: #333333;
        }

        .dash8-card .card-desc {
            max-width: 100%;
            min-width: 350px;
        }

        .card-contact {
            max-width: 300px;
        }

        .contact-info {
            min-width: 320px;
            padding-top: 43px;
        }

        .routine-card {
            background-color: transparent;
            border-width: 0px;
            box-shadow: none;
            // .card-details .card-info {
            //     width:200px;
            // }
        }

        .routine-card:hover {
            border: 0px solid #6244bb !important;
        }

        .dash8-card {
            margin: 0px;
            cursor: default;

            .card-desc-holder {
                max-width: calc(100% - 725px);
                min-width: 300px;
            }
        }

        .accordion-icon {
            margin: 0px 50px 0px auto;
        }
    }

    .search-accordion-item-header.searchactive img {
        transform: rotate(180deg);
    }

    .search-accordion-item-body {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        background: rgba(230, 230, 230, 0.3);
        border-color: rgba(230, 230, 230, 0.3);
        border-style: solid;
        border-width: 0px 20px 20px 20px;

        .routine-card.dash1-card {
            position: relative;
            &:last-child{
                .action-items-container{
                    right: 9px;
                    bottom: 0;
                    top: auto;
                }
            }
        }
    }

    .search-accordion-item-body-content {
        padding: 30px 10px 30px 30px;
        //line-height: 1.5rem;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;

        >a {
            width: 100%;
            padding-right: 20px;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .search-accordion-item-header.searchactive+.search-accordion-item-body {
        max-height: 100%;
    }

    .harmonized-search {
        padding-bottom: 32px;
        background: #0c2340;

        .card-content-holder,
        .slp-title,
        .slp-mel-value,
        .card-desc-holder,
        .CircularProgressbar-text {
            color: #ffffff;
            fill: #fff;
        }

        &.search-accordion-item-header {

            & .card-details,
            & .dash-number-value,
            & .card-contact {
                color: #fff;
            }
        }

        &.search-accordion-item-body {
            border-radius: 0 0 6px 6px;
            background-color: #c1c6c8;
            border: none;
        }

        &>.search-accordion-item-body-content {
            background-color: transparent;
        }

        .arrow-accordion {
            height: 25px;
            width: 25px;

            &.searchactive {
                transform: rotate(90deg);
                transition: 300ms linear all;
            }

            transform: rotate(-90deg);
            transition: 300ms linear all;
        }
    }
}

.papersignoff-modal {
    width: 64rem;
    height: 36rem;
}

.papercard-alert-icon {
    width: 3.4rem;
    height: 2.4rem;
    padding-right: 1rem;
}

.induct-aircraft-modal {
    max-width: 500px;

    .induct-continue {
        width: 120px;
    }
}

.duplicate-taskcards-group {
    margin: 20px auto;

    .searchtaskcard {
        margin: 0px auto;

        &:first-child {
            .search-accordion-item-header {
                border-radius: 6px 6px 0px 0px;
            }
        }

        &:last-child {
            .search-accordion-item-body {
                border-radius: 0px 0px 6px 6px;
            }
        }
    }

    .search-accordion {
        padding: 0px;

        &-item {
            border-radius: 0px;
            box-shadow: none;

            &-header {
                background-color: #0c2340;
                color: white !important;

                .card-desc-holder {
                    color: #ffffff;
                }

                .routine-card .card-details {
                    color: #ffffff;
                }

                .routine-card .card-details {
                    color: #ffffff;
                }

                .routine-card .card-details .dash-number-value {
                    color: #ffffff;
                }

                .card-contact {
                    color: #ffffff;
                }

                .accordion-icon {
                    transform: rotate(90deg);
                    cursor: default;

                    svg {
                        width: 24px;
                        height: 24px;
                    }

                    .searchactive {
                        transform: rotate(180deg);
                    }
                }
            }

            &-body {
                background: #c1c6c8;
                border-color: #c1c6c8;
                border-width: 0px;

                &-content {
                    background-color: #c1c6c8;
                    padding: 15px;

                    &:last-child {
                        border-radius: 0px 0px 6px 6px;
                    }

                    &>a {
                        padding-right: 0px;
                    }

                    .dash1-card {
                        margin: 0px;
                    }
                }
            }
        }
    }
}

.notes {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;

    .notes-count {
        height: 16px;
        width: 16px;
        background-color: #D50032;
        color: #fff;
        font-size: 8px;
        font-weight: bold;
        border-radius: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        right: 21px;
    }
}

.station-details-print {
    font-size: 16px;
    position: absolute;
    left: 50%;
    font-weight: bold;
    top: -5px;
    display: none;

    span {
        margin-left: 25px;
    }

    &.display {
        display: block;
    }

    &.line-flow {
        display: block;
        padding-top: 15px;
    }
}

.taskcard-print-icon {
    margin-left: 20%;

    &.line-print-icon {
        display: none !important;
    }
}

.station-code-print {
    margin-left: -100px;
    margin-top: -5px;
    font-weight: bold;
    font-size: 16px;
    display: none;

    &.display {
        display: block;
    }
}
.tcm-enabled{
    min-width: 55px !important;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.tcm-enabled-base{
    top: 5px;
}