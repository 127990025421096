$md-radio-checked-color: #6244bb;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 14px;
$md-radio-ripple-size: 15px;

.RadioButton {
    margin: 16px 0;

    input[type="radio"] {
        display: none;

        &:checked + label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards;
        }

        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        height: $md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;

        &:before,
        &:after {
            position: absolute;
            content: "";
            border-radius: 50%;
            transition: all 0.3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }

        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width: $md-radio-checked-size;
            height: $md-radio-checked-size;
            transform: scale(0);
            background: $md-radio-checked-color;
        }
    }
    &.disabled {
        label {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
}
