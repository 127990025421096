.react-calendar-timeline .rct-dateHeader {
    background-color: #e6e6e6 !important;
    border-left: 1px solid #bbb !important;
}
.react-calendar-timeline .rct-header-root {
    background: #e6e6e6 !important;
}
.react-calendar-timeline {
    border: 2px solid #e6e6e6 !important;
    border-radius: 6px !important;
}
.rct-calendar-header {
    & > div:nth-child(1) {
        height: 40px !important;
    }
    & > div:nth-child(2) {
        height: 80px !important;
    }
}
.rct-dateHeader span {
    font-size: 1.6rem;
    color: #333333;
    font-weight: 600;
}
.react-calendar-timeline .rct-sidebar {
    font-size: 1.6rem;
    color: #333333;
    font-weight: 600;
    background-color: $white-gray;
}
.shift-cells {
    border-left: 1px solid #979797 !important;
    border-bottom: 1px solid #979797 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &.current-date {
        background-color: #69b3e7 !important;
    }
}

// .react-calendar-timeline .rct-vertical-lines .rct-vl {
//     border-left: 0px !important;
// }
// .react-calendar-timeline .rct-vertical-lines {
//      div:nth-child(8n-1) {
//         border-left: 1px solid #979797 !important;
//     }
// }
.react-calendar-timeline .rct-scroll {
    //overflow-x: hidden !important;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: none !important;
    background-color: transparent !important;
}
.react-calendar-timeline .rct-vertical-lines .verticalLine--past {
    border-left: 1px solid #949494 !important;
}
.rct-outer {
    height: 100% !important;
    // overflow-y: auto !important;
    // height: calc(100vh - 320px) !important;
}
.rct-sidebar-row {
    line-height: normal !important;
}
.shift-cells-bg {
    background-color: #41748d;
}
.react-calendar-timeline .rct-vertical-lines {
    .rct-vl.rct-day-6,
    .rct-vl.rct-day-0 {
        background-color: inherit !important;
    }
}
.react-calendar-timeline {
    .rct-horizontal-lines .rct-hl-odd,
    .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
        background: rgba(0, 0, 0, 0) !important;
    }
    .custom-group {
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 1rem 2rem;
        flex-direction: column;
        .title {
            //color: #10274a !important;
            font-size: $font-size-fs20 !important;
            padding-bottom: 1rem;
            text-overflow: ellipsis;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
        }
        .group-info {
            font-size: $font-size-normal !important;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
    .rct-sidebar-row,
    .rct-hl-even,
    .rct-hl-odd {
        // height: 10rem !important;
    }
    .rct-sidebar {
        height: auto !important;
    }
    .rct-item {
        height: 60px !important;
        color: #333333;
        letter-spacing: 3px;
        border-radius: 6px !important;
        margin-top: 1rem;
        &.item-open {
            border: 3px solid #6244bb !important;
            background-color: rgba(98, 68, 187, 0.2) !important;
            .override-column {
                background-color: #6244bb;
            }
        }
        &.item-closed {
            border: 3px solid #208500 !important;
            background-color: rgba(32, 133, 0, 0.2) !important;
            .override-column {
                background-color: #208500;
            }
        }
        &.past {
            border: 2px solid red !important;
        }
        &.item-overide {
            border: none !important;
            background: none !important;
            display: flex;
            justify-content: center;
            padding-top: 1.4rem;
        }
    }
    .selected-item {
        background-color: orange !important;
        border-color: #949494;
        border-style: solid;
        border-width: 3px 15px 3px 3px;
    }
    .grouped-workload {
        color: #333333;
        line-height: normal;
        font-size: $font-size-large;
        font-weight: bold;
        display: flex;
        .workload-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-direction: column;
            padding: 0.6rem;
            flex-grow: 1;
        }
        .override-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $white;
            width: 1.4rem;
            height: 5.75rem;
            padding-top: 0.8rem;
            &.left {
                margin-right: 1rem;
                margin-left: -2px;
                border-top-left-radius: 6px !important;
                border-bottom-left-radius: 6px !important;
            }
            &.right {
                margin-left: 1rem;
                margin-right: -2px;
                border-top-right-radius: 6px !important;
                border-bottom-right-radius: 6px !important;
            }
            span {
                font-size: 3.5rem;
                font-weight: 700;
                height: 0.9rem;
                line-height: 0;
            }
        }
    }
    .overide-icon {
        position: absolute;
        svg {
            height: 52px;
        }
    }

    .badge-secondary {
        top: -9px;
        background-color: $white;
        position: relative;
        left: 21px;
        font-weight: bold;
        font-size: 12px;
        border-radius: 50%;
        &.open {
            color: #6244bb;
            border: 3px solid #6244bb !important;
        }
        &.closed {
            color: #208500;
            border: 3px solid #208500 !important;
        }
        &.past {
            color: red;
            border: 2px solid red !important;
        }
    }
    .rct-vertical-lines .rct-vl.empty-shift {
        background-color: lightgrey !important;
        opacity: 0.5;
    }
    .rct-vertical-lines .rct-vl.current-date {
        background-color: #69b3e7 !important;
        opacity: 0.4;
    }
    .rct-vertical-lines .rct-vl.past-etr {
        background-color: lightgrey !important;
        opacity: 0.5;
    }
}
.sticky {
    position: sticky;
    top: 0;
    z-index: 200;
}
