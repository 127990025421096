.flifo {
  .disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    color: #949494;
  }
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(65, 116, 141, 0.2);
  //border-bottom: 1px solid #41748D;
  box-sizing: border-box;
  margin-bottom: 15px 0px;
  height: 80px;
  .flifo-card-status {
    background: $white;
    margin-right: 0;
  }
  .flifo-number {
    font-weight: bold;
    font-size: 2.4rem;
    &.link {
      text-decoration: underline;
      color: inherit;
      cursor: pointer;
    }
  }
  .flifo-section-seperator {
    height: 50px;
    border-right: 1px solid #000000;
    //opacity: 0.3;
    box-sizing: border-box;
  }
  .plane-img {
    transform: rotate(-90deg);
    width: 26px;
    height: 40px;
  }
  .clock-img {
    width: 16px;
    height: 16px;
  }
  .clock-icon {
    width: 17px;
    height: 17px;
  }
  .date {
    padding-right: 10px;
  }
  .time {
    padding-left: 5px;
  }
  .flifo-section {
    display: flex;
    flex-flow: column;
    padding: 0 1rem;
    span {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.4px;
      height: 24px;
    }
  }

  .mrd-status {
    position: relative;
    svg {
      cursor: pointer;
      height: 20px;
      width: 47px;
    }
  }
  .assignees-container {
    cursor: pointer;
    position: relative;
  }
  .star-icon,
  .ron-icon,
  .insp-required-icon,
  .etops-icon,
  .workload-icon,
  .mrd-icon {
    width: 24px;
    height: 24px;
    padding-right: 3px;
  }
  .mrd-status-icon {
    width: 42px;
    height: 24px;
  }
  // .infoimg {
  //   cursor: pointer;
  //   padding-left: 3px;
  // }
  .info-icon {
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 12px;
    padding-left: 8px;
    top: -4px;
  }
  .flifo-events {
    height: 22px;
    display: flex;
    .icon-container {
      padding-right: 13px;
    }
  }
  .flifotooltip {
    float: right;
  }
  #flifotooltip {
    .expand {
      display: block;
    }
  }
  .show {
    display: block;
  }
  #flifotooltippanel {
    position: absolute;
  }
  .flifotooltip-container {
    position: absolute;
    margin: auto;
    display: none;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    z-index: 4;
    top: 2px;
    left: -8px;
    &::after {
      content: "";
      width: 0px;
      height: 0px;
      border-bottom: 9px solid transparent;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid transparent;
      position: absolute;
    }
    &::before {
      content: "";
      width: 0px;
      height: 0px;
      border-bottom: 10px solid #ffffff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      position: absolute;
      top: -17px;
      left: 12px;
    }
    #flifotooltip-inner-container {
      width: 185px;
      max-width: 185px;
      // height: 194px;
      display: block;
      padding: 10px 0px 10px 0px;
      font-size: 14px;
      color: #333333;
      label {
        font-size: 14px;
        color: #333333;
        font-weight: 600;
        padding-bottom: 10px;
        padding-left: 10px;
      }
    }
    .row {
      line-height: 30px;
      padding-bottom: 5px;
    }
    .flifotooltip-name {
      padding-bottom: 15px;
      margin: 0px 0px 0px 30px;
      span {
        font-size: 14px;
        color: #333333;
        font-weight: 500;
        padding-bottom: 10px;
      }
    }
  }
}

.mrd-modal,
.assignees-modal {
  width: 30rem;
  position: absolute;
  padding: 2rem;
  z-index: 10;
  right: -3px;
  top: 30px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 0.6rem;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.07);
  &:before {
    content: "";
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    border-left: 8px solid transparent;
    /* left: -8px; */
    top: -16px;
    right: 16px;
  }
}
.checkMrd-modal,
.issue-Confirmation-modal {
  min-height: 35rem;
  max-width: 77rem;
  margin: 4rem;
  padding: 0;
  .modal-content-header {
    border-radius: 6px 6px 0 0;
    background-color: #0c2340;
    align-items: center;
    height: 5rem;
    padding: 1rem;
    h3 {
      color: $white;
      padding: 0;
      font-weight: 400;
      margin-left: 1rem;
    }
  }
  .modal-content-body {
    max-height: 35rem;
    overflow-y: auto;
    .broken {
      color: #d50032;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .tools-message {
      display: flex;
      justify-content: space-between;
      background-color: #edb72b;
      align-items: center;
      height: 6rem;
      font-size: 1.8rem;
      padding: 0 2rem;
      svg {
        height: 20px;
        width: 20px;
        cursor: pointer;
      }
    }
    .information-message {
      border-bottom: 2px solid #d4d4d0;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      padding: 2rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }
    .checkout-tool {
      border-bottom: 1px solid #ccccc0;
      padding: 1rem;
      display: flex;
      align-items: baseline;
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .tools-info-header {
      font-size: 2.4rem;
      font-weight: bold;
      border-bottom: 1px solid #ccccc0;
      padding: 1rem;
      svg {
        transform: rotate(180deg);
        height: 20px;
        width: 20px;
        margin-right: 1rem;
        cursor: pointer;
      }
    }
  }
}
.issue-Confirmation-modal {
  min-height: 23rem;
  .issue-content {
    display: flex;
    flex-direction: column;
    color: #333333;
    label {
      font-size: 16px;
    }
    span {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
.assignees-modal {
  .header {
    border-bottom: 1px solid #0c2340;
    padding-bottom: 1rem;
    color: #0c2340;
    font-size: 18px;
    font-weight: 600;
  }
  .list-item {
    color: #000000;
    height: 4rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cccccc;
  }
}
