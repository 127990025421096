.search-input.master-card {
  border-color: $night-rider-gray;
}
.master-error {
  padding-left:25px;
  color:red;
}
.edit-search {
  color: #000000;
  font-size: $font-size-fs20;
  font-weight: $font-weight-semi-bold;
  padding: 0px;
}
.search-results-section {
  // height: calc(100vh - 324px);
  // overflow-y: auto;
  width: 100%;
  a:hover {
    text-decoration: none;
  }
  .card-list-item {
    min-height: 4.8rem;
    padding: 0.6rem;
    border: 1px solid $mercury-gray;
    margin: 0.8rem;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    font-size: $font-size-large;
    color: $night-rider-gray;
    border-radius: 6px;
    .card-column {
      display: flex;
      // flex-flow: column;
    }
    > div {
      padding: 0 2rem;
    }
    &:hover{
      border: 2px solid #683dc2 !important;
    }
    .tail-column {
      font-weight: $font-weight-bold;
      font-size: $font-size-fs24;
    }
    .chevRight {
      cursor: pointer;
      height: 2.4rem;
      width: 2.4rem;
    }
  }
}

.sort-fields {
  color: $united-purple;
  display: flex;
  justify-content: flex-end;
  font-weight: $font-weight-semi-bold;
  letter-spacing: 0.35px;
  width: 100%;
  .sort-text {
    width: 16.6rem;
    display: flex;
    position: relative;
    right: 0px;
    & svg {
      padding-right: 9px;
      height: 20px;
    }
  }
}

.archivecards-form {
}

.search-header {
  font-size: $font-size-fs24;
  font-weight: $font-weight-semi-bold;
  //padding-bottom:20px;
  padding-top: 30px;
}
.searchby-fields {
  display: flex;
  flex-wrap: wrap;
  .typeahead-container {
    padding-right: 20px;
  }
  .lookup-input {
    padding-top: 20px;
    width: 200px;
    height: 45px;
    border: 1px solid #333333;
    border-radius: 6px;
    font-size: $font-size-normal;
    display: block;
    padding-right: 20px;
    &:focus {
      background: #ffffff;
      border: 2px solid #6244bb !important;
    }
  }
  .options-container {
    margin-left: 0px;
    width: 200px;
  }
}
.input-field {
  font-size: $font-size-normal;
  padding-right: 20px;
  padding-top: 20px;
  input {
    width: 200px;
    height: 45px;
    border: 1px solid #333333;
    border-radius: 6px;
    font-size: $font-size-normal;
  }
}

.keyword {
  padding-bottom:20px;
}
.search-button-container {
  padding-right: 20px;
  padding-top: 20px;
}

.btn-search-cards {
  width: 110px;
  height: 45px;
  display:inline-block;
  // margin-top: 30px;
}

.archivecards-edit-search {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  .search-header {
    display: none;
  }
  .searchby-fields {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    min-width: 678px;
    justify-content: space-between;
  }
  .btn-search-cards {   
    display:flex;
    // margin-top: 30px;
  }
}
.sort-modal {
  width: 20rem;
  position: absolute;
  padding: 0;
  z-index: 10;
  right: 40px;
  top: 20px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 0.6rem;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.07);
  .list-item {
    font-size: $font-size-normal;
    font-weight: $font-weight-normal;
    color: $black;
    letter-spacing: -0.09px;
    padding: 1rem 2rem;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
  }
  & .higlighted-item {
    color: $white;
    background: $united-purple;
    margin: -1px;
    display: flex;
    justify-content: space-between;
    img {
      height: 2rem;
      width: 2rem;
    }
  }
  .arrow-up {
    transform: rotateX(180deg);
  }
}
.icon-cursor {
  cursor: pointer;
}

.date-range,
.date-range-show {
  z-index: 3;
  .rdrDateDisplayWrapper {
    display:none;
  }
  .calendar-control-container{
    height:0px;
  }
  .date-range-labels-container {
    display: flex;
    padding-bottom: 5px;
    position: relative;
    padding-top: 20px;
    .date-range-label, .date-range-input {
      width: 200px;
      &:first-child {
        margin-right: 60px;
      }
      &:last-child {
        margin-left: 0.833em;
      }
    }
    .invalid {
      border-color: red;
    } 
    .date-range-input {         
        padding-left: 1rem;      
    }
    .calendar-icon {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 47px;
      left: 216px;
      z-index: 100;
    }
  }
  .rdrDateDisplay {
    margin-top: 0px;
    margin-left: 0px;
  }
  .rdrMonthAndYearWrapper,
  .rdrMonths {
    display: none;
  }
  .rdrCalendarWrapper {
    position: relative;
  }
  .rdrDateDisplayItem {
    width: 200px;
    min-width: 200px;
    height: 45px;
    line-height: 45px;
    border: 1px solid #333333;
    border-radius: 6px;
    font-size: 1.4rem;
    text-align: left;
    padding-left: 5px;
    &:first-child {
      margin-right: 60px;
    }
  }
  .rdrDayHovered {
    border:none;
    .rdrDayNumber {
      span {
        color:Red;
      }
    }
    //background-color:red;
  }
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background-color: #E6E6E6;    
    border-radius: 0px;
    &:hover {
      border: 0px;
    }    
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color:#666666;
  }
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge,
  .rdrDayStartOfWeek {
    border-radius: 0px;
  }
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    border-radius: 0px;
  }
  .rdrDateDisplayWrapper {
    background-color: transparent;
  }
}

.date-range-show {  
  .rdrMonthAndYearWrapper,
  .rdrMonths {
    display: flex;
    position: absolute;
    top: -10px;
    left: 200px;
    width:400px;
    background-color: #fff;
  }
  .rdrMonths {
    top: 45px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    .rdrMonth {
      margin:auto;
    }
  }
  .rdrMonthAndYearWrapper {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    padding:0px;
  }
  .rdrMonthAndYearWrapper::before {
    content: '';
    width: 0px;
    border-bottom: 10px solid #FFFFFF;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 0px solid transparent;
    position: absolute;
    -webkit-filter: drop-shadow(0 -2px 18px #333);
    filter: drop-shadow(0 -2px 18px #333);
    top: -9px;
    left: 20px;
  }
  .rdrDayHovered{
    .rdrDayStartPreview, .rdrDayEndPreview,.rdrDayInPreview {
      background-color: #d2d2d2;
      border-radius:0px;
      border:none;
      color:red;
      z-index:0;
      .rdrDayNumber {
        span {
          color:red;
        }
      }
    }
  }
  .rdrDay .rdrDayWeekend .rdrDayEndOfWeek {
    .rdrDayInPreview {
      border-radius: 0px;
    }
  }

  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayEndPreview,
  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfWeek .rdrDayStartPreview {
    border-radius: 0px;
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview {
    background-color: pink;
    border-radius: 0px;
    border: none;
    color: red;
    z-index: 0;
  }

.rdrDayStartPreview, .rdrDayEndPreview,.rdrDayInPreview {
  background-color: #d2d2d2;
  border-radius:0px;
  border:none;
  color:red;
  z-index:0;
}

.rdrPprevButton,.rdrNextButton{
  background-color: transparent;
  i {    
    border: solid #6244bb;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    line-height: 30px;
}
}
.rdrNextButton > i{  
  transform:rotate(315deg);
}
.rdrDayNumber {
  font-size:1.4rem;
  font-weight:400;
}
.rdrWeekDay {
  font-size:1.4rem;
  font-weight:700;
  color: $black;
}
.rdrNextPrevButton{
  line-height:30px;
}
.rdrNextPrevButton:hover {
  background-color: transparent;
}
.rdrNextPrevButton:focus {
  border-width: 2px solid red;
}
.rdrMonthAndYearPickers select {
  font-size:1.7rem;
  font-weight:800;
  padding:5px 5px;
  //line-height: 40px;
}
.rdrMonthAndYearPickers {
  justify-content: flex-start;
}

// .rdrMonthAndYearWrapper {
//   width:330px;
// }
  
  // :after{
  //   content: '';
  //   border: 1px solid red;
  //   border-radius: 1.333em;
  //   position: absolute;
  //   top: -2px;
  //   bottom: -2px;
  //   left: 0px;
  //   right: 0px;
  //   background: transparent;
  // }
  // .rdrDayHovered {

  //   .rdrStartEdge, .rdrEndEdge{
  //     border: 1px solid green;
  //   }
  // }
}
.editsearch-form {
  display: flex;
  justify-content: space-around;
}

.pagination-container {
  display: flex;
  user-select: none;
  justify-content: flex-end;
  & span {
    width: 27px;
    height: 27px;
    line-height: 27px;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    margin: 3px;
    border: 1px solid $united-purple;
    color: $united-purple;
  }
  & span.active {
    color: #ffffff;
    background-color: $united-purple;
  }
  & span.more-pages {
    border: none;
    font-size: 38px;
    vertical-align: middle;
    position: relative;
    top: -12px;
    cursor: default;
  }
  & span.prev-next {
    line-height: 27px;
    font-size: 18px;
    border: none;
    color: $united-purple;
    width: auto;
  }
}
.search-warning {
  width: 945px;
  height: 88px;
  background: #ffffff;
  border: 1px solid $outrageous-orange;
  border-radius: 6px;
  margin-left: 170px;
  padding:1rem;
  svg{
    height:2.4rem;
    width:4.8rem;
  }
  .warning-text {
    color: $outrageous-orange;
    font-size: $font-size-fs24;
  }
  .search-warning-message {
    font-size: $font-size-fs18;
  }
}
