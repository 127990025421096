
.linkedlogpages-container {
    margin-top: 3rem;
    display: flex;
    .linkedlogpages-data-container {
      background-color: #F7F7F7;
      height: calc(100vh - 320px);
      overflow-y: auto;
      padding-right: 2rem;
      padding-left: 2rem;
      a {
        color: #333333;
      }
    }
  }
  .linked-lp {
      padding-top: 2rem;
    .header {
      color: #333333;
      font-size: 2rem;
      font-weight: bold;
      padding-bottom: 1rem;
    }
    .linked-lp-header {
      label {
        font-size: 1.2rem;
      }
      .value {
        color: #0C2340;
        font-size: 1.8rem;
        display: block;
        width: max-content;
      }
      .task-card-details {
        color: #333333;
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
  }

  .lp-card-status {    
      min-width: 9rem;
      max-width: 9rem;
      font-size: 1.3rem;
      font-weight: $font-weight-bold;
      color: #41748d;
      border: 2px solid #41748d;
      border-radius: 3.2rem;
      height: 2.7rem;
      width: 9rem;
      align-self: center;
      text-align: center;
      line-height: 2.4rem;
      margin-right: 12.5rem;    
  }