.modal-content {
  font-size: $font-size-large;
  background-color: $white;
  border: 1px solid $mercury-gray;
  border-radius: 0.6rem;
  padding: 2rem;
  &-header {
      display: flex;
      justify-content: space-between;
  }
  h2 {
      font-size: $font-size-fs28;
      font-weight: $font-weight-bold;
      padding-bottom: 2rem;
  }
  h4 {
      font-size: $font-size-fs20;
      font-weight: $font-weight-semi-bold;
      padding: 2rem 0 0.5rem;
  }
  .closeicon{
    cursor: pointer;
    height: 1.5rem;
  }
}
.papermodal-content {
  font-size: $font-size-large;
  background-color: $white;
  border: 1px solid $mercury-gray;
  border-radius: 0.6rem;
  &-header div {
      display: flex;
      justify-content: space-between;
      // background-color: #0C2340;
      padding: 0 1rem 0 1rem;
  }
  .closeicon {
    cursor: pointer;
    margin-top: 1rem;
  }
  h2 {
      font-size: $font-size-fs24;
      font-weight: $font-weight-bold;
      // color: white;
  }
  h4 {
      font-size: $font-size-fs20;
      font-weight: $font-weight-semi-bold;
      padding: 2rem 0 0.5rem;
  }
  .paper-signoff-notification {   
    background: #FFFFFF;
    border: 2px solid rgba(244,99,53,0.25);
    border-radius: 0.6rem;
    margin: 2rem; 
    padding: 1rem;
    display: flex;   
    background-color: #FEF7F5; 
  }
  .paper-signoff-modal-padding {
    padding-left: 2rem;
  }
  .paper-signoff-notification div{
    margin: auto 0;
  }
  .papercard-cancel {
    padding-right: 3rem;
  }
  .papercard-buttongroup {
    float: right;
    display: flex;
  }
  .papercard-closeicon{
    cursor: pointer;
    width: 2.4rem;
    height: 1.6rem;
    margin-top: 1.2rem;
  }
}