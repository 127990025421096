.outer-shell {
    width:150px;
    height:32px;
    border: 1px solid #CCCCCC;
    position: relative;
    text-align: center;
    line-height:32px;
}
.progress-value {
    font-size: 16px;
    font-weight: $font-weight-semi-bold;
    position: relative;
    user-select: none;
}
.complete {
    color: #FFFFFF;
}


