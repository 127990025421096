﻿@import'../utilities/_variables.scss';

.btn {
    padding: 0 20px;
    font-family: $font-family-standard;
    text-align: center;
    cursor: pointer;
    outline: none;
    overflow: visible;
    text-decoration: none;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 24px;
    border: 0;
    min-height: 45px;
    min-width: 110px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: none;
    border-radius: 6px;
    //@include box-shadow(0, 1px, 1px, $dove-gray);
}

.btn-primary:disabled,
.btn-secondary:disabled,
.btn-primary.disabled,
.btn-secondary.disabled {
    color: #666666;
    background-color: #E6E6E6;
    border-color: #E6E6E6;
    cursor: default;
    box-shadow: none;

    &:hover {
        color: #666666;
        background-color: #E6E6E6;
        border-color: #666666;
        cursor: default;
        box-shadow: none;
    }
}


.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
    color: $white;
    background-color: $united-purple;
    text-align: center;
    width: auto;
    line-height: 45px;
}

.btn-primary:focus {
    background-color: $united-purple;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary.active {
    color: $united-purple;
    background-color: $white;
    text-align: center;
    line-height: 45px;
    height: 10px;
    //display: inline-block;
    border: 1px solid $united-purple;
}

.btn-secondary:focus {
    background-color: $white;
    color: $united-purple;
    border: 1px solid $united-purple;
}

.btn-outline-primary {
    border: 1px solid $united-purple;
    color: $united-purple;
    border-radius: 4px;
}

.btn-outline-primary:hover,
.btn-outline-primary.active {
    border: 1px solid $mariner-blue;
    color: $white;
    background-color: $mariner-blue;
    border-radius: 4px;
}

.btn-container {
    margin-top: 20px;
    display: flex;

    .btn {
        margin-right: 15px;
    }
}

label {
    &.btn {
        min-width: 100px;

        &.btn-secondary {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border: 1px solid $selector-border-color;
            background-color: $selector-color;

            &.error {
                border: 1px solid $selector-error-border-color;
            }

            &.disabled {
                border: 1px solid $nobel-gray;
                color: $nobel-gray;
                cursor: default;

                &.active {
                    border: 1px solid $selector-border-color;
                    background-color: $selector-active-color;
                    color: $white;
                    @include opacity(0.5);
                    cursor: default;
                }
            }
        }

        input[type="checkbox"]:checked {
            border: 1px solid $selector-border-color;
            background-color: $selector-active-color;
            color: $white;
        }
    }
}

.btn-link {
    display: flex;
    cursor: pointer;
    color: $united-purple;
    padding-top: 0.5rem;
    align-items: center;

    &:hover {
        color: $united-purple;
    }
}