.section-global {
   // width:100%;
    &-header {
        padding-bottom:15px;            
        & label {
            font-size: 28px;
            color: #0C2340;
            display:flex;
            font-weight:$font-weight-semi-bold;
            justify-content: center;
            align-items: center;
        }
        & label::after {
            content: '';
            border-top: 2px solid;
            margin: 0 15px 0 20px;
            flex: 1 0 41px;
            top: 2px;
            color:#D8D8D8;
            position: relative;
        }
    }
}