.workload-search-icon {
  height: 16px;
}
.workload-search-results-icon {
  width: 12rem;
  height: 12rem;
}
.workload-search-results-text {
  color: #666666;
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 3.3rem;
  text-align: center;
}

.harmonized-workload-search {
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  & > div {
    flex: 1;
    margin-right: 3rem;
  }
  .assigned-work-checkbox {
    align-self: flex-end;
    padding-bottom: 1.5rem;
    font-size: 1.6rem;
    height: inherit;
    display: flex;
    align-items: center;
    input {
      width: 26px;
      height: 18px;
    }
    span {
      padding-left: 1rem;
    }
  }
  .taskcard-search {
    padding-top: 0.4rem;
    input {
      min-width: 200px;
    }
  }
  .btn {
    width: 20rem;
    margin-right: 4rem;
  }
  .advance-search-link{
    margin-top: 29px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    span{
      color: #6648bf !important;
    }
    svg{
      width: 12px;
      margin-left: 7px;
    }
  }
}
.field-align{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 5px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1400px) {
  .workload-search {
    width: 95%;
  }
}
.workload-search-results {
  height: calc(100vh - 365px);
  width: 100%;
  border-top: 2px solid $cool-gray;
  background-color: #f7f7f7;
  padding: 15px;
  display:flex;
  justify-content: center;
  align-items: center;

  .workload-search-results-content {
    display: flex;
    justify-content: center;
    height:100%;
    width: 100%;
    //overflow-y: auto;
    // Added below media print as user is trying to print the screen with ctrl p , whole content is getting hidden
    @media print{
      overflow-y: visible;
    }
    .workload-search-scroll-class {
      height: calc(100vh - 365px);
      overflow-y: auto;
    }
  }
  .workload-search-info{
    display:flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
  }
}
.search-workload-tabs {
  border-top: 1px solid #CCCCCC;
}

// Advanced search modal
.search-criteria-advanced-search-modal{
  .advanced-search{
    .select-box--items .list-item{
      min-height: 30px;
    }
  }
}

.advance-search-modal {
  width: 676px;
  .advanced-search{
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem 1.2rem;
    padding: 5px 15px;  
    margin-bottom: 15px;    
    .orgDoc-input{
      padding-top: 3px;
      padding-right: 0px !important;
      input {
        width: 316px;
      }
    }
  }
}
