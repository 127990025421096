.menu-button {
    height: 48px;
    width: 230px;
    border-radius: 6px;
    border: 1px solid $united-purple;
    color: $united-purple;
    display: flex;
    //position: relative;
    cursor: pointer;
    background-color: #FFFFFF;
    .menu-dropdown-button {
        width: calc(100% - 45px);
        align-self: center;
        text-align: center;
    }
    .menu-dropdown-icon {
        width: 45px;
        background-color: $united-purple;
        border-radius: 0 6px 6px 0;
    }
    .nr-action-menu-img {
        transform: rotate(90deg);
        margin-left: 25;
        margin-left: 15px;
        margin-top: 11px;
        width: 16px;
        height: 26px;
    }
}
.menu-dropdown-list {
    position: absolute;
    background-color: white;
    width: 230px;
    border-radius: 6px;
    border: 1px solid #6422bb;
    margin-top: 5px;
    z-index: 999;
    .menu-dropdown-item {
        height: 40px;
        line-height: 40px;
        align-self: center;
        font-weight: $font-weight-semi-bold;
        cursor: pointer;
        padding-left: 12px;
        border-top: 1px solid #cccccc;
    }
    .menu-dropdown-item-link {
        color: black;
    }
    .menu-dropdown-item:hover {
        background-color: $united-purple;
        color: #ffffff;
    }
    .menu-dropdown-item:not(:last-child) {
        border-bottom: 1px solid #cccccc;
    }
}

.nr-actions {
    @media print {
        &.hide{
          display: none!important;
        }
    }
    width: 100%;
    font-size: 1.4rem;
    color: #333333;
    .description {
        font-weight: $font-weight-bold;
        font-size: 2rem;
    }
    .nr-overview {
        display: flex;
        justify-content: space-between;
        width: 90%;
        padding-bottom: 20px;
        .nr-id {
            font-weight: $font-weight-bold;
            font-size: 2.4rem;
            align-self: center;
        }
        .od-number,
        .spec-number,
        .zone-val,
        .ata-val {
            .value {
                font-size: 16px;
                font-weight: $font-weight-bold;
            }
        }
        .dd-wrapper {
            margin-left: 30px;
            margin-top: -3px;
            .dd-list {
                height: 400px;
                overflow: auto;
              }
        }
        .add-pdf {
            color: $united-purple;
            margin-top: 10px;
            display: inline-block;
            cursor: pointer;
            text-decoration: underline;
          }
    }
    .create-nr-subtask {
        hr {
            display:none;
        }
    }
    // .view-non-routine {
    //     @media print {
    //       display: none;
    //     }
    // }
}

.material-labor-section-container {
    width: 60%;
    // margin-top:20px;
    .materials-table {
        margin-bottom:15px;
    }
}
.rotable-parts-info {
    border: 1px solid #CCCCCC;
    background-color: #FAFAFA;
    border-radius: 6px;
    padding: 10px 20px;
    width: 600px;
    margin-bottom: 10px;
    svg {
        height:24px;
        width:24px;
        margin-right:15px;
    }
}

.update-attributes {
    justify-content: space-between;
    flex-flow: wrap;
    width:100%;
}
.update-textarea-field {
    min-width: 72rem;
    max-width: 72rem;
    display: -ms-grid;
    display: grid;
    display: -moz-grid;
}
.row-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 3rem;
    margin-right:3rem;
    width: 100%;
    .zone-ata-margin {
        margin-right: auto;
        margin-left: 15px;
    }
}
// Rotable parts confirmation modal
.rotable-parts{
    .modal-maintext{
        font-size: 28px;
        color: #333333;
    }
    .btn-link{
        font-size: 1.4rem;
        width: 45px;
    }
   
}

.flex-col{
    flex-direction: column !important;
}

.upload-pdf-modal {
    width: 765px;    
    .textarea-holder{
        label {
          display:block;
        }     
    }
    .buttons-holder {
        display: flex;
    }
    input[type="file"] {
        display: none;
      }
      .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
      }
      .pdf-list {
        padding: 20px 0px;
        margin-left: 10px;
        .pdf-delete{
            padding-bottom: 5px;
        }
        .pdf{
            padding: 0px 10px;
        }
        .confirm-message{
            .button-container{
                gap:2rem;
                margin-left: auto;
                cursor: pointer;
            }
        }
        .delete-icon{
            cursor: pointer;
            width: 18px;
            height: 20px;
        }
      }
}