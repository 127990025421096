.groups-summary {
    .group-card {
        display: flex;
        flex-wrap: wrap;
        max-width: 1700px;
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 6px;
        font-size: $font-size-large;
        background-color: $white;
        padding: 28px 30px;
        position: relative;
        margin: 3rem auto;
        color: #333333;
        > div {
            align-self: center;
            display: flex;
        }
        &-info {
            min-width: 28%;
            max-width: 28%;
            word-break: break-all;
            white-space: normal;
            overflow-wrap: break-word;
        }
        .group-card-update {
            padding-right: 50px;
            width: 150px;
            //margin-left: auto;
            label {
                color: #6244bb;
                font-size: 16px;
                font-weight: 600;
                align-self: center;
                cursor: pointer;
            }
            .update-link, .delete-link {
                display: flex;
                cursor: pointer;
            }
            .delete-link {
                svg {
                    margin-right:5px;
                }
                label {
                    margin-bottom:0px;
                }
            }
        }
        &-stats {
            width: 400px;
            padding-right: 50px;
            display: flex;
            justify-content: space-between;
            &-count {
                padding-right: 10px;
                display: flex;
                div {
                    font-size: 30px;
                }
                label {
                    margin: auto 0 0.5rem 0.5rem;
                }
            }
        }
        &-shifts {
            display: flex;
            width: 320px;
            > .shift-details {
                margin-right: 40px;
                margin-top: 0px;
            }
        }
        &-title {
            font-size: $font-size-fs20;
            font-weight: $font-weight-semi-bold;
        }
        &-chevron {
            position: absolute;
            right: 0px;
            top: 0px;
            min-width: 50px;
            height: 100%;
            display: flex;
            align-items: center;
            align-content: center;
            svg {
                cursor: pointer;
            }
        }

        .value {
            font-weight: $font-weight-bold;
            padding-left: 8px;
        }
        .seperator::after {
            content: "|";
            padding-left: 5px;
            padding-right: 5px;
        }
        // &:hover {
        //     border: 3px solid $united-purple;
        // }
    }
}
.shift-grave-icon {
    transform: rotate(220deg);
}
