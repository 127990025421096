.taskcard-content-section {
    //margin-left: 55px;
    font-size: $font-size-large;
    padding: 0px;

    .sign-off-btn {
        //min-width: 20rem;
        // max-width: 20rem;
        align-self: center;
    }

    // .btn:not(:disabled):not(.disabled) {
    //     color: #fff;
    //     background-color: $united-purple;
    //     border-color: $united-purple;
    // }
    h3 {
        color: $black;
        margin: 2rem 0;
    }

    .taskcard-content {
        margin-top: 0.5rem;
        height: calc(100vh - 460px);
        overflow: auto;
        padding-left: 25px;
    }

    .taskcard-buttons {
        display: flex;
        justify-content: flex-end;
        height: 80px;
        padding-right: 25px;
        -webkit-box-shadow: 0px 5px 5px -1px rgba(102, 102, 102, 0.77);
        -moz-box-shadow: 0px 5px 5px -1px rgba(102, 102, 102, 0.77);
        box-shadow: 0px 5px 5px -1px rgba(102, 102, 102, 0.77);

        .nr-blocking-info-container {
            align-self: center;
            padding-left: 20px;

            .nr-blocking-info {
                width: 340px;
                font-size: $font-size-normal;
                line-height: 45px;
                background-color: rgba(244, 99, 53, 0.1);
                border: 2px solid rgba(244, 99, 53, 0.2);
                border-radius: 6px;
                justify-content: center;
                display: flex;
                align-items: center;

                svg {
                    width: 42px;
                    height: 42px;
                    padding-right: 20px;
                }
            }
        }

        .bulk-action-buttons {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;

            .btn:not(:last-child) {
                margin-right: 20px;
            }

            .bulk-button {
                align-self: center;
            }

            .primary-action-button {
                flex-basis: 153px;
                flex-grow: 0;
                flex-shrink: 0;
            }

            .secondary-action-button {
                flex-basis: 200px;
                flex-grow: 0;
                flex-shrink: 0;

                .thumbsup-icon,
                .thumbsdown-icon {
                    height: 24px;
                    margin-right: 10px;
                }

                span {
                    white-space: nowrap;
                }
            }
        }
    }

    .sub-section {
        margin-bottom: 1rem;

        div {
            color: $night-rider-gray;
        }
    }

    .sub-header {
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-fs20;
        color: $black;
        margin-bottom: 1rem;

        .sub-header-desc {
            font-weight: $font-weight-normal;
            font-size: $font-size-normal;
        }
    }

    .content-holder {
        min-width: 24rem;
        padding: 2px 10px 2px 0px;
        font-size: $font-size-large;

        label {
            font-weight: $font-weight-semi-bold;
            color: $cod-gray;
        }

        span {
            padding-left: 10px;
            color: $night-rider-gray;
            margin-bottom: 15px;
            word-break: break-all;
        }
    }

    .content-group {
        padding-bottom: 10px;
        padding-top: 5px;

        label {
            margin-bottom: 0px;
        }
    }

    .illustrations {
        padding-left: 0;

        div {
            min-width: 22rem;
            width: auto;
        }
    }

    .generalinstructions-content {
        .main-bullet-point {
            white-space: pre-line;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .sub-bullet-point {
            padding-left: 25px;
        }
    }

    .references {
        &-content {

            // padding-left: 5px;
            &-subheader {
                width: 700px;
                margin-bottom: 15px;
                display: block;

                div {
                    // padding-left: 10px;
                    display: flex;
                    min-width: 22rem;
                }
            }
        }
    }

    .additionalinfo {
        .additionalinfo-content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .additionalinfo-content div .col-md-4 {
            flex-wrap: wrap;
            min-width: 22rem;
            padding: 1em;
        }

        .additionalinfo-contentdiv {
            border-bottom: 1px solid #666666;
        }

        .sub-header {
            border-bottom: 1px solid $dove-gray;
        }
    }

    .earevisions-refdoc {
        white-space: pre-line;
    }

    .warning-message-1 {
        color: $crimson-red;
    }

    .ptm-block {
        h3 {
            border-bottom: 1px solid $dove-gray;
            padding-bottom: 1.5rem;
        }

        .list-holder {
            margin-bottom: 1.5rem;
            border-bottom: 1px solid $dove-gray;
            padding-bottom: 1rem;
        }
    }
}

.taskcard-container {

    .taskcard-feedback {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //justify-content: center;
    }
}

.illustrtions-modal {
    height: 90rem;
    width: 130rem;
    padding: 0;
    border: 0;

    .modal-header {
        height: 8.4rem;
        width: 130rem;
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        -webkit-print-color-adjust: exact !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem !important;

        p {
            margin-bottom: 0px;
        }

        .illustration-title {}

        .control-container {
            display: flex;
            justify-content: center;
            height: inherit;
            align-items: center;

            img {
                margin-right: 2rem;
                cursor: pointer;
            }

            .chevron-left {
                transform: rotatey(180deg);
                cursor: pointer;
                margin-right: 2rem;
            }
        }

        .list-item-icon {
            cursor: pointer;
        }
    }

    h2 {
        color: $white;
        font-weight: 500;
        padding: 0;
    }

    .modal-body {
        display: flex;
        justify-content: center;
        overflow: auto;
        //align-items: center;

        .zoomin {
            height: 131rem;
            width: 114rem;
            // -webkit-transform:scale(1.5); /* or some other value */
            // transform:scale(1.5);
            transition: transform 0.25s ease;
        }

        .zoomout {
            // -webkit-transform:scale(-1.5); /* or some other value */
            // transform:scale(-1.5);
            height: 31rem;
            width: 60rem;
            transition: transform 0.25s ease;
        }

        .illustration-spinner {
            display: flex;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}

@media print {
    .illustrtions-modal {
        height: 100%;

        .modal-header {
            h2 {
                padding-left: 125px;
            }

            .control-container,
            .list-item-icon {
                display: none;
            }
        }
    }
}

.print {
    min-width: 120px;
    text-align: center;
    display: flex;

    // flex-direction: column;
    svg {
        height: 28px;
        width: 28px;
        cursor: pointer;
        align-self: center;
    }
}

.notes-modal {
    min-height: 74rem;
    max-height: 74rem;
    max-width: 76rem;
    margin: 4rem;
    padding: 0;

    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 5rem;
        padding: 1rem;

        h3 {
            color: $white;
            padding: 0;
            font-weight: 400;
            margin-left: 1rem;
        }
    }

    .modal-content-body {
        padding: 2rem;
        max-height: max-content;
        overflow: auto;

        .add-new-note {
            color: #333333;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        .notes-input-container {
            position: relative;

            .count {
                position: absolute;
                bottom: 1px;
                right: 10px;
            }
        }

        .input-notes {
            display: flex;
            flex-direction: column;

            & textarea {
                max-width: 100%;
                height: 17rem;
            }
        }

        .btn-section-modal {
            margin: 2rem 0;

            .btn {
                margin: 0;
            }
        }

        .notes-container {
            max-width: 100%;
            font-size: 1.4rem;

            .list-item-note {
                display: flex;
                padding-bottom: 15px;

                .avatar {
                    max-height: 54px;
                    min-width: 54px;
                    background-color: #e6e6e6;
                    border-radius: 50%;
                    color: #333333;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 2rem;
                }

                .added-by {
                    color: #333333;
                }

                .date {
                    color: #949494;
                    font-size: 12px;
                }

                .note {
                    color: #000000;
                    padding: 2rem 0;
                }
            }
        }
    }
}

@media print {

    // .taskcard-container-print{
    .navigation-panel,
    .print,
    .taskcard-buttons,
    .linked-items {
        display: none;
    }

    .taskcard-content {
        max-height: none !important;
        overflow: scroll !important;
        display: block;
        
        &.hide{
            display: none!important;
        }
        height: auto !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }

    .block-card,
    .header-content {
        clear: both;
        page-break-before: auto !important;
        page-break-inside: avoid !important;
        page-break-after: auto !important;
    }

    // }
}

@import "../BlockContent/blockcontent";