.accordion-item {
  margin-bottom: 30px;
  $self: &;

  &--opened {
    background-color: #ffffff;
    border-radius: 6px;

    #{ $self }-icon {
      transform: rotate(180deg) !important;
    }

    #{ $self }-content {
      //max-height: 2000rem;
      max-height: 100%;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: height;
    }
  }

  &-icon {
    transition: transform 0.3s ease-in-out;
  }

  &-content {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }

  .sort-container {
    position: absolute;
    width: 180px;
    background-color: white;
    right: 41px;
    z-index: 1;
    border: 1px solid #e6e6e6;
    list-style: none;
    padding: 0px;
    font-size: 14px;
    display: none;

    & li {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
    }

    & li:hover {
      background-color: $united-purple;
      color: #ffffff;
    }

    & li.selected {
      background-color: $united-purple;
      color: #ffffff;
    }

    & li.selected::after {
      content: "\2713";
      position: absolute;
      right: 10px;
    }
  }

  .open {
    display: block;
  }
}

.accordion-header {
  display: flex;

  & label {
    font-size: 24px;
    color: $united-purple;
    font-weight: 500;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px;
    padding-left: 10px;
    line-height: 20px;
  }

  .no-accordion {
    padding-left: 0px;
  }

  & label::after {
    content: "";
    border-top: 2px solid;
    margin: 0 15px 0 20px;
    flex: 1 0 41px;
    top: 2px;
    color: #d8d8d8;
    position: relative;
  }
}

.accordion-header {
  &.dash-8 {
    align-items: center;
    margin: 0 24px 0 0;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid #979797;

    label {
      font-size: 17px;
      font-weight: 600;
      color: #000000;
      padding-left: 0;
      justify-content: flex-start;
    }

    & label::after {
      content: none;
    }

    img {
      height: 16px;
      margin-top: 6px;
    }
  }
}