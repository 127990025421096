.update-visit-span {
    width:1021px;
    font-size:14px;
    background-color: #ffffff;
    border:1px solid #E6E6E6;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
    &-title {
        font-size:28px;
        font-weight: $font-weight-bold;
        padding-bottom:20px;
        .close-icon {
            position: absolute;
            right:20px;
            cursor:pointer;
        }
    }
    &-subtitle {
        font-size:24px;
        font-weight: $font-weight-bold;
    }
    &-reason {
        height:150px;
        width:380px;
        border: 1px solid #333333;
        border-radius: 6px;
    }
    .visit-span-section {        
        .input-set {
            margin-bottom:20px;
            .invalid-start {
                color: #D50032;
            }
            .invalid-start-message {                
                width:250px;
                padding-top:5px;
                font-size:12px;
                font-style: italic;
            }
            .invalid-start-icon{
                width:16px;
                height:16px;
                padding-right:5px;
            }
           
            .datetime-input {
                width: 200px;
                height: 45px;
                border: 1px solid #333333;
                border-radius: 6px;
                padding:12px;
            }
            .calendar-icon {
                cursor: pointer;
                margin-left:10px;
                height:40px;
            }
        }
        .input-set-hide {
            display: none;
        }
    }    
    .comments-required::before {
        content:" *";
        color: red;
      }
    .btn-update {
        width: 150px;
        // background-color: #E6E6E6;
        // color: #666666;
        // border: 1px solid #666666;
        font-size:16px;
        line-height:45px;
    }
    .disabled {
        cursor: not-allowed;
    }
    // .btn-link {
    //     cursor: pointer;
    //     color: #6244BB;
    //     padding-top: 5px;
    // }

}

@media screen and (max-width: 1400px) { 
    .update-visit-span-title {
        padding-bottom:15px;
    }
    .update-visit-span-reason {
        height:50px;
    }
    .update-visit-span .visit-span-section .input-set {
        margin-bottom: 15px;
    }
}