.skeleton,
.view-only-mode {
    .tile-link {
        display: none !important;
    }
    // .milestone-card {
    //     pointer-events: none;
    // }
    .timeline-link {
        display: none !important;
    }
}

.view-only-mode {
    .scheduling-header {
        .counts {
            display: none !important;
        }
        
        
    }
    .milestone-title {
        pointer-events: none;
        .link {
            text-decoration: none;
        }
    }
    .details-icon {
        display: none !important;
    }
}

.template-details-header {
    background-color: rgba(12, 35, 64, 0.1);
    margin: 0 -15px;
    padding: 15px 35px 0px 35px;
    display: flex;
    .template-details-header-title {
        font-size: 20px;
        font-weight: 600;
        align-items: flex-end;
    }
}
.template-details {
    background-color: rgba(12, 35, 64, 0.1);
    margin: 0 -15px;
    padding: 15px 35px 35px 35px;
    display: flex;
    .template-stats {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
    }
    .scheduling {
        margin-left: 20px;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        color: #333333;
        font-size: 14px;
        border-radius: 6px;
        &-header {
            background-color: #002244;
            color: #ffffff;
            height: 72px;
            border-radius: 6px 6px 0px 0px;
            display: flex;
            padding: 15px;
            font-size: 14px;
            justify-content: space-between;
            .title {
                width: 356px;
                min-width: 356px;
                font-size: 20px;
                font-weight: $font-weight-semi-bold;
            }
            .counts {
                display: flex;
                justify-content: space-around;
                padding: 0px 15px;
                border-right: 1px solid #ffffff;
                border-left: 1px solid #ffffff;
                > div:first-child {
                    border-right: 1px solid #ffffff;
                    padding-right: 30px;
                }
                .btn-link {
                    color: #FFFFFF;
                    padding-top: 0px;
                    text-decoration: underline;
                }
            }
            .timeline-link {
                justify-content: flex-end;
                display: flex;
                align-items: center;
                font-size: 18px;
                width: 150px;
                font-weight: $font-weight-semi-bold;
                cursor: pointer;
                svg {
                    width: 16px;
                    height: 16px;
                }
                a {
                    color: #ffffff;
                }
            }
        }
        &-content {
            display: flex;
            min-height: calc(100vh - 147px);
            &-groups {
                width: 371px;
                min-width: 371px;
                border-right: 1px solid #cccccc;
                .group-header {
                    font-size: 16px;
                    font-weight: $font-weight-semi-bold;
                    display: flex;
                    align-items: center;
                    height: 60px;
                    padding-left: 15px;
                    border-bottom: 1px solid #cccccc;
                }
                .selected-milestone {
                    background-color: rgba(105, 179, 231, 0.15);
                }
                .milestone-card {
                    border-bottom: 1px solid #cccccc;
                    font-weight: 14px;
                    padding: 15px;
                    cursor: pointer;
                    .link {
                        color: #0c2340;
                        cursor: pointer;
                    }
                    .milestone-title {
                        font-size: 16px;
                        font-weight: $font-weight-semi-bold;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .milestone-schedule {
                        display: flex;
                        justify-content: space-between;
                        font-size: 13px;
                        div {
                            width: 50%;
                        }
                        .seperator {
                            width: 15px;
                            span {
                                border-right: 1px solid #333333;
                            }
                        }
                        span {
                            padding-right: 4px;
                        }
                        .schedule-info {
                            font-weight: $font-weight-bold;
                        }
                        .shift-grave-icon {
                            width: 15px;
                            height: 15px;
                        }
                        .shift-day-icon {
                            width: 15px;
                            height: 15px;
                        }
                        .shift-swing-icon {
                            width: 15px;
                            height: 15px;
                        }
                        .milestone-status {
                            padding-left: 2em;
                            .schedule-status-all {
                                font-weight: 700;
                            }
                            .schedule-status-green {
                                font-weight: 700;
                                color: #4d9d33;
                            }
                        }
                    }
                }
                .selected-milestone {
                    border-left: 4px solid #0c2340;
                    padding-left: 11px;
                    background-color: rgba(105, 179, 231, 0.15);
                }
            }
            &-cards {
                .scheduling-tabs, .open-close-tabs {
                    margin-top: 20px;
                }
                .scheduling-taskcard {
                    width: 100%;
                    .task-card {
                        width: 100%;
                        .task-card-section {
                            width: 185px;
                            min-width: 185px;
                            align-self:center;
                        }
                        .paper-width {
                            width: 190px;
                            min-width: 190px;
                        }
                        .card-icons {
                            width: 110px;
                            min-width: 110px;
                        }
                    }
                }
            }
        }
    }
}
.template-info {
    // display:flex;
    // width: 70%;
    flex-wrap: wrap;
    .content-holder {
        width: 200px;
        padding-bottom: 10px;
        padding-right: 30px;
        .content-value {
            font-weight: $font-weight-bold;
            font-weight: 700;
            height: 45px;
            display: flex;
            align-items: center;
        }
    }
    .template-search-fields .options-container {
        width: 330px;
    }
    .disabled {
        cursor: not-allowed;
        pointer-events: all !important;
        color: #949494;
    }
}
.apply-template-modal {
    width: 500px;
    height: 250px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    position: relative;
}
.close-apply-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.wrkload-group-details-container-nr {
    border: 1px solid #949494;
    border-radius: 6px;
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    .workload-group-nr-desc {
        //max-width: 23rem;
        min-width: 100px;
        font-weight: bold;
    }
    & > div {
        padding: 0 1.8rem 0 0;
        &:last-child {
            padding-right: 0;
        }
    }
    .wrkload-group-icon-holder {
        display: flex;
        align-items: center;
        width: 90px;
        min-width: 90px;
    }
}

.assign-crew-link {
    align-self: center;
    margin-right:30px;
}

.execution-toggle {
    padding-top: 10px;
    margin-right: 30px;
    font-size: 18px;
}

.update-template-modal {
    width: 80%;
    height: 80vh;
    background-color: #ffffff;
    border-radius: 6px;
    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 5rem;
        padding: 0px 20px;
        h3 {
          color: #ffffff;
          margin-left: 1rem;
        }
      }
      .template-search {
          padding: 0 15px;
          .template-search-tabs {
              margin: 0px;
          }
          .tab-list, .template-search-type, .template-search-note {
              display:none;
          }
          .template-search-results {
              height: calc(80vh - 250px);
              overflow-y: auto;
          }
      }
}
// .template-timeline {
//     .react-calendar-timeline .rct-item.past {
//         border-color: $united-purple !important;
//     }
//     .react-calendar-timeline .badge-secondary.past {
//         color: $united-purple;
//         border-color: $united-purple !important
//     }
// }

.delete-template-modal{
    width: 800px;
    .modal-content{
        padding: 0;
        border: 0;
        .modal-content-header{
            padding: 1em;
            background-color:#002244;
            height: 60px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            h4{
                color: white;
                line-height: 1;
                padding: 0;
            }
            .delete {
                filter: brightness(0) invert(1);
            }
        }
    }
    .modal-content-body{
        width: 100%;
        padding: 0 2em 2em;
        .password-container{
            width: 80%;
            position: relative;
            input{
                width: 60%;
                padding: 5px;
            }
        }
        button{
            margin-left: auto;
        }
        .btn-link{
            position: absolute;
            bottom: 2.5em;
            z-index: 999;
            right: 11em;
        }
    }
}