.ptsummary-expand-collapse {
    position: absolute;
    top: 16rem;
    right: 0;
    color: #6244bb;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
    padding-right: 1.5rem;
}
.ptsummary-dash8-desc {
    display: flex;
    flex-direction: column;
    padding-right: 3rem;
    line-height: initial;
    .dash8-desc-label {
        font-weight: 500;
        font-size: 1.2rem;
    }
    .dash8-desc-value {
        font-size: 1.6rem;
        font-weight: bold;
    }
}
.ptsummary-dash8-number {
    display: flex;
    flex-direction: column;
    padding-right: 3rem;
    line-height: initial;
    .dash8-number-label {
        font-weight: 500;
        font-size: 1.2rem;
    }
    .dash8-number-value {
        font-size: 1.6rem;
        font-weight: bold;
    }
}
.ptsummary-contact {
    font-weight: 500;
    width:300px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ptsummary-contact-icon {
    height: 1.8rem;
  }
.ptsummary-content {
    .accordion-header {
      min-height: 6rem;
      display: flex;
      align-items: center;
      .title {
        font-size: $font-size-normal;
        font-weight: $font-weight-semi-bold;
        width: 100%;
        // div {
        //     &:first-child {
        //       flex-grow: 1;
        //     }
        //     &:nth-child(2) {
        //         flex-grow: 1;
        //       }
        //       &:nth-child(3) {
        //         flex-grow: 1;
        //       }
        //   }
      }
    }
    .accordion-item-content {
      display: block;
      border-bottom-left-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
      border-left: 1px solid #E6E6E6;
      border-right: 1px solid #E6E6E6;
      border-bottom: 1px solid #E6E6E6;
      background-color: #ffffff;
    }
    .accordion-item--opened {
      background: #f6f7f8;
    }
    .pt-group {        
      background-color: #ffffff;
    }    
    .border-top {
      border-top: 1px solid #E6E6E6;
    }
    th, td {
      padding: 5px;
    }
    th {
      text-align: left;
    }
    table {          
      empty-cells: hide;
    }
    table tr td:first-child {
        border: none;
    }
    table tr:last-child {
      border: none;
    }
    .w-5 {
      width: 7%;
    }
    .w-10 {
      width: 7%;
    }
    .w-15 {
      width: 12%;
    }
    .w-50 {
      width: 54%;
    }
    .pt-header, .pt-content-title {
        color: #333333;
        font-size: $font-size-normal;
        font-weight: bold;
        padding-right: 2rem;
    }
    .pt-content-value {
        color: #333333;
        font-size: 1.5rem;
        letter-spacing: 0;
        padding-right: 2rem;
    }
    .pt-content-mepn-value {
        color: #0C2340;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        padding-right: 2rem;
    }
    
  }
  