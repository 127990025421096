.breadcrumbs {
    font-size: 14px;
    color: $united-purple;
    margin-top: 12px;
    padding-left: 20px;
    display: flex;
    // height:40px;
    // align-items:center;
    .page {
        padding-right: 10px;
    }
    a {
        color: $united-purple;
        text-decoration: none;
    }
    // a:last-child {
    //     color:#666666;
    //     text-decoration: none;
    // }
    .bread-crumb {
        margin-right: 10px;
        cursor: pointer;
        //color:$united-purple !important;
    }
    .bread-crumb::after {
        // content : '>';
        // color: $united-purple;
        // padding-left:10px;
        // cursor: default;
        // font-size: 1.7rem;
        // font-weight: $font-weight-bold;
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: "";
        display: inline-block;
        height: 10px;
        left: 2px;
        position: relative;
        top: 6px;
        transform: rotate(45deg);
        vertical-align: top;
        width: 10px;
    }
    .ellipsis-menu {
        padding: 0px 10px;
        span {
            font-size: 3.5rem;
            height: 0.9rem;
            line-height: 0;
            top: -2px;
            position: relative;
        }
        &:hover {
            color: $white;
            background: rgba(96, 68, 187, 0.7);
            //transform: scale(1.1);
            //transition-duration: 0.3s;
            border-radius: 6px;
        }
        &.active {
            color: $white;
            background: $united-purple;
            transform: scale(1.1);
            transition-duration: 0.3s;
            border-radius: 6px;
        }
        &.disabled {
            color: $silver-gray;
            cursor: not-allowed;
            &:hover {
                color: $silver-gray;
                background: transparent;
            }
        }
    }
    .ellipsis-menu::after {
        font-size: 1.4rem;
    }
    .ellipsis-active {
        background-color: $united-purple;
        color: #ffffff;
        border-radius: 6px;
    }

    [class~="bread-crumb"]:last-of-type {
        color: #666666 !important;
        cursor: default;
    }
    [class~="bread-crumb"]:last-of-type::after {
        content: "";
        display: none;
    }
}
.current {
    color: #666666;
}

.bread-crumbs-ellipsis {
    position: relative;
    background-color: #ffffff;
    z-index: 3;
    flex-direction: column;
    display: flex;
    border: 1px solid #6244bb;
    border-radius: 6px;
    left: 25px;
    top: 10px;
    cursor: default;
    .bread-crumb-ellipsis {
        padding: 10px 20px;
        border-bottom: 1px solid $silver-gray;
    }
    .bread-crumb-ellipsis:last-child {
        border-bottom: 0px solid white;
    }
    .bread-crumb-ellipsis:hover {
        background-color: $united-purple;
        color: #ffffff;
    }
    a {
        color: $united-purple;
        text-decoration: none;
    }
}

.subheader-search-container {
    margin: 20px 20px 0px auto;
    display: flex;
    z-index:1000;
    .search-input {
        width: 300px;
        color: #333333;
        font-size: 14px;
        border-width: 0 0 2px 0;
        border-color: $united-purple;
        text-indent: 30px;
        padding-bottom: 5px;
    }
    input {
        background-color: transparent;
    }
    .search-icon {
        position: relative;
        left: 22px;
        top: 5px;
        width: 16px;
        height: 16px;
    }
}
.master-card-search {
    .is-draft {
        font-size: 1.8rem;
        font-weight: $font-weight-semi-bold;
        padding-top: 30px;
        margin-left: 18px;
    }

    .draft-preview {
        margin: 20px 20px 0px 18px;
    }
}
.icon {
    font-size: 3rem;
    padding-right: 10px;
    font-weight: 400;
}
.print-button {
    display: flex;
    justify-content: space-around;
    img {
        height: 18px;
        width: 18px;
    }
}

// .loading {
//     span{
//         background-color: #E6E6E6;
//         color:#E6E6E6;
//     }
//     .back-to-dashboard{
//         background-color: #E6E6E6;
//         color:#E6E6E6;
//         border-color: #E6E6E6;
//     }

// }
.dashboard-subheader-actions {
    padding: 10px 35px 0px 20px;
}
.subheader-buttons {
    display: inline-flex;
    margin-left: auto;
    .subheader-btn {
        width: 150px;
        height: 45px;
        font-weight: $font-weight-semi-bold;
        font-size: 14px;
        text-align: center;
    }
    .addvisit-btn {
        // background-color: #6244bb;
        border: 1px solid #6244bb;
        color: #6244bb;
        margin-right: 10px;
    }
    .viewhistory-btn {
        color: #6244bb;
        border: 1px solid #6244bb;
    }
    .back-to-dashboard {
        width: auto;
    }
}
.dashboard-subheader {
    padding: 8px 35px 10px 20px;
    position:relative;
    z-index:10007;
    background-color: #0c2340e6;
    .subheader-title {
        color: #FFFFFF;
    }

    // .subheader-title {
    //     font-weight: $font-weight-semi-bold;
    //     font-size: 32px;
    //     color: #000000;
    // }
    
}

.dashboard-visit-summary {
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10008;
}
.timeline-links {
    // width:300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.group-summary {
        margin-right: 18rem;
    }
    span {
        height: 24px;
        color: #6244bb;
        font-size: 1.8rem;
        letter-spacing: 0;
        line-height: 24px;
        text-align: right;
        font-weight: bold;
    }
    a {
        display: flex;
    }
    svg {
        padding-top: 0.3rem;
        height: 2.4rem;
        width: 2.4rem;
    }
}
.visit-header {
    //background-color: #0c2340;
   // opacity: 0.9;
   background-color: #0c2340e6;
   position:relative;
   z-index:10008;
    .subheader-title,
    .bread-crumb,
    .breadcrumbs [class~="bread-crumb"]:last-of-type {
        color: #fff !important;
        text-decoration: none;
    }
    .bread-crumb{
    text-decoration: underline;
    }
}
.print-icon{
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.navigation {
    color: #FFFFFF; 
    z-index: 1000;
    margin-right: 25px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;  
        span {
            cursor: pointer;
        }      
        span:after {
            content: '';
            border: solid #FFFFFF;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            position: relative;
            top:-3px;
            left: 6px;
        }
      }
      ul li {
        display: block;
        position: relative;
        float: left;
        
      }
      
    li ul { display: none; }
    ul li {
    display: block;
    padding: 10px 25px;
    text-decoration: none;
    white-space: nowrap;  
    a {
        color: #FFFFFF !important;
    }      
    }
    ul li:hover { text-decoration: underline; text-decoration-thickness: 2px;  }

    li:hover > ul {
        display:block;
        position: absolute;
        z-index: 1000;        
        color: #fff;
        background-color : #243953;
        margin-top:5px; 
        @include box-shadow(0, 3px, 6px, #243953);
    }
    li:hover li { float: none; }
    //li:hover a { background: #1bc2a2; }
    li:hover li a:hover { text-decoration: underline; text-decoration-color: #FFFFFF; }
    .main-navigation li ul li { border-top: 0; }    
}

.visit-theme {
    font-size: 15px;
    ul li {      
        a {
            color: #243953 !important;
        }      
    }
    li:hover > ul {         
        background-color: #fff;
        color : #243953;
        margin-top:5px; 
        @include box-shadow(0, 3px, 6px, $silver-gray);
    }
  
    li:hover li a:hover { text-decoration: underline; text-decoration-color: #243953; }
   
}