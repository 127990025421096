$theme-color:#0c2340;
$white:#fff;
.baseline-container{
    width: 95%;
    .baseline-header{
        display: flex;
        width: 98%;
        justify-content: flex-end;
        .date-view{
            font-size: 23px;
            color: $theme-color;
        }
        .baseline-expand-collapse{  
            cursor: pointer;
            display: flex;
            flex-flow:row-reverse;
            margin-top: 10px;
            text-decoration: underline;
            font-weight: 700;
            color: #6244bb;
        }
    }

    // baseline contents
    .baseline-content{
        .col-12{
            .search-accordion{
                .search-accordion-item{
                    
                    background-color: $theme-color!important;
                    .search-accordion-item-header{
                        height: 95px;
                        background-color: $theme-color!important;
                        border-radius: 10px;
                        .routine-card .card-details {
                            .slp-title{
                                padding-left: 20px;
                            }
                            .slp-mel-value,.slp-title{
                            color: $white!important;
                        }}
                        .accordion-icon{
                            color:$white!important;
                            svg{
                                transition: .5s;
                                &.searchactive{
                                    transition: .5s;
                                    transform: rotate(90deg);
                                }
                            }
                        }
                        .dash-number-value,
                        .dash-number-label,
                        .accountability-date-value,
                        .position-serial,
                        .CircularProgressbar-text,
                        .card-desc{
                            color: $white;
                        }
                        .card-content-holder {
                            padding: 0!important;
                            color:$white!important;
                                &.slp-tile{
                                    .card-info .slp-title{
                                        color: $white!important;
                                    }
                                }
                        }  

                        .basline-induction-date{
                            display: flex;
                            gap: 10px;
                            .date{
                                label{
                                    margin: 0;
                                    padding: 0;
                                }
                                svg{
                                    circle{
                                        background-color: #fff;
                                        stroke: transparent;
                                        fill: #fff;
                                    }
                                    path{
                                        fill: #000;
                                    }
                                }
                            }
                        }
                        .accordion-icon{
                            svg{
                               path{
                                   fill: #fff;
                               }
                            }
                        }
                        .card-details{
                            flex-wrap: initial;
                            .card-desc-holder{
                                width: 45%;
                            }
                            .induction-status{
                                display: flex;
                                align-items: center;
                                width: 120px;
                            }
                            .baseline-date-container{
                                display: flex;
                                align-items: center;
                                .basline-induction-date{
                                    display: flex;
                                    color: white;
                                    gap: 0.5em;
                                    margin: 0 15px;
                                    align-items: center;
                                }
                            }
                            .acknowledge-container{
                                display: flex;
                                align-items: center;
                                .baseline-acknowledge{
                                    button{
                                        background-color: $white;
                                        border: 1px solid #666666;
                                        border-radius: 6px;
                                        padding: 4px 10px;
                                        align-self: center;
                                        outline: none;
                                    }
                                }
                                .acknowledged-content{
                                    display: flex;
                                    flex-direction: column;
                                    label{
                                        color: #fff;
                                        font-size: 12px;
                                        font-weight: normal;
                                        padding: 0;
                                        margin: 0;
                                    }
                                    span{
                                        color: #fff;
                                        font-size: 12px;
                                        svg{
                                            height: 12px;
                                            margin-left: -6px;
                                        }
                                    }
                                }
                            }
                            .status-code{
                                background-color: #0c2340;
                                border: 1px solid #fff;
                                border-radius: 25px;
                                padding: 4px 10px;
                                align-self: center;
                                &.added,
                                &.deferred,
                                &.removed{
                                    background-color: #d71543;
                                }
                                label{
                                    color: #fff;
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                            &>.col-auto:not(.slp-tile){
                                &:last-child{
                                    display: none!important;
                                }

                            }
                        }
                    }
                    .search-accordion-item-body{
                        border-style: none!important;
                        border-color: transparent!important;
                        // .search-accordion-item-body-content{
                            .log-page{
                                .induction-status{
                                    display: flex;
                                    align-items: center;
                                    width: 120px;
                                    .status-code{
                                        background-color: #0c2340;
                                        border: 1px solid #fff;
                                        border-radius: 25px;
                                        padding: 4px 10px;
                                        align-self: center;
                                        &.added,
                                        &.deferred,
                                        &.removed{
                                            background-color: #d71543;
                                        }
                                        label{
                                            color: #fff;
                                            margin: 0;
                                            padding: 0;
                                        }
                                    }
                                }
                                .basline-induction-date{
                                    display: flex;
                                    gap: 10px;
                                }
                                .acknowledge-container{
                                    display: flex;
                                    align-items: center;
                                    min-width: 250px;
                                    .baseline-acknowledge{
                                        button{
                                            background-color: $white;
                                            border: 1px solid #666666;
                                            border-radius: 6px;
                                            padding: 4px 10px;
                                            align-self: center;
                                            outline: none;
                                        }
                                    }
                                    .acknowledged-content{
                                        display: flex;
                                        flex-direction: column;
                                        label{
                                            color: #000;
                                            font-size: 12px;
                                            font-weight: normal;
                                            padding: 0;
                                            margin: 0;
                                        }
                                        span{
                                            color: #000;
                                            font-size: 12px;
                                            svg{
                                                height: 12px;
                                                margin-left: -6px;
                                            }
                                        }
                                    }
                                }
                            }
                        // }
                    }
                }
            }
        }
    }
}

.report-filter{
    // margin-top: 35px; 
    display: flex;
    width: 100%;
    .filters-container{
        margin-top: 50px;
    }
    .tabs{
        width: 95%;
    }
}


.acknowledge-notes-modal, .advance-search-modal {
    width: 50%;
    padding: 0;
    border: none;
    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: $theme-color;
        align-items: center;
        padding: 25px 20px;
        height: 60px;
        h2 {
            color: $white;
            padding: 0;
            font-weight: 400;
            margin-left: 1rem;
        }  
        .closeicon {
            //color: $white;
            filter: brightness(0) invert(1);
        }      
    }
    .modal-desc{
        margin-top:15px;
        margin-left:15px;
    }
    .notes{
        .notes-input-container{
            width: 100%;
            padding: 15px;
            .textarea-holder{
                display: flex;
                flex-direction: column;
                textarea{
                    max-width: 100%;
                }
            }
        }
        .count{
            text-align: right;
            position: absolute;
            right: 35px;
            bottom: 15px;
        }
    }
    .modal-content-footer{
        box-shadow: 0px -3px 14px -6px rgba(0,0,0,.20);
        padding: 15px;
        gap: 1em;
        justify-content: flex-end;
    }
}

.advance-search-modal {
    width: 676px;
  }

// Skeleton
.baseline-skeleton{
    .search-accordion{
        .search-accordion-item{
            .search-accordion-item-header{
                padding: 25px;
                height: 65px;
                .routine-card{
                    .row{
                        &.no-gutters{
                            height: 65px;
                        overflow: hidden;                        }
                    }
                }
            }
        }
    }
}