.subheader-title {
    font-size: 28px;
    font-weight: $font-weight-bold;
    color: #000000;
}

.page-sub-title {
    font-size: 28px;
    font-weight: $font-weight-bold;
}

// .btn {
//     font-size:14px;
// 	font-weight: $font-weight-bold;
// 	text-align:center;
// }
// .btn-primary {
//     background-color: #6244BB;
//     color: #FFFFFF;
// }
// .btn-secondary {
// 	background-color: #FFFFFF;
// 	color: $united-purple;
// 	border:1px solid $united-purple;
// }
.section-header {
    font-size: 28px;
    font-weight: $font-weight-semi-bold;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px;
    line-height: 40px;
}

.section-header::after {
    content: '';
    border-top: 2px solid;
    margin: 15px 0 15px 0;
    flex: 1 0 41px;
    top: 2px;
    color: #D8D8D8;
    position: relative;
}

.global-input {
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 6px;
    height: 45px;
    font-size: 14px;
}

.link-button {
    font-size: 16px;
    color: $united-purple;
    text-decoration: underline;
}

.position-relative {
    position: relative;

    .closeIcon {
        height: 12px;
        width: 12px;
        position: absolute;
        right: 15px;
        bottom: 15px;
        cursor: pointer;
    }
}

.position-absolute {
    position: absolute;
}

.padding-none {
    padding: 0px;
}

.display-flex {
    display: flex;
}

.flex-d-column {
    flex-direction: column;
}
.margin-left-auto{
    margin-left: auto;
}

.flex-end{
    justify-content: flex-end;
}

.align-center{
    align-items: center;
}

.align-self-center {
    align-self: center;
}

.display-none {
    display: none !important;
}

.display-inline {
    display: inline;
}

.text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.width-auto {
    width: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.tabs-extension-container {
    position: relative;

    .tabs-extension {
        display: flex;
        position: absolute;
        //width: 100%;
        justify-content: flex-end;
        align-self: center;
        height: 65px;
        align-items: center;
        z-index: 1;
        right: 0;

        .btn-subtask {
            width: auto;
            margin-left: 20px;
            z-index: 2;

            img {
                padding-right: 1rem;
                height: 16px;
                width: 30px;
            }
        }
    }
}

.action-btn-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.base-btn,
.base-btn:hover {
    width: 130px;
    height: 40px;
}

.font-bold {
    font-weight: 800;
}

.text-align-center {
    text-align: center;
}

.display-on-top {
    z-index: 10007 !important;
}

// Link with no style
.link-no-style {
    color: inherit;
}