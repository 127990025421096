
.partsandtools-card {
    display:flex;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    font-size: $font-size-large;
    padding:10px 30px 10px 20px;
    margin: 10px auto;
    position: relative;
    &-desc{
        min-width: 40%;
        padding-top: 2rem;
    }
    &-me{
        min-width:20%        
    }
    &-mfg{
        min-width: 20%;
    }
    &-quantity {
        min-width: 10%;      
    }
    &-unit {
        min-width: 10%;       
    }
    .desc {
        color: #333333;
        font-size: 1.4rem;
        letter-spacing: 0;
        line-height: 1.9rem;
        font-weight: $font-weight-bold;
    }
    .label{
        padding-left: 0rem;
        color: #333333;
        font-size: 1.2rem;
        letter-spacing: 0;
        line-height: 1.7rem;
    }
    .value{
        color: #0C2340;
        font-size: 1.8rem;
        letter-spacing: 0;
        line-height: 2.4rem;
    }
}