.Tooltip-wrapper{
    display: inline-block;
    position: relative;
}

.Tooltip-tip{
    position: absolute;
    border-radius: 4px;
    transform: translate(-50%);
    padding: 6px;
    color: #000;
    background-color: #fff;
    font-size: 14px;
    line-height: 1;
    z-index: 100;
    min-width: 120px;
    max-width: 300px;
    border: 0.2px solid lightgrey;
    height: auto;
    // white-space: nowrap;
    cursor: pointer;
    &.top{
        bottom:30px;
        left: 40px;
        &:before{
            content: "";
            left: 14px;
            position: absolute;
            bottom: -10px;
            width: 0;
            transform: rotate(0deg);
            border-top: 10px solid white;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }
    }
}


