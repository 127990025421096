.grouping-agent {
  padding-left: 10px;
  justify-content: space-between;
  width: 90%;
  .content-holder {
    color: #333333;
    align-self: center;
    .content-label {
      //font-size: $font-size-fs16;
    }
    .content-value {
      font-size: $font-size-fs16;
      font-weight: $font-weight-semi-bold;
    }
  }
  .btn-edit {
    color: $united-purple;
    font-size: $font-size-fs16;
    width: auto;
    &:focus {
      box-shadow: none;
    }
  }
  .btn-apply,
  .btn-cancel {
    width: 150px;
    height: 45px;
    align-self: center;
  }
  .btn-cancel {
    margin-left: 20px;
  }
  .group-details {
    width: 900px;
    display: flex;
    //justify-content: space-between;
    height: 75px;
    > div {
      margin-right: 50px;
    }
    .ddl-group {
      width: 275px;
    }
    .ddl-disabled {
      color: #999999;
      pointer-events: none;
      .select-box--container {
        border-color: #999999;
        cursor: not-allowed;
      }
    }
    .clock-icon {
      width: 20px;
      height: 20px;
    }
    .update-link {
      svg,
      label {
        cursor: pointer;
      }
      align-items: center;
      color: $united-purple;
      align-self: center;
      margin-top: 10px;
    }
    .group-schedule {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 50px;
      .shift-details {
        margin-right: 80px;
      }
      .button-container {
        display: flex;
        width: 350px;
      }
    }
  }
  .tabs {
    width: 100%;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1800px) {
  .grouping-agent {
    width: 95%;
  }
}
.grouping-workload {
  height: calc(100vh - 400px);
  width: 50%;
  border: 2px solid $cool-gray;
  padding: 15px;
  .subheader-search-container {
    margin-top: 0px;
  }
  .accordion-header {
    min-height: 6rem;
    display: flex;
    align-items: center;
    .title {
      font-size: $font-size-normal;
      font-weight: $font-weight-semi-bold;
      width: 100%;
      div {
        padding-right: 2rem;
        align-items: center;
      }
    }
  }
  .accordion-item-content {
    display: block;
    padding: 1rem;
    position: static;
  }
  .accordion-item--opened {
    background: #f6f7f8;
  }
  h3 {
    color: #0c2340;
    font-size: 2rem;
    padding: 0rem 0 2rem 0;
  }
  .group-title-color {
    color: #ffffff;
  }
  .grouping-workload-content {
    overflow-y: auto;
    height: calc(100% - 40px);
    .wrkload-group-card-ddl {
      line-height: normal;
    }
    .workload-description {
      line-height: normal;
    }
    .dash8-number {
      min-width: 155px;
      line-height: 20px;
    }
    .man-hours {
      min-width: 100px;
      width: 100px;
    }
  }
  .wrkload-group-nr {
    width: 10.5rem;
    min-width: 10.5rem;
    .wkload-dash-number-label {
      font-size: 1.4rem;
      color: #333333;
    }
    .wkload-dash-number-value {
      font-size: 1.4rem;
      color: #333333;
      font-weight: bold;
      a {
        color: $united-purple;
      }
    }
  }
  .wrkload-group-details-container-nr {
    border: 1px solid #949494;
    border-radius: 6px;
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    .workload-group-nr-desc {
      //max-width: 23rem;
      min-width: 100px;
      font-weight: bold;
    }
    & > div {
      padding: 0 1.8rem 0 0;
      &:last-child {
        padding-right: 0;
      }
    }
    .wrkload-group-icon-holder {
      display: flex;
      align-items: center;
      width: 90px;
      min-width: 90px;
    }
    .stop-container {
      margin-left:16px;
    }
  }
  .nr-card-status {
    margin-right: 0;
    margin-bottom: 0;
  }
  .paper-icon {
    height: 16px;
    width: 16px;
    align-self: center;
  }  
  .task-card-section {
    width: 175px;
    min-width: 175px;
    & > span {
      a:first-child {
        //margin-right:8px;
      }
    }
  }
}
.grouped-bg-color {
  background-color: rgba(12, 35, 64, 0.71);
}
.grouping-contact-icon {
  height: 18px;
}
.workload-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  width: 100%;
  .btn-link {
    margin: 0 2rem;
    width: 4.5rem;
  }
}
.grouping-left-arrow {
  width: 18px;
  height: 24px;
  cursor: pointer;
  transform: rotate(90deg);
  //transform: scaleX(1.3);
}
.grouping-right-arrow {
  width: 18px;
  height: 24px;
  cursor: pointer;
  transform: rotate(-90deg);
  //transform: scaleX(1.3);
}
.shift-selector-ddl {
  .select-box--container {
    min-width: 200px;
    width: 200px;
  }
}
.shift-selector-container {
  position: absolute;
  top: 20px;
  z-index: 1;
  padding: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  background-color: #ffffff;
  width: 667px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: default;
  &-title {
    font-size: $font-size-fs18;
    font-weight: $font-weight-semi-bold;
    color: $night-rider-gray;
    padding-bottom: 20px;
  }
  &-content {
    width: 100%;
    overflow-x: auto;
    display: flex;
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    font-size: $font-size-normal;
    font-weight: $font-weight-semi-bold;
    color: $united-purple;
    padding-top: 20px;
    span {
      cursor: pointer;
    }
  }
  &::after {
    content: "";
    background-color: white;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 30px;
    top: -10px;
    transform: rotate(45deg);
    border-top: 2px solid #e6e6e6;
    border-left: 2px solid #e6e6e6;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.grouped-wrkload-msg,
.ungrouped-wrkload-msg {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}
.grouped-wrkload-msg {
  color: #ffffff;
}
.group-agent-d1-completed {
  span {
    background-color: #208500;
    color: #ffffff;
    padding: 0px 4px;
  }
}
.group-agent-override {
  span {
    background-color: #9f7d23;
    color: #ffffff;
    padding: 0px 4px;
  }
}
.groups-summary-header-container {
  // margin: 0 -15px;
  border-bottom: 2px solid #ccc;

  .override-history-link {
    position: absolute;
    top: 13.5rem;
    right: 2rem;
  }

  .chevron-right-icon {
    margin-left: 1rem;
    height: auto;
    width: 1rem !important;
  }
}
.groups-summary-header {
  padding: 2rem;
  max-width: 1700px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;

  > div:not(:last-child) {
    border-right: 1px solid #cccccc;
    width: 24%;
    padding: 0 2rem 0 0;
  }
  > div:first-child {
    width: 8%;
  }
  > div:last-child {
    width: 39%;
    justify-content: flex-end;
  }
  .total {
    color: #333333;
    text-align: center;
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    label {
      font-size: 14px;
    }
    span {
      font-size: 60px;
      font-weight: 600;
      margin-top: 0.5rem;
    }
  }
  .content-holder {
    color: #000000;
    font-size: 1.6rem;
    display: flex;
    margin-right: 2rem;
    justify-content: space-between;
    .content-label {
      padding-right: 1rem;
    }
    .content-value {
      font-weight: bold;
    }
  }
  .search-add {
    display: flex;
    align-items: center;
  }
  .add-group {
    display: flex;
    align-items: center;
    border: 2px solid $united-purple;
    padding: 0 15px;
    color: $united-purple;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    .icon {
      font-size: 6rem;
      line-height: 0.8;
      font-weight: 200;
    }
  }
  .searchContainer {
    position: initial;
    width: 50%;
    margin-right: 2rem;
    color: #666666;
    border-bottom: 2px solid #666666;
  }
}
.milestones-container {
  margin: 0px -15px;
}
.groups-summary {
  background-color: #f7f7f7;
  // margin: 0 -15px;
  padding: 0px 50px;
  max-height: calc(100vh - 330px);
  height: calc(100vh - 330px);
  overflow: auto;
}
.skeleton {
  .add-group {
    color: transparent;
    background: linear-gradient(325deg, #e6e6e6, #d3d3d3);
    border: none;
  }
  .searchContainer {
    border: none;
    input::placeholder {
      color: transparent;
    }
  }
}
.add-update-group-modal {
  min-height: 35rem;
  max-width: 77rem;
  margin: 4rem;
  padding: 0;
  .modal-content-header {
    border-radius: 6px 6px 0 0;
    background-color: #0c2340;
    align-items: center;
    min-height: 5rem;
    padding: 1rem;
    h3 {
      color: #fff;
      word-break: break-all;
      white-space: normal;
      overflow-wrap: break-word;
      padding-right: 2rem;
    }
  }
  .modal-content-body {
    padding: 2rem;
  }
  .btn-container {
    margin-top: 0;
    padding-top: 1.5rem;
    justify-content: flex-end;
    border: 1px solid #ffffff;
    border-radius: 0 0 6px 6px;
    background-color: #ffffff;
    box-shadow: 0 -3px 4px 0 rgba(230, 230, 230, 0.5);
    .btn-cancel {
      width: auto;
      color: $united-purple;
    }
  }
  .text-overflow {
    text-overflow: ellipsiss;
  }
}
