.calendar {
    display: block;
    background:#FFFFFF;
    width: 390px;
    height: 320px;
    border: solid 1px #CCCCCC;
    margin-top: 2px;
    box-shadow: 0 0 15px 0 #C0C0C0;
    font-size: 1.3rem;
    text-align: center;
    position: relative;
    right: 195px;
    z-index: 99999;
    border-radius: 6px;  
    .header {
      display: flex;
      justify-content:center;
      align-items: center;
      color: #333333;
      cursor: default;
      font-size:1.4rem;
      display: block;
      font-weight: bold;
      user-select: none;
        
        .month-display {
            align-items: center;
            height: 40px;
            margin-top: 20px;
        }
        .month-label { 
           flex: 1;
           font-size: 18px;
           text-align: center;
           font-family: Open Sans;
           font-weight: 600px;
        }    
        .calendar-arrow {
            text-align: center;
            flex-basis: 15%;
            width: 24px;
            height: 24px;
            font-weight: bold;
            cursor: pointer;
            transition: background .2s;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
  
    .footer {
      display: flex;
      justify-content:center;
      align-items: center;
      border-top: 1px solid #949494;
      color: #FFFFFF;
      cursor: default;
      font-size:1.4rem;
      display: block;
      font-weight: bold;
      user-select: none;
        
      .footer-display {
          align-items: center;
          height: 55px;
          background: white;
      }
      .footer-label { 
          flex: 1;
      }
      .column {
        height: 55px;
        float: left;
        width: 50%;
        color: black;
      }
      .column span {
        cursor: pointer;
        font-family: Open Sans;
        font-size: 14px;
        color: #666666;
        padding-left: 5px;
      }      
      .column img {
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
      .highlighted {
        cursor: pointer;
        border-top: 2px solid #6244BB;
      }      
      .highlighted span {
        color: #6244BB;
      }
    }
  
    .timeView {
      height: 250px;
      .timeView-header {
        display: flex;
        justify-content:center;
        align-items: center;
          color: #333333;
          cursor: default;
          font-size:1.4rem;
          display: block;
          font-weight: bold;
          user-select: none;
          
          // .timeView-display {
          //     align-items: center;
          //     height: 40px;
          //     margin-top: 35px;
          // }
          .timeView-label { 
             flex: 1;
             font-size: 16px;
             text-align: center;
             font-family: Open Sans;
             font-weight: 600px;
             color: #6244BB;
             letter-spacing: 0;
             margin-left: 142px;
             cursor: pointer;
             text-decoration: underline;
          }
  
      }
  
      .timeView-calendarname {
        margin-top: 15px;
      }
      
      input, textarea {
          background-color: white;
          box-sizing: border-box;
          margin: 0;
          outline: none;
          padding: 10px;
      }
      .timeView-section {
        margin-left: 142px;
        //margin-top: -10px;
      }
      .timeView-input-group {
        display: flex;
        width: 50px;
        margin-top: 35px;
        margin-bottom: 35px;
      }
   
      .separator{
        margin-top: 45px;
        margin-right: 5px;
        margin-left: 5px;
      }
  
      input[type="button"] {
        -webkit-appearance: button;
        cursor: pointer;
      }
  
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
  
      .timeView-input-group input[type='button'] {
        background-color:white;
        min-width: 50px;
        width: 50px;
        transition: all 300ms ease;
      }
  
      .timeView-input-group .button-minus {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        color: #6244BB;
        font-weight: bold;
        height: 40px;
        padding: 0px;
        border: 1px solid #333333;
        width: 50px;
        position: relative;
      }
  
      .timeView-input-group .button-plus {
          
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        color: #6244BB;
        font-weight: bold;
        height: 40px;
        padding: 0px;
        border: 0px;
        border: 1px solid #333333;
        width: 50px;
        position: relative;
      }
  
      .timeView-input-group .quantity-field {
        //position: relative;
        height: 44px;
        //left: -6px;
        text-align: center;
        width: 50px;
        //display: inline-block;
        font-size: 13px;
        padding: 4px 0 0 0;
        border-top: none;
        border-bottom: none;
        border-right: 1px solid #333333;
        border-left: 1px solid #333333;
        //margin: 0 0 5px;
        resize: vertical;
      }
  
      input[type="number"] {
        -moz-appearance: textfield !important;
        -webkit-appearance: none;
      }
  
    }
    .timeView-hoursdiv, .timeView-minutesdiv {
      display: grid;
    }
  
    .show{
      display: block;
    }
    .hide{
      display: none;
    }
  
    .week {
      width: 350px;
      padding-left: 40px;
        &:first-child {
            border-top: none;
        }
    }
  
    .day-names {
        color: #333333;
        font-family: Open Sans;
        font-weight: bold;
        cursor: default;
        font-size: 12px;
        width: 350px;
        padding-left: 40px;
        .day {
            cursor: default;
            
            &:hover {
                background: inherit;
  
            }
        }
    }
  
    .day {
      display: flex;
      font-family: Open Sans;
      justify-content:center;
      align-items: center;
      flex: 1;
      height: 35px;
      font-size: 14px;
      cursor: pointer;
      transition: all .2s;
        &:hover {
            background: #EFEFEF;
        }
  
        &:first-child {
            border-left:none;
        }
  
        &.today {
          font-weight: bold;
            //background: lighten(#2875C7,45%);
        }
  
        &.different-month {
            color: #C0C0C0;
        }
  
        &.selected {
          background: #0C2340;
          border-radius: 4px;
          color: white;
        }
    }
  }