.landing {
  //background: url('url(' + require('../../../../assets/images/logo/Background.jpg') + ')') no-repeat;
  //background: url("https://www.w3schools.com/cssref/mountain.jpg") no-repeat;
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;

  .logo {
    text-align: center;
  }
  .united-logo {
    width: 400px;
    height: 73px;
  }
  .landing-tile {
    width: 46rem;
    height: 400px;
    background-color: #ffffff 80%;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin: 80px auto 0px auto;
    font-size: 18px;
    color: #0c2340;
    a:hover {
      text-decoration: none;
    }
    .welcome-text {
      font-size: 32px;
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: center;
    }
    .landing-page-button {
      cursor: pointer;
      width: 137px;
      height: 137px;
      background: #0c2340;
      border-radius: 118.5px;
      text-align: center;
      display:flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
     // margin: 10px auto 30px auto;
      //padding-top: 28px;
      .button-text {
        font-size: 1.8rem;
        color: #ffffff;
        line-height: 24px;
        padding-top: 0.5rem;
      }     
      svg{
        height:2.4rem;
      }
      &.disabled {
        background: $dove-gray;
        cursor: not-allowed;
      }
    }
  }
  .authentication-div {
    display: flex;
    margin-left: 150px;
    margin-top: 50px;
  }

  .authentication-icon {
    padding-right: 35px;
  }
  .authentication-textdiv {
    display: grid;
  }

  .authentication-text {
    width: 845px;
    font-family: Open Sans;
    font-size: 40px;
    color: #0c2340;
    text-align: left;
    line-height: 56px;
    padding-bottom: 20px;
  }
  .authentication-subtext {
    width: 834px;
    font-family: Open Sans;
    font-size: 24px;
    color: #333333;
    text-align: left;
    line-height: 32px;
    padding-bottom: 20px;
  }
  .authentication-helptext {
    width: 389px;
    font-family: Open Sans;
    font-weight: 900;
    font-size: 16px;
    color: #333333;
    text-align: left;
    line-height: 24px;
  }

  .tiles {
    display: flex;
    justify-content: space-around;
    margin: 2rem 0;
  }
}
