@import '../../../../assets/styles/scss/global/utilities/variables/colorVariables';
.transaction-row {
  border-bottom: 1px solid #ccccc0;
  padding: 1.5rem 0;
  font-size: 1.6rem;
  margin: auto 0;
  svg {
    height: 1.6rem;
  }
  div {
    //flex-grow: 1;
    //padding-right: 1rem;
    width:25%;
  }
  label{
    color: #666666;
  }
  // div:first-child{
  //   flex-grow:2;
  //   max-width:70rem;

  // }
}
.custom-group {
  position: absolute;
  width: 98%;
  .sort {
    position: absolute;
    display: flex;
    top: 8px;
    right: 0px;
    justify-content: flex-end;
    color: $united-purple;
    font-weight: bold;
    cursor: pointer;
    svg {
      height: 23px;
    }
  }
}
