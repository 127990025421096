.select-box--box {
    position: absolute;
    left: 0;
}
.mini-label {
    font-size: 1.4rem;
    color: #333333;
}
.select-box--container {
    height: 45px;
    border: 1px solid #333333;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 6px;
    position: relative;
    min-width:24rem;
    cursor: pointer;
    .input-field {
        padding:0px;
        input[type=text] {
            border: none !important;
            outline: none !important;
        }
        input[type=text]:focus {
            border:none;
             outline: none;
             //override bootstrap styles
             border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
           }
        }    
}
.select-box--mini-container {
    height: 22px;
    border: 1px solid #333333;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 6px;
    //position: relative;
    min-width:6.5rem;
    cursor: pointer;
}
.title-placeholder {
    color: #666666;
}
.selected {
    background-color: #6244BB;
    color: #FFFFFF;
}

.select-box--selected-item {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 12px 30px 12px 12px;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.select-box--mini-selected-item {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0px 30px 10px 20px;
    vertical-align: top;
    //transform: scaleX(1.5)
}
.select-box--items .list-item {
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 6px;
    padding-left: 20px;
    &:hover, &:focus {
        cursor: pointer;
        font-weight: bold;
        background-color: #6244bb;
        color: #FFFFFF;
    }
    &.level-2{
        padding-left:3.5rem;
    }
    &.level-3{
        padding-left:4.5rem;
    }
}
.select-box--items {
    max-height: 400px;
    overflow-y: auto;
}

.dd-list {
    max-height: 400px;
    overflow-y: auto;
}

.select-box-items--display {
    display: block;
    background:#ffffff;
    position: relative;
    z-index: 2;
    top:1px;
}
.select-box-items--hide {
    display: none;
}
.select-box--arrow {
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
}
.select-box-mini--arrow {
    position: relative;
    top: -22px;
    left: 20px;
}

.select-disabled {
    cursor: not-allowed;
    span{
        border: solid #cccccc;
        border-left: 0;
        border-top: 0;
    }
}
.arrow {
    border: solid #6244bb;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 7px;
    margin-top: 10px;
}
.mini-arrow {
    border: solid #6244bb;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-top: 0px;
    margin-bottom: 4px;
    margin-left: 2px;
}
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.ddlRegular {
    width: 296px;
    padding-right:20px;
}
.ddlSmall {
    width: 191px;
}
.ddlXSmall {
    width: 50px;
}
.ddl-container {
    margin: 1rem 0;
}
