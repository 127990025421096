.block-card {
    background-color: rgba(230, 230, 230, 0.4);
    border-radius: 0.6rem;
    padding: 0 2rem;
    font-size: $font-size-fs16;
    color: $black;
    margin: 2rem 0 0 0;   
    .block-card-header {
        padding: 10px 0;
        color: #000000;
        .block-id{
            font-size: 24px;            
            font-weight: $font-weight-semi-bold;
        }
        .na-text{
            font-size: 24px;
            font-weight: 700;
            float: right;
            margin-right: 10px;
        }
        .effectivity {

        }
    }
    .btb-select-all {
        float: right;
        margin-top: -35px;
    }
    .block-card-content {
        background-color: #ffffff;
        height: auto;
        padding: 20px;
    }
    .block-card-footer {
        // height:120px;
        .signoff-info {
            //min-height: 86px; 
            min-height: 30px;
            height: auto;
            margin-left: -20px;
            margin-right: -20px;
            display: flex;
            justify-content: space-between;
            padding-left: 20px;
            .tech-comments {
                word-break: break-all;
                white-space: pre-line;
            }
        }
        .content-holder {
            padding-top: 10px;
            height: auto;
            display: flex;
            flex-flow: wrap;
        }
        .content-holder label {
            min-width: 98px;
        }
        .content-holder span {
            min-width: 140px;
        }
        .pending {
            padding: 3rem 0;
            font-weight: bold;
            //justify-content: center;
        }
        .signoff-info-mech div,
        .signoff-info-insp div {
            display: flex;
            // flex-flow: wrap;
        }
        .signoff-info-mech div img,
        .signoff-info-insp div img {
            padding-right: 10px;
        }
        .tech-comments,
        .insp-comments {
            padding-left: 25px;
            word-break: break-all;
            white-space: pre-line;
        }
    }
}
.hide-block {
    display: none;
}
.required {
    border-color: #ff0000;
}

.required-radio {
    border: 1px solid #ff0000;
    padding: 5px 0px 5px 10px;
    border-radius: 5px;
}

.signoff-block {
    height: auto;
    // min-height: 12rem;
    padding: 2rem 0;
    .secondary-action-section {
        display: flex;
        flex-direction: column;
        padding-left: 3rem;
    }
    .action-disabled {
        color: #666666;
    }
    .required {
        border-color: #ff0000;
    }
    textarea {
        border: 1px solid $night-rider-gray;
        border-radius: 6px;
        border-radius: 6px;
        height: 15rem;
        width: 100%;
        max-width: 38rem;
        min-width: 12rem;
        &:focus {
            outline: 0;
            border: 2px solid $united-purple;
            border-radius: 6px;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    .signoff-section {
        display: flex;
        align-items: center;
        justify-content: space-between;

        select {
            width: 20rem;
            height: 4.5rem;
            border: 1px solid $night-rider-gray;
            border-radius: 0.6rem;
            cursor: pointer;
            &:disabled {
                background: $mercury-gray;
                cursor: not-allowed;
            }
            &:focus {
                outline: 0;
                border: 2px solid $united-purple;
                border-radius: 6px;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }
        .required {
            border-color: #ff0000;
        }
        .dropdown {
            display: flex;
            flex-direction: column;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            border: 1px solid #666666;
            border-radius: 3.14px;
            margin-left: 0.5rem !important;
            margin-top: 0.25rem !important;
            //top: 8px;
            left: -6px;
            height: 2rem;
            width: 2rem;
            background: $concrete-gray;
            cursor: pointer;
        }
        .cusutomCheckbox {
            font-size: 1.4rem;
            &:hover input ~ .checkmark {
                background-color: $mercury-gray;
                outline: 0;
                border: 2px solid $united-purple;
                border-radius: 6px;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }
        /* When the checkbox is checked, add a purple background */
        .cusutomCheckbox input:checked ~ .checkmark {
            background-color: $united-purple;
            border: 1px solid $white;
        }
        .cusutomCheckbox input:disabled ~ .checkmark {
            background-color: $nobel-gray;
            border: 1px solid $nobel-gray;
            cursor: not-allowed;
            box-shadow: none;
        }
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        /* Show the checkmark when checked */
        .cusutomCheckbox input:checked ~ .checkmark:after {
            display: block;
        }
        /* Style the checkmark/indicator */
        .cusutomCheckbox .checkmark:after {
            left: 30%;
            top: 2%;
            width: 7px;
            height: 13px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(41deg);
            -ms-transform: rotate(41deg);
            transform: rotate(41deg);
        }
    }
}

.block-title {
    font-size: $font-size-fs24;
    color: $black;
    font-weight: $font-weight-semi-bold;
    letter-spacing: 0;
    line-height: 2.8rem;
    padding-top: 3rem;
}
.signoff-modal {
    width: 65rem;
    height: fit-content;
    .status-container {
        max-height: 34rem;
        overflow-y: auto;
    }
    textarea {
        border: 1px solid $night-rider-gray;
        border-radius: 6px;
        border-radius: 6px;
        height: 12rem;
        width: 100%;
        max-width: 42rem;
        min-width: 12rem;
        &:focus {
            outline: 0;
            border: 2px solid $united-purple;
            border-radius: 6px;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }
    .comments-container {
        margin-bottom: 0;
    }
}
.blocktopictext {
    font-weight: $font-weight-bold;
    padding-top: 10px;
    color: #000000;
    white-space: pre-line;
}
.normal {
    // padding-left: 10px;
    color: #000000;
    white-space: pre-line;
}
.numeric {
    color: #000000;
    white-space: pre-line;
}
.feedback {
    //padding-left: 10px;
    height: auto;
    .input-group {
        .required {
            border-color: #ff0000;
        }        
    }
}
.feedback-radiobutton {
    padding-left: 20px;
}
.feedback-checkbox{
    div{
        padding-left: 15px;
        max-width: fit-content;
    }
}
.feedback-question {
    font-size: $font-size-fs16;
}
.note {
    line-break: word-wrap;
    // font-weight: bold;
    padding-left: 10px;
    padding-top: 5px;
    white-space: pre-line;
}
.note label::before {
    content: "NOTE: ";
}
.alpha {
    padding-left: 15px;
    white-space: pre-line;
}
.dash {
    color: #000000;
    padding-left: 20px;
    white-space: pre-line;
}
.caution {
    font-weight: $font-weight-bold;
    color: #ffa500;
    // padding-left:10px;
    padding-top: 5px;
    white-space: pre-line;
}
// .caution::before {
//     content: "CAUTION:  ";
// }
.warning {
    color: #ff0000;
    font-weight: $font-weight-bold;
    // padding-left: 18px;
    padding-top: 5px;
    white-space: pre-line;
}
// .warning::before {
//     content: "WARNING: ";
// }
.blockcontent-note {
    font-weight: bold;
    padding-left: 10px;
}
.blockcontent-pre {
    white-space: pre;
    padding-top: 5px;
    padding-bottom: 5px;
    //Henry's changes for rel-71
	// text-wrap: pretty;
}
.blockcontent-graphic {
    width: 250px;
    padding-left: 15px;
    cursor: pointer;
}
.blockcontent-generaltext {
    padding-top: 5px;
    padding-bottom: 5px;
}
.blockcontent-radiobutton {
    width: 50px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}
.blockcontent-radiobutton > input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
.blockcontent-radiobutton > i {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: 0.2s;
    box-shadow: inset 0 0 0 8px #fff;
    border: 2px solid #666666;
    background: gray;
}
.blockcontent-radiobutton > input:checked + i {
    box-shadow: inset 0 0 0 1px #fff;
    background: #6244bb;
    border: 2px solid #6244bb;
}
.blockcontent-radiobutton > input:disabled + i {
    // border: 1px solid #e6e6e6;
    color: #949494;
    opacity: 0.5;
    // background-color: #ffffff;
    cursor: not-allowed;
}
.signoff-activity-modal {
    width: 100rem;
    height: 62rem;
    .activity-content {
        max-height: 55rem;
        overflow-y: auto;
    }
    .activity-section {
        border-top: 1px solid $dove-gray;
        margin: 2rem 1rem 0;
        padding: 1rem 1rem 0;
        .comments {
            padding-left: 3rem;
            word-break: break-all;
            white-space: pre-line;
        }
    }
    .status-type {
        max-height: 3.2rem;
        display: flex;
        align-items: center;
        font-size: $font-size-fs18;
        font-weight: bold;
        padding: 0.5rem 1.5rem;
    }
    .signoff-details {
        display: flex;
        //justify-content: space-between;
        padding-top: 1rem;
        flex-flow: wrap;
    }
    .border-bottom {
        border-bottom: 1px solid $dove-gray !important;
    }
}

.blocktable-header {
    border: 1px solid #333333;
    width: auto;
    text-align: center;
    font-weight: bold;
    padding: 5px;
}
.blocktable-boldcolumn {
    border: 1px solid #333333;
    width: 150px;
    text-align: center;
    font-weight: bold;
    padding: 5px;
}
.blocktable-normal {
    border: 1px solid #333333;
    width: 150px;
    text-align: center;
    padding: 5px;
}
.blocktable-boldcolumn-GI {
    border: 1px solid #333333;
    text-align: center;
    font-weight: bold;
    padding: 5px;
}
.blocktable-normal-GI {
    border: 1px solid #333333;
    text-align: center;
    padding: 5px;
}
#password-highlighted {
    border: 1px solid #ff0000;
    display: block;
}

.signoff-modal {
}
textarea {
    border: 1px solid $night-rider-gray;
    border-radius: 6px;
    border-radius: 6px;
    height: 12rem;
    width: 100%;
    max-width: 42rem;
    min-width: 12rem;
    &:focus {
        outline: 0;
        border: 2px solid $united-purple;
        border-radius: 6px;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    .comments-container {
        margin-bottom: 0;
    }
}

.open-closed-items{
    div:first-child{
        font-size: 1.4rem;
    }
    div:nth-child(2){
        font-weight: bold;
        text-align: center;
    }
}
.tascard-footer{
    svg{
        height:20px;
        width: 20px;
    }
    border: 1px solid #0C2340;
    border-radius: 6px;
    background-color: rgba(105,179,231,0.2);
    padding: 1rem;
}
.na-block {
        cursor: not-allowed;
        display: flex;
        list-style: none;
        font-size: 1.6rem;
        color: $dusty-gray;
        letter-spacing: 0;
        height: 3.7rem;
        align-items: center;
        padding-left: 3rem !important;
}
.all-na-blocks {
    font-size: 22px;
    font-weight: 700;
    color: #F46335;
    text-align: center;
    margin-top: -20px;
    width: 100%;
    &.line{
        margin-top: 20px !important;
    }
}