.etr-history-container {
    height:500px;
    overflow-y: auto;
}
.etr-block {
    display:flex;    
    .etr-icon {
        min-width: 54px;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background-color: #E6E6E6;
        display: flex;
        margin-right: 20px;
        span {
            margin:auto;
            font-size:20px;
            color: #333333;
            line-height:54px;
        }
    }
    .etr-info {
        font-size: 14px;
        color: #000000;
        margin-bottom:20px;
        .user-name {
            color: #333333;
        }
        .updated-date {
            color: #949494;
            font-size:12px;
        }
        .updated-etr-info {
            font-weight: $font-weight-bold;
            padding-left:10px;
        }
        > div {
            margin-bottom:10px;
        }
    }

}


@media screen and (max-width: 1400px) { 
    .etr-history-container {
        height:400px;
    }
}