.stats-tile {
    border: 1px solid #CCCCCC;
    background-color: #FFFFFF;
    padding: 15px;
    color: #333333;
    font-size: 14px;
    border-radius: 6px;
    &-title{
        font-size: 20px;
        font-weight: $font-weight-semi-bold;
        .stats-baseline-date{
            font-size: 20px !important;
            font-weight: normal;
            float: right;
            .update-baseline-icon{
                width:20px;
            }
        }
    }
   
    &-total {
        font-size: 50px;
        font-weight: $font-weight-bold;
        &-approved{
            float: right;
        }
    }
    &-seperator {
        border: 1px solid #CCCCCC
    }
    &-summary {     
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        // flex-grow: 1;
        // flex-shrink: 1;
        flex-basis: auto;
        width: 150px;
    }
    &-value-pairs {
        width: 75%;
        display: flex;
        flex-direction: column;
        padding:20px;
        border-left: 1px solid #CCCCCC;
        .value-pair {
            width:100%;
            display:flex;
            justify-content: space-between;
            padding-bottom:10px;
            .value {
                font-weight: $font-weight-bold;
                .stats-link {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }
        &-baseline{
            display: flex;
            padding-top: 10px;
            width: 100%;
            flex-direction: column;
            gap: 10px;
            .value-pair{
                display: flex;
                justify-content: space-between;
                .value{
                    font-weight:700;
                }
            }
        }
    }
    &-value-pairs-only {
        border-left: none;
        width: 100%;
        padding-left: 0px;
    }
    .duration{
        display:flex;
        width: 40%;
        justify-content: space-between;
        align-items: center;
        padding-left:20px;
        .duration-stats {
            font-size: 3rem;
            font-weight: $font-weight-bold;
            padding-right:5px;
        }
    }   
    .shifts {
        display:flex;
        width: 60%;
        .shift-details {
            margin-right: 10px;
        }
    }    
    .display-baseline{
        margin-top: 20px;
    }
}
.tile-link, .tile-link:hover {
    font-size:18px;
    color: $united-purple;
    font-weight: $font-weight-semi-bold;
    svg {
        width: 16px;
        height: 16px;
    }
}
