﻿
/*Primary palette*/
$united-blue: #0033A0;
$cool-gray: #D4D4D0;
$platium-gray: #7C848A;
$sky-blue: #62A9E3;
$premier-blue:#002244;
$modern-gold: #EDB72B;
$united-purple: #6244BB;

/*Secondary palette*/
$champagne-brown: #C5AB85;
$sandstone: #796E65;
$carrot-orange: #F16321;
$cardinal-red: #CD202C;
$crimson-red:#D50032;
$kelly-green: #7AC24B;
$success-green : #208500;
$fern-green: #3E7D3B;
$bondi-blue: #0093B2;
$cerulean-blue: #009CDE;
$allports-blue: #006BA6;
$smalt-blue: #527D8F;
$prussian-blue: #003057;
$plum-purple: #572C5F;
$mariner-blue: #2172BA;
$tropical-blue: #D0E5F7;
$congress-blue:#044687;
$water-blue: #CBDEF3;
$alice-blue:#e9f1f8;


/*grayscale*/
$black: #000000;
$black-gray: #4A4A4A;
$night-rider-gray: #333333;
$dove-gray: #666666;
$dark-gray: #767676;
$nobel-gray: #999999;
$pearl-gray:#BFBFBF;
$silver-gray: #CCCCCC;
$mercury-gray: #E6E6E6;
$concrete-gray: #F2F2F2;
$white-smoke:#EBEBEB;
$white-gray: #F7F6F6 ;
$alabaster: #F7F7F7;
$seashell-gray:#F1F0F0;
$white: #FFFFFF;
$dusty-gray:#979797;
$cod-gray:#151515;
$mountain-mist: #B2B0B0;
$nobel-gray:#949494;

/*log book colors*/
$atmosphere-blue: #C5E2FF;
$steel-blue: #647D8F;
$pattens-blue: #D7E7F2;
$hawke-blue: #E5EAF4;
$rhapsody-blue: #0C2340;

/*feedback*/
$fern-green: #3E7D3B;
$white-lilac: #FAFBFD;
$blue-haze: #C4CEDD;

/* other colors*/
$cornflower-blue:#4A90E2;
$san-marino:#4C70B7;
$dixie-orange:#E09215;
$outrageous-orange:#F46335;
$orion-steel-blue:#41748D;

/*body color*/
$content-color: $night-rider-gray !default;
$attributes-secondary-bg: $hawke-blue !default;

/*defined status colors*/
$status-defer: $champagne-brown;
$status-defer-ip: $champagne-brown;
$status-open-o: $steel-blue;
$status-open-ta: $sky-blue;
$status-open-tp: $sky-blue;
$status-open-tr: $sky-blue;
$status-open-d: $sky-blue;
$status-open-dp: $sky-blue;
$status-open-ip: $sky-blue;
$status-carry: $plum-purple;
$status-carry-ip: $plum-purple;
$status-other: $cool-gray;
$status-closed: $cool-gray;
$status-void: $kelly-green;
$status-cleared: $kelly-green;

$activities-header-container:$allports-blue !default;
$activity-description-section:$pattens-blue !default;

/*selector colors*/
$selector-color: $white-smoke;
$selector-active-color: $mariner-blue;
$selector-border-color: $mariner-blue;
$selector-error-border-color: $cardinal-red;

/*panel colors*/
$panel-color-white:$white;
$panel-color-gray: $concrete-gray;
$panel-color-premier-blue: $premier-blue;
$panel-color-tropical-blue: $tropical-blue;

/*table colors*/
$table-header-bg-color:$mercury-gray !default;
$table-header-color:$united-blue !default;
$table-border-color: $mercury-gray !default;
$table-bg-color:$white !default;
$table-content-color:$content-color !default;
$table-selected-color:$cornflower-blue !default;

/*page level message boxes*/
$message-color-information:$mariner-blue !default;
$message-color-warning: $carrot-orange !default;
$message-color-error: $cardinal-red !default;




