.aircraft-status {
    .aircraft-status-text-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        >div:nth-child(2) {
            display: flex;
            align-items: center;

            label {
                margin-right: 5px;
            }
        }

        .aircraft-status-menu{
            display: flex;
            gap: 2rem;
            .hide-widget{
                color: #6244BB;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
                text-align: right;
                cursor: pointer;
                .chevron-right-icon{
                    margin-left: 3px;
                    width: 16px;
                    height: 15px;
                    transform: rotate(90deg);                    
                    &.up{
                        transform: rotate(270deg);
                    }
                }
            }
        }
        .aircraft-status-text {
            font-size: 16px;
            padding: 16px 0 16px 0;

            .aircraft-status-text-color{
                color: #666666;
            }

            .aircraft-status-text-bold {
                font-weight: 700;
                margin: 0 5px 0 5px;
                padding-right: 10px;
                color: #333333;
            }

            .aircraft-status-text-green {
                color: #38911c !important;
                margin: 0 20px 0 0px;
            }
            .aircraft-status-text-red {
                color: #d60536 !important;
                margin: 0 20px 0 0px;
            }
            .toggle-switch {
                width: 42px;
                height: 25.2px;
                .toggle-switch-switch{
                    height: 24.5px;
                    width: 24.5px;
                    border: 1.05px solid #6244BB;
                    top:-3px;
                }
                input[type="checkbox"]{
                    + label{
                        &.toggle-switch-label{
                            .toggle-switch-inner{
                                &:after, &:before{
                                    height: 24.2px;
                                    width: 42px;
                                }
                            }
                            .toggle-switch-switch{
                                right: 13px;
                            }

                        }
                    }
                    &:checked{
                    + label{
                        &.toggle-switch-label{
                            .toggle-switch-switch{
                                right: -4px;
                            }
                        }
                        
                    }
                }}
            }
        }
        .menu-dropdown-list{
         right: 0;
         top:55px
        }
    }

    .aircraft-status-cards-wrapper {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 3rem;
        flex-wrap: wrap;
        .aircraft-status-card:last-child {
            width: 545px;
        }

        .perday-widget { 
            .aircraft-status-card-heading {
                display:flex;
                justify-content: center;
                margin: 12px 20px;
            } 
            .aircraft-status-card-details-col{
                display:flex;
                align-items: center;
                justify-content: center;
                flex: 100% 0 0 !important;
                flex-direction: column;
                .aircraft-status-card-main-stat{
                    display:flex;
                    margin-right: 30px;
                    .arrow-down-icon {
                        top: 2px;
                    }
    
                    .arrow-up-icon {
                        top: -2px;
                    }
                }
            }
            .aircraft-status-card-body{
                .aircraft-status-card-details-row {
                    .aircraft-status-card-cdp-widget {
                        display: flex;
                        gap: 4rem !important;
                        margin-left: 26px;
                    }
               }
            }
           }

        .aircraft-status-card {
            padding: 16px;
            box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
            border-radius: 12px;
            min-height: 160px;
            min-width: 295px;
            .aircraft-status-card-header-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 8px;
            }

            .aircraft-status-card-main-stat {
                font-size: 24px;
                font-weight: bold;
                padding-bottom: 4px;

                .arrow-down-icon {
                    transform: scaleY(1.2);
                    height: 30px;
                    top: 2px;
                    position: relative;
                }

                .arrow-up-icon {
                    transform: rotate(180deg) scaleY(1.2);
                    height: 30px;
                    top: -8px;
                    position: relative;
                }
                .disabled{
                    display: none;
                }

                span:last-child {
                    margin-left: 10px;
                }

                .openNr-link {
                    text-decoration: underline;
                }

            }

            .aircraft-status-card-sub-stats {
                font-size: 16px;
                .text-bold{
                    font-weight: bold;
                }
            }

            .aircraft-status-card-heading {
                font-size: 20px;
                .cdp-title{
                    font-size: 18px;
                }
                .cdp-due{
                    font-size: 13px;
                    position: relative;
                    right: -88px;
                }
            }
            .aircraft-status-card-sub-heading{
                font-size: 12px;
                color:#666666;
            }

            .aircraft-status-card-body {
                display: flex;
                align-items: center;
                padding: 0;

                .aircraft-status-card-details-col {
                    flex: 40% 0 0;
                }

                .aircraft-status-card-chart-col {
                    flex: 60% 0 0;
                    position: relative;
                    .labels{
                        position: absolute;
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        .span{
                            display: block;
                            font-size: 12px;
                            &:first-child{
                                position: absolute;
                                left: -15px;
                            }
                            margin-left: auto;
                        }
                    }
                    .chart-large{
                        height: 71px;
                        width: 146px!important;
                        // width: 15vw!important;
                    }
                }

                .aircraft-status-card-chart-cdp {
                    flex: 30% 0 0;
                    .chart-large{
                        height: 71px;
                        width: 146px!important;
                        // width: 15vw!important;
                    }
                }

                .aircraft-status-card-details-row {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    flex: 60% 0 0;

                    .aircraft-status-card-sub-stat-count {
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 32px;
                    }

                    .aircraft-status-card-sub-stat-label {
                        font-size: 16px;
                    }
                    .aircraft-status-card-cdp-widget{
                        display: flex;
                        gap: 1rem;
                        .bold-data{
                            font-size: 18px;
                            font-weight: bolder;
                            .btn-link.stats-link {
                                text-decoration: underline;
                                padding-top: 0rem !important;
                            }
                        }
                        .small-label{
                            font-size: 14px;
                        }
                    }
                }
                .cdp {
                    flex: 70% 0 0;
                }
            }
        }

        .aircraft-detail-view-card{
            width: 296px;
            height: 128.64px;
            margin: 20px;
        }
    }
    .toggle-chart{
        margin: 10px;
        font-size: 16px;
        color:#666666;
        span{
            margin-left: 6px;
            text-decoration: underline;
            cursor: pointer;
        }

        svg {
            width: 16px;
            height: 1.2rem;
        }

    }
    .aircraft-status-tabs-wrap {
        padding: 16px;
        margin-top: 16px;
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
        border-radius: 4px;

        &.detailed-view-label{
            height: 8px;
            //width: 1888px;
            border-radius: 4px 4px 0 0;
            background-color: #0C2340;
            padding: 0 !important;
            box-shadow: 0 0 0 0 !important;
            // position relative is defined to position filter dropdown 
            position:relative
        } 
        .no-card-selected{
            font-size: 24px;
            color:#666666;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .details-tab{
            .body-wrapper {
                height: calc(100vh - 200px)
            }
            .details-tab-workload-section{
                padding-top: 30px;
            }
            .assign-crew-workload-header{
                svg{
                    g{
                        stroke: #fff;
                    }
                }
            }
            .assign-crew-workload-body {
                .assign-crew-workload{
                   div.ac-icons{
                    &:last-child{
                        margin-left: auto!important;
                        margin-right: 2%!important;
                    }
                }
                }
                .more-details-container{
                    .note-detail{
                        overflow-wrap: break-word;
                        width: 60%;
                    }
                }
            }
            
            &.status-tab{
                display: none; 
                border-top: 2px solid #979797; 
                &.active{
                    display: block;
                    .tabs{
                        .tab-list{
                            margin-bottom: 8.52px;
                            &-item{
                                font-size: 16px;
                                height: 42.4px;
                            }
                        }
                    }
                    .body-wrapper{
                        height: calc(100vh - 200px);
                        .assign-crew-workload-body {
                            //overflow: visible;
                            // + .scroll-indicator{
                            //     display: none!important;
                            // }
                        }
                    }
                    .assign-crew-workload-header{
                        height: 40px;
                        background-color: #0C2340;
                        color: #FFFFFF;
                    }
                }
            }
            .card-title-style{
                display: block;
                color: #25282A;
                font-size: 22px;
                line-height: 24px;
                margin:25px 0 24px 14px;
            }
            .kpi-gantt-view {
                margin: 10px;
                font-size: 16px;
                color: #666666;
                gap: 10px;
                .toggle-btn{
                    margin-left: auto;
                    width: 90%;
                    justify-content: space-between;
                }
                .link{
                    margin-left: 6px;
                    cursor: pointer;
                    svg{
                        width: 16px;
                        height: 1.2rem;
                    }
                }
            }
        }

        .head-title {
            font-size: 28.21px;
            &.overview{
                padding-bottom: 20px;
            }
            &.detailed-view{
                display: flex;
                justify-content: space-between;
                padding: 25px 20px 0px 20px;
                
                .title{
                    display: flex;
                    align-items: center;
                    padding-bottom: 0px;
                }
                // .select-filter{
                //     .filter-icon-container{
                //         display: flex;
                //         align-items: center;
                //         gap: 2rem;
                //         .filter-icon{
                //             font-size: 16px;
                //             cursor: pointer;
                //             svg{
                //                 height: 18px;
                //                 width: 18px;
                //             }
                //         }
                //         .dd-wrapper{
                //             min-width: 200px;
                //             > label{
                //                 display: none;
                //             }
                //         }
                //     }
                // }
                .clock-circle-wrapper{
                    margin-right: auto;
                    padding: 10px 30px 10px 30px!important;
                    font-size: 18px !important;
                    color: grey;
                    align-items: center;
                }
                .dot-circle-wrapper{
                    display: inline-flex;
                    padding: 10px 0;
                    justify-content: end;
                        .select-filter{
                            ~ div{
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                                margin-top: 5px;
                            }
                        }
                    // }
                  }
            }
        }

        .title {
            font-size: 28.21px;
            font-weight: 500;
            padding-bottom: 12px;
        }

        .tab-list {
            margin-bottom: 0;
            .tab-list-item {
                font-size: 22px;
            }
        }
       .spinner-margin{
        .text-align-center{
            margin-top:40px;
        }
       }
        .overview-card {
            display: flex;
            //align-items: center; 
            justify-content: space-between;
            min-width: 175px;
            min-height: 616px;
            .overview-card-chart {
                flex: 80% 0 0;
                .clock-circle-wrapper{
                    margin-right: auto;
                    padding: 10px;
                    font-size: 18px;
                    color: grey;
                    align-items: center;
                }
                .dot-circle-wrapper{
                    display: flex;
                    gap: 1rem;
                    // align-items: center;
                    padding: 10px 0;
                    justify-content: end;
                    margin-right: 25px;
                    .right-content{
                        .select-filter{
                            ~ div{
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                                margin-top: 5px;
                            }
                        }
                    }
                    .dot-circle{
                      border-radius: 50%;
                      width: 15px;
                      height: 15px;
                      display: block;
                    }
                    p{
                        margin: 0;
                        padding: 0;
                    }
                  }
                &.zones-overview{ //full width view
                    flex:95% 0!important;
                    .chartAreaWrapper2.horizontal-scroll{
                        max-width: 70vw!important;
                        width: 100%!important;
                        .chart-large{
                            max-width: 100vw!important;
                            width: 85vw!important;
                        }
                    }
                }
            }

            .overview-card-detail {
                flex: 20% 0 0;
                background-color: #f7f7f7;
                padding: 16px 24px;
                display: flex;
                flex-direction: column;
                gap: 3rem;

                .overview-label {
                    font-size: 20px;
                }

                .overview-count {
                    font-size: 32px;
                    font-weight: bold;
                }
            }

            .overview-label-check {
                font-size: 16px;
            }

            

            .skills-chart, .zones-chart{
                //flex: 65% 0;  
                width: calc(100% - 532px);              
                .chartAreaWrapper2 {
                    flex: 100% 0;
                    display:block;
                    .chart-large {
                        width: auto;
                    }
                }                
            }
            .zones-chart {
                flex: 100% 0;
            }

            .skills-overview-info {
                //flex: 35% 0;
                min-width: 532px;
                border-left: 1px solid #979797;
                .overview-header-label {
                    font-size:28px;
                    font-weight: 600;
                    padding: 20px 0 0 15px;
                    color: #333333;
                }
                .skills-list, .skills-list-header {
                    color: rgba(51,51,51,0.8);
                    .skill-card{
                        display: flex;
                        font-size: 20px;
                        padding: 12px 15px;
                        border-bottom: 1px solid #979797;
                        .skill-name{
                            font-weight: bold;                            
                            flex: 40% 0;
                            padding-right: 15px;
                            display:flex;
                            .skill-desc {
                                overflow: hidden;
                                padding-right: 15px;
                                width: 125px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                // display: -webkit-box;
                                // -webkit-line-clamp: 1;
                                // -webkit-box-orient: vertical;
                                // overflow: hidden;
                                // white-space: break-spaces;
                                // padding-right: 15px;
                            }
                        }
                        .skill-progress{
                            flex: 27% 0;
                            padding-right: 15px;
                            .outer-shell[data-title]:hover::after {
                                content: attr(data-title);
                                position: absolute;
                                top: -100%;
                                left: 50px;
                                border: 1px solid #e3e3e3;
                                white-space: nowrap;
                                width: auto;
                                padding: 0 10px;
                                color: #333333;
                                background-color: #FFFFFF;
                                z-index:100;
                                box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
                                border-radius:5px;
                                font-size:16px;
                            }
                        }                        
                        .man-hours{
                            font-weight:600;
                            flex: 33% 0;
                            text-align: center;                            
                            .kpi-status {
                                border: 1px solid black;
                                border-radius: 45px;
                                padding: 2px 10px;
                                font-size: 15px;
                            }
                        }
                    }                    
                }
                .skills-list {
                    height:550px;
                    overflow-y: auto;
                    .skill-card:first-child {
                        .skill-progress {
                            .outer-shell[data-title]:hover::after {
                                content: attr(data-title);
                                position: absolute;
                                top: 100%;
                                left: 50px;
                                border: 1px solid #e3e3e3;
                                white-space: nowrap;
                                width: auto;
                                padding: 0 10px;
                                color: #333333;
                                background-color: #FFFFFF;
                                z-index:100;
                                box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
                                border-radius:5px;
                                font-size:16px;
                            }
                        }
                    }
                }
                .skills-list-header {
                    .skill-card:first-child{
                        font-size: 14px;
                        color: rgb(102, 102, 102);
                        font-weight: 400 !important;
                        align-items: center;
                        padding-top: 0px;
                        div {
                            padding:0px;
                        }
                    }
                }
            }

            
        }
    }

}

.menu-button{
    &.disabled{
        border: 1px solid #c1c1c1;
        color: #c1c1c1;
        .menu-dropdown-icon{
            background: #c1c1c1!important;
        }
    }
}


// 12 hr widget
.tw-hr-widget-wrapper{
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    .tw-hr-widget{
        box-sizing: border-box;
        border: 1px solid rgba(204,204,204,0.75);
        box-shadow: 4px 4px 8px 2px rgba(0,0,0,0.08);
        border-radius: 8px;
        background-color: #FFFFFF;
        min-width: 260px;
        min-height: 131px;
        &.burnrate{
            max-width: 183px;
            min-width: 183px;
        }
        &-heading{
            padding:16px 15px 8px 16px;
            font-size: 22px;
            line-height: 20px;
            color:#333333;
            display: flex;
            align-items: center;
            .tw-hr-widget-label{
                cursor: default;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width:200px;
            }
        }    
        &-sub-heading{
            font-size: 12px;
            padding-left: 16px;
            margin-top: 1px;
            color:#666666;
            line-height: 13px;
        }
        &-body{
            padding:12px 16px;
            .tw-hr-widget-main-stat{
                display: flex;
                gap: 1rem;
                font-size: 24px;
                line-height: 33px;
                color: #333333;
                font-weight: bold;
                .stats-link{
                    font-weight: bold;
                    text-decoration: underline;
                }
                .arrow-down-icon {
                    transform: scaleY(1.2);
                    height: 30px;
                    top: 2px;
                    position: relative;
                    top: 2px;
                }
                .arrow-up-icon {
                    transform: rotate(180deg) scaleY(1.2);
                    height: 30px;
                    top: -8px;
                    position: relative;
                    top: -6px;
                }
                .disabled{
                    display: none;
                }
            }
            .burnrate-status-count{
                display: flex;
                gap: 2rem;
                .burn-count{
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    .burnrate-label{
                        font-size: 12px;
                        line-height: 13px;
                        font-weight: 400;
                        color:#666666;
                    }
                }
            }
        }
    }
}

// details view
.details-view-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 9px 16px;
    margin-bottom: 16.2px;
    margin-top: 19px;
    .select-filter{
        position:absolute;
        right:25px;
        top:25px;
        .filter-icon-container{
            display: flex;
            align-items: center;
            gap: 2rem;
            .filter-icon{
                font-size: 16px;
                cursor: pointer;
                svg{
                    height: 18px;
                    width: 18px;
                }
            }
            .dd-wrapper{
                min-width: 200px;
                > label{
                    display: none;
                }
            }
        }
    }
    .no-cards-display {
        font-size: 2.4rem;
        font-weight: bold;
        margin-left: 33px;
    }
    .details-view-card{
        min-height: 110px;
        height:auto;
        width: 296px;
        border: 1px solid rgba(204,204,204,0.75);
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.2);
        cursor: pointer;
        &.active{
            border: 2px solid #6244bb;
        }
        .details-view{
            width:100%;
            &-heading {
                display: flex;
                gap: 11px;
                padding: 11px 11.2px;
                border-bottom: 1px solid #6666;
                .details-view-label{
                    color: #333333;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22px;
                    max-width: 290px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
            &-body{
                padding: 11px 11.2px;
                .details-view-details-col{
                    letter-spacing: 0;
                    .details-view-main-stat{
                        .counts{
                            display: flex;
                            .count-grp{
                                .shift-description{
                                    padding-bottom: 10px;
                                    .target {
                                        color: #666666;
                                        font-size: 12px;
                                        line-height: 13px;
                                        margin-right: 8px;
                                    }
                                    .schedule-info{
                                        color: #333333;
                                        font-size: 12px;
                                        font-weight: 600;
                                        line-height: 13px;
                                    }
                                    svg{
                                        height: 17px;
                                    }
                                }
                                .nr-counts{
                                    display: flex;
                                    gap: 24px;
                                    .count-container{
                                        .title{
                                            color: #666666;
                                            font-size: 12px;
                                            line-height: 13px;
                                            padding-bottom: 5px;
                                        }
                                        .count{
                                            color: #333333;
                                            font-family: "Open Sans";
                                            font-size: 14px;
                                            font-weight: 600;
                                            line-height: 16px;
                                        }
                                    }
                                }
                            }
                            .progress{
                                width: 80px;
                                height: 42.54px;
                                background-color: #fff;
                                margin-left: auto;
                                .progress-value{
                                    // color: #333333;
                                    // font-size: 14px;
                                    // font-weight: 600;
                                    // line-height: 16px;
                                    // text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Chart content
.gantt-chart-wrapper{
    max-height: 55vh;
    /* border: 1px solid red; */
    overflow: hidden;
    
    .toggle-status{
        margin-left: auto;
        display: block;
        color: #6244BB;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        text-align: right;
        cursor: pointer;
        svg{
            margin-left: 5px;
            width: 16px;
            height: 15px;
            transform: rotate(0deg);
            &.right{
                transform: rotate(180deg);
            }
        }
    }
    
    .status-container{
        padding: 0;
        margin: 0;
        &.col-md-0{
            display: none;
        }
        .kpi-container{
            height: 52vh;
            overflow: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar{
                width: 8px;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 4px rgba(0,0,0, 0.3);
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #6244BB;
                border-radius: 5px;
                // outline: 1px solid grey;
            }

            .skills-overview-info {
                //flex: 35% 0;
                min-width: 532px;
                border-left: 1px solid #979797;
                .overview-header-label {
                    font-size:28px;
                    font-weight: 600;
                    padding: 20px 0 0 15px;
                    color: #333333;
                }
                .skills-list, .skills-list-header {
                    color: rgba(51,51,51,0.8);
                    .skill-card{
                        display: flex;
                        font-size: 20px;
                        padding: 12px 15px;
                        border-bottom: 1px solid #979797;
                        .skill-name{
                            font-weight: bold;                            
                            flex: 40% 0;
                            padding-right: 15px;
                            display:flex;
                            .skill-desc {
                                overflow: hidden;
                                padding-right: 15px;
                                width: 125px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                // display: -webkit-box;
                                // -webkit-line-clamp: 1;
                                // -webkit-box-orient: vertical;
                                // overflow: hidden;
                                // white-space: break-spaces;
                                // padding-right: 15px;
                            }
                        }
                        .skill-progress{
                            flex: 27% 0;
                            padding-right: 15px;
                            .outer-shell[data-title]:hover::after {
                                content: attr(data-title);
                                position: absolute;
                                top: -100%;
                                left: 50px;
                                border: 1px solid #e3e3e3;
                                white-space: nowrap;
                                width: auto;
                                padding: 0 10px;
                                color: #333333;
                                background-color: #FFFFFF;
                                z-index:100;
                                box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
                                border-radius:5px;
                                font-size:16px;
                            }
                        }                        
                        .man-hours{
                            font-weight:600;
                            flex: 33% 0;
                            text-align: center;                            
                            .kpi-status {
                                border: 1px solid black;
                                border-radius: 45px;
                                padding: 2px 10px;
                                font-size: 15px;
                            }
                        }
                    }                    
                }
                .skills-list {
                    // height:550px;
                    // overflow-y: auto;
                    .skill-card:first-child {
                        .skill-progress {
                            .outer-shell[data-title]:hover::after {
                                content: attr(data-title);
                                position: absolute;
                                top: 100%;
                                left: 50px;
                                border: 1px solid #e3e3e3;
                                white-space: nowrap;
                                width: auto;
                                padding: 0 10px;
                                color: #333333;
                                background-color: #FFFFFF;
                                z-index:100;
                                box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
                                border-radius:5px;
                                font-size:16px;
                            }
                        }
                    }
                }
                .skills-list-header {
                    .skill-card:first-child{
                        font-size: 14px;
                        color: rgb(102, 102, 102);
                        font-weight: 400 !important;
                        align-items: center;
                        padding-top: 0px;
                        div {
                            padding:0px;
                        }
                    }
                }
            }
        }
    }
}

.gantt-chart-modal{
    .modal-content-header{   
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        padding: 1rem;
        margin: 1rem;
        h3{
            color: #FFFFFF;
        }
    }
}
.flex{
    display: flex;
}
.gap-2{
    gap: 2rem;
}
.mb-5{
    margin-bottom: 5px;
}

.chart-content{
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    &::-webkit-scrollbar{
        width: 8px;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track{
        box-shadow: inset 0 0 4px rgba(0,0,0, 0.3);
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #6244BB;
        border-radius: 5px;
        // outline: 1px solid grey;
    }
}
.labels-block{
    // position: absolute;
    
    width: 100%;
    overflow: hidden;
    &::-webkit-scrollbar{
        width: 6px;
        height: 10px;
    }
    &::-webkit-scrollbar-track{
        background: transparent;
    }
    &::-webkit-scrollbar-thumb{
        background-color: transparent;
        border-radius: 5px;
    }
}

.gantt-chart-modal{
    width: 85vw;
    .popup-chart-contianer{
        height: 80vh;
        overflow: hidden;
        // overflow-y: scroll;
        .chart-content{
            max-height: 77vh;
        }
    }
}