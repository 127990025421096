.stats-tile {
    border: 1px solid #CCCCCC;
    background-color: #FFFFFF;
    padding: 15px;
    color: #333333;
    font-size: 14px;
    &-title{
        font-size: 20px;
        font-weight: $font-weight-semi-bold;
    }
    &-total {
        font-size: 70px;
        font-weight: $font-weight-bold;
    }
    &-seperator {
        border: 1px solid #CCCCCC
    }
}