@import './GroupsSummary/GroupCard/groupcard';
@import './ShiftSelector/shiftselector';
.visit-info {
    .groups-overview {
        width: 780px;
    }
    .visit-workload-section {
        box-sizing: border-box;
        height: 355px;
        width: 260px;
        border: 1px solid $nobel-gray;
        border-radius: 6px;
        padding: 1.2rem;
        &-header {
            font-weight: bold;
            color: $night-rider-gray;
            font-size: 2rem;
            padding-bottom: 2rem;
        }
        .view-all {
            color: #6244bb;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
  


.visit-scheduling {
    .content-holder{
        color: #333333;
        padding-bottom:18px;
        .content-label{
            font-size: $font-size-normal;
        }
        .content-value {
            font-size: $font-size-large;
            font-weight: $font-weight-semi-bold;
        }
    } 
    .group-category-section  {
        //display:flex;
        width:415px;
        margin-left:110px;
        justify-content: space-between;
        .group-category{
            width: 110px;
            height: 110px;
            border: 1px solid #949494;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        } 
        .schedule-group-btn {
            height:40px;
        }        
    }
    .visit-stats-link {
        color: #6244BB;
        font-size:18px;
        font-weight: $font-weight-semi-bold;
        text-align:left;
        padding-left:20px;
        margin-left: auto;
        svg{height:16px;}                  
        span {
            padding-right:15px;
            cursor: pointer;
        }
        > a{
            color: #6244BB;
            padding-right:10px;
            text-decoration: none;
        }
        .inactive-link {
            cursor:default;
            color: #000000;
        }
    }
    .inducted-icon, .etr-icon {
        height: 40px;
    }
    .wrkload-alert-icon {
        height: 22px;
        span {
            padding-left: 5px;
        }
    }
    .unscheduled-alert {
        width: 16px;
        height: 14px;
        margin-left: 20px;
    }
}

