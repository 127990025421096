.create-nr {
  width: 100%;
  font-size: $font-size-normal;
  color: $night-rider-gray;
  // padding: 0 35rem 0 35rem;
  &-header {
    font-size: 2rem;
    padding-bottom: 1rem;
    font-weight: $font-weight-bold;
  }
  &-sub-header {
    font-weight: $font-weight-semi-bold
  }
  .skills-and-labour-container{
    .pb-4.section{
      padding: 0!important;
    }
  }

  .section {
    display: flex;
    flex-flow: wrap;
    padding: 1.5em;
    .zone-ata-align {
      display: flex;
    }
    .attribute-radio {
      padding-right: 5em;
    }
    .dd-wrapper {
      margin-left: 20px;
      margin-top: -3px;
      .dd-list {
        height: 400px;
        overflow: auto;
      }
    }
  }
  .discrepancy-section {
    justify-content: space-between;
    flex-flow: wrap;
    .radio-spacing{
      padding-right: 14.5rem;
    }
  }
  .add-link {
    .link-container {
      display:flex;
      cursor: pointer;
    }
    .icon {
      font-size: 3rem;
      padding-right:10px;
      font-weight: $font-weight-normal;
    }    
    display:inline-flex;
    cursor: pointer;
    color: $united-purple;
    font-weight: $font-weight-semi-bold;
    align-self:center;
    line-height: 30px;
  }
  .add-link-disable {
    cursor: not-allowed;
    pointer-events: all !important;
    color: #949494;
  }

  .input-field-container {
    padding-right:20px;
  }
  .input-class {
    width: 200px;
    height: 45px;
    border: 1px solid #333333;
    border-radius: 6px;
    font-size: $font-size-normal;
  }
  
  .textarea-holder{
    padding-right: 20px;
    label {
      display:block;
    }     
  }
  .textarea-field{
    width:450px;
    border: 1px solid #333333;
    border-radius: 6px;
  }

  .button-container {
    display:flex;
    justify-content: flex-end;
  }
  .not-valid { border: 1px solid red !important;}
  .rp-disabled{
    border-color: #d3d3d3;
  }
  .nr-parent-summary {
    display:flex;
      .nr-parent-section{
        display:flex;
        flex-direction: column;
        padding-right: 50px;
        .nr-parent-value{      
          font-weight: $font-weight-bold;
          font-size: 1.6rem;    
      }
    }
    .dd-wrapper {
      margin-left: 30px;
      margin-top: -3px;
      .dd-list {
        height: 400px;
        overflow: auto;
      }
    }
  }
}

.card-desc-holdernr {
  &.active{
    .accordion-item.view-nonroutine{
      display: block;
    }
  }
  .accordion-item{
    display: none;
  }
}

.section-content-nr-holder {
  border: 2px solid #e6e6e6;
  border-radius: 6px;
  margin-bottom: 2em;
  &.no-border{
    border: none!important;
  }
  // .mal-padding {
  //   padding-left: 2em;
  // }
  .section{
    .material-content{
      flex-direction: column;
      width: 100%;
    }
    .manage-nr-container{
      .manage-nr-content{
        .sectionOption{
          display: flex;
          gap: 2em;
        }
      }
    }
  }
  .view-non-routine{
    gap: 2 rem;
  }
  .create-nr-header{
    display: flex;
    padding: 0 12px;
    background-color: #0c2340;
    color: white;
    border-radius: 6px 6px 0px 0px;
    width: 100%;
    line-height: 50px;
    .non-routine-chevron{
      margin-left: auto;
      margin-right: 10px;
      svg{
        transform: rotate(-90deg);
        transition-delay: .3s;
        filter: brightness(0) invert(1);
      }
    }
    // Toggle Accordion
    &.active{
      ~ .section, ~ .view-non-routine{
        height: 100%!important;
        padding: 1.5em!important;
        display: flex;
        min-height: 100%;
        &.material-block{
          display: block !important;
        }
      }
      .non-routine-chevron{
        svg{
          transform: rotate(90deg)!important;
          transition-delay: .3s;
          filter: brightness(0) invert(1);
        }
      }
    }
     ~ .section,  ~ .view-non-routine{
      padding: 0!important;
      height: 0!important;
      display: none;
      &.material-block{
        display: none !important;
      }
      .non-routine-chevron{
        svg{
          transform: rotate(0deg)!important;
          transition: .3s;
        }
      }
    }
  }
}

.section-content-nr-holder.disabled, .section-content-nr-holder.disabled-partially {
  .create-nr-header {
    background-color: #d3d3d3;
  }
  input, label {
    pointer-events:none;
    cursor: not-allowed;
    opacity: 0.6;    
  }
  .reopen{
    label {
      opacity: 1;
    }
  }
}
.section-content-nr-holder.disabled-partially.reopen {
  .create-nr-header {
    background-color: #0C2340;
  }
}
.empty-est-hours{
  border: 1px solid red;
}
.skills-labor-title {
  font-size: 20px;
  font-weight: 700;
  //padding-left: 25px;
  margin-bottom: 10px;
}
.validation-modal {
    width: 600px;
    max-height: 500px;
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 30px;
    .button-container {
      justify-content: space-around;
      display: flex;
      padding-top: 30px;
    }
    .validation-message:before{
      content: '-';
      position: relative;
      left:0px;
      padding-right:5px;
    }
}

.authentication-modal {
  width: 450px;
    background-color: white;
    padding: 30px;
    border-radius: 6px;
}

// Assign crew drop down disabled
.assign-crew-disabled ~ div{
  pointer-events: none;
  opacity: .4;
  span.up{
    border-color: grey!important;
  }
}
.assign-crew-disabled, .milestone-disabled{
  opacity: 0.6;
  .select-box--container{
    cursor: not-allowed;
    border-color: #c7c7c7!important;
  }
}
.workbin-disabled{
  .select-container {
    border-color: #c7c7c7!important;
    opacity: 0.6;
    cursor: not-allowed;
    .up{
      border-color: #c7c7c7!important;
    }
  }
}

.zone-disabled {
  opacity: 0.6;
  .select-box--container{
    cursor: not-allowed;
    border-color: #c7c7c7!important;
  }
}