.accordion {
  .title {
    color: $night-rider-gray;
    font-size: $font-size-fs18;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    font-weight: normal;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    //position: relative;
    border-bottom: 1px solid $dove-gray;
    text-align: left;

    &.is-expanded {
      transition: background 0.5s;
    }
  }

  .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s;
    // margin: 0;

    ul {
      margin: 0;
      opacity: 0;
      transition: 0.8s;
    }

    &.is-expanded {
      max-height: 44.5rem;
      border-bottom: 1px solid $dove-gray;
      // height: 44.5rem;
      ul {
        opacity: 1;
      }
    }
  }
  .accordion-item-icon {
    transform: rotate(180deg);
    height: 18px;
    width: 22px;
    cursor: pointer;
  }
  .accordion-item-icon-closed {
    transform: rotate(90deg);
    height: 18px;
    width: 22px;
    cursor: pointer;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
}

.navigation-panel {
  height: calc(100vh - 410px);
  width: 27rem;
  border-right: 1px solid #949494;
  overflow-y: auto;

  .aircraft-number {
    padding-top: 10px;
    span {
      font-size: 24px;
      font-weight: 700;
      color: #333333;
      text-align: left;
    }
  }

  .task-card-number {
    padding-top: 10px;
    span {
      font-size: 24px;
      font-weight: 700;
      color: #333333;
      text-align: left;
    }
  }

  .border-top {
    border-top: 1px solid $dove-gray !important;
  }

  .block-list-item {
    padding: 10px;
    .list-item-label {
      min-width: 65px;
      padding-right: 20px;
      padding-left: 5px;
      margin-bottom: 0px;
      cursor: pointer;
    }
    .list-item-icon {
      width: 16px;
      height: 16px;
    }
    .insp-required-icon {
      width: 25px;
      height: 20px;
      position: relative;
      top: -2px;
    }
    .checked-icon {
      position: absolute;
      top: 4px;
      left: -16px;
    }
    .list-item-tech-icon {
      margin-right: 10px;
    }
  }

  .nav-list-items {
    display: flex;
    list-style: none;
    font-size: 1.6rem;
    color: $united-purple;
    letter-spacing: 0;
    height: 3.7rem;
    align-items: center;
    padding-left: 3rem;
    cursor: pointer;

    &:not(.highlight):hover {
      color: white;
      background: rgba(96, 68, 187, 0.85);
      //opacity: 0.7;
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      // -webkit-transform: perspective(1px) translateZ(0);
      // transform: perspective(1px) translateZ(0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform;
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    }
  }

  .highlight {
    color: $white;
    background: $united-purple;
  }
}

.link {
  // used in illustrations too
  color: $united-purple;
  text-decoration: underline;
  font-size: $font-size-fs16;
  width: max-content;
  span,
  a {
    cursor: pointer;
  }
  &:hover {
    color: $united-purple;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    text-decoration: underline;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
    // box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  }
}
.no-nr-modal {
  width: 600px;
  max-height: 500px;
  height: 180px;
  background-color: #ffffff;
  &.modal-content {
    padding: 0;
  }
  .modal-content-header {
    border-radius: 6px 6px 0 0;
    background-color: #0c2340;
    align-items: center;
    height: 5rem;
    padding: 1rem;
    h3 {
      color: $white;
      padding: 0;
      font-weight: 400;
      margin-left: 1rem;
    }
  }
  .modal-content-body {
    padding: 2rem;
    margin-top: 0;
  }
  .btn {
    width: 45px;
  }
}
