.addvisit {
    width: 1265px;
    height: 614px;   
    margin-left: auto;
    margin-right: auto;
    .add-visit-cancel {
      margin: auto 0;
    }
    .add-visit-buttons {
      float: right;
    }
}    

.subtitle-padding {
  padding-bottom: 50px;
}

.addvisit-title {    
    margin-bottom: 40px;
}
.addvisit-acinformation {
  height: 34px;
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 34px; 
}
  
 .rbt-input-main.form-control.rbt-input {
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 6px;
    height: 45px;
  }

.addvisit.form-group.button.row {
    margin-top: 35px;
    margin-left: 0px;
    height: 100px; 
    display: flex;
    float: right;
  .form-control {
      font-size: 14px;
  }
  .btn.btn-primary {
    width: 110px;
    height: 45px;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    background: #6244BB;
    border-radius: 6px;
  }
  .col-xs-4.form-group {
    margin-bottom: 10px;
  }
  .col-xs-4.form-group a {
    font-family: Open Sans;
    font-size: 16px;
    color: #6244BB;
    letter-spacing: 0;
  }

  .btn.btn-primary.disabled {
    cursor: not-allowed;
    color:black;
    background-color: #666666;
    font-size: 16px;
    font-weight: 600;
    border-color: #666666;
  }
}
.addvisit-calendar {
    width: 45px;
    height: 45px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 25px;
    margin-right: 60px;
}

.addvisit-plannedinduction, .addvisit-etr, .addvisit-actualinduction, .addvisit-routines, .addvisit-visitschedule, .addvisit-nonroutines, .addvisit-zonal {
    display: flex;
}
.nocursor {
  cursor: not-allowed !important;
}
.form-control:focus {
  background: #FFFFFF;
  border: 2px solid #6244BB !important;
}
.rbt-input:focus {
  background: #FFFFFF;
  border: 2px solid #6244BB !important;
}
.dropdown-item:hover {
    color: white !important;
    background-color: #6244BB !important;
}
input[type="search"] {
  -webkit-appearance: searchfield;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}