.template-search-container {
    background-color: rgba(12, 35, 64, 0.9);
    color: #FFFFFF;
    padding: 25px;
    margin-right: -15px;
    margin-left: -15px;
    .select-container {
        .header-title{
            color: #666666;
        }
    }
    .template-search-radio {
        .RadioButton {
            input[type="radio"] {
                &:checked+label:before {
                    border-color: #FFFFFF;
                }
            }

            label {
                &:before {
                    border: 2px solid #FFFFFF;
                }

                &:after {
                    background: #FFFFFF;
                }
            }
        }
    }
}

.template-search-note {
    color: #69B3E7;
}

.template-search-form {
    display: flex;
    padding-top: 10px;
    flex-wrap: wrap;
    gap:1rem;
    .date-range .date-range-labels-container,
    .date-range-show .date-range-labels-container {
        padding-top: 0px;
    }

    .date-range .date-range-labels-container .calendar-icon,
    .date-range-show .date-range-labels-container .calendar-icon {
        top: 27px;
    }

    .invalid>input {
        border: 1px solid red;
    }
    .dd-wrapper {
        min-width: 270px;
        width: 270px;
        padding-right: 20px;
        font-size: 14px;
    }
}

.template-search-fields {
    align-self: center;
     padding-bottom: 10px;
    font-size: 1.4rem;
    //padding-right: 0;
    padding-top: 0px;

    .select-box--container {
        background-color: #FFFFFF;
        color: #333333;

        .select-box--items .list-item {
            min-height: 34px;
        }
    }   

    .options-container {
        margin-left: 0px;
        width: 200px;
    }
}

.template-search-clear,
.template-search-clear:hover {
    background-color: transparent;
    color: #FFFFFF;
    border-color: #FFFFFF;
}

.template-search-search,
.template-search-search:hover {
    background-color: #FFFFFF;
    color: #0C2340;
    border-color: #0C2340;
}

.template-search-tabs {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 15px;

    .template-search-results {
        padding: 30px 30px 0px 30px;
    }

    .template-card {
        display: flex;
        flex-wrap: wrap;
        color: #333333;
        border: 1px solid #979797;
        background-color: #FFFFFF;
        border-radius: 6px;
        padding: 15px 15px 0px 15px;
        justify-content: space-between;
        margin-bottom: 12px;

        &>div {
            padding-right: 10px;
            padding-bottom: 15px;
            min-width: 100px;
            flex: 1 1 0;
        }

        &>.template-data-large {
            flex: 2 1 0;
        }

        &>.template-data-medium {
            flex: 1.5 1 0;
        }

        &>.template-data-small {
            flex: 0.5 1 0;
        }

        .template-type {
            border: 1px solid #647D8F;
            border-radius: 32px;
            width: auto;
            color: #647D8F;
            height: 30px;
            padding: 5px;
            margin-right: 30px;
            text-align: center;
            margin-top: -15px;
        }

        .content-holder {
            .content-value {
                font-weight: $font-weight-bold;
                font-size: 16px;
            }
        }

        .template-name {
            font-weight: $font-weight-bold;
            font-size: 16px;
        }

        .chevron-right-icon {
            height: 4.4rem;
            width: 1.4rem;
        }

        .right-icon {
            text-align: end;
        }

        .update-link {
            color: $united-purple;
            cursor: pointer;
        }
    }
}

.templatetaskcard-search-tabs {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 15px;
    background-color: #F7F7F7;

    .templatetaskcard-search-results {
        padding: 30px 30px 0px 30px;
        //height: 800px;
        height: calc(100vh - 445px);
        overflow-y: auto;
        @media print{
            overflow-y: visible;
        }
    }

    .template-taskcard {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        padding: 0px 20px 10px 15px;
        border: 1px solid $mercury-gray;
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        margin: 0 0 20px 0;
        background-color: #ffffff;

        &-info {
            >.col {
                padding-top: 20px;
            }

            width: calc(100% - 30px);
        }

        &-card {
            min-width: 18rem;

            div {
                display: flex;
                flex-direction: column;
            }
        }

        &-description {
            min-width: 260px;
            flex-grow: 5;
            color: $night-rider-gray;
            font-weight: $font-weight-bold;
        }

        &-workstop {
            min-width: 60px;
            max-width: 60px;
            text-align: center;
        }

        &-skills-zones-container,
        &-workarea-container {
            min-width: 300px;
            max-width: 300px;
            color: $night-rider-gray;
        }

        &-skills-zones {
            border-left: 2px solid #e6e6e6;
            height: 100%;
            padding-left: 20px;
            display: flex;
            font-size: $font-size-normal;

            &-info {
                align-self: center;
                display: flex;

                .lbl {
                    width: 40px;
                }

                .value {
                    font-weight: $font-weight-bold;
                }
            }
        }

        &-workarea {
            border-left: 2px solid #e6e6e6;
            height: 100%;
            padding-left: 20px;
            display: flex;
            font-size: $font-size-normal;
            width: 430px;

            &-info {
                align-self: center;
                width: 430px;

                .lbl {
                    width: 120px;
                }

                .value {
                    font-weight: $font-weight-bold;
                }
            }
        }

        &-chevron {
            position: absolute;
            right: 0px;
            top: 0px;
            min-width: 50px;
            height: 100%;
            display: flex;
            align-items: center;
            align-content: center;
            cursor: pointer;
        }

        .details-icon {
            width: 16px;
            height: 18px;
            cursor: pointer;
            //margin-left: 10px;
        }

        .content-holder {
            min-width: 24rem;
            padding: 2px 10px 2px 0px;
            font-size: $font-size-large;

            label {
                font-weight: $font-weight-semi-bold;
                color: $cod-gray;
            }

            span {
                color: $night-rider-gray;
                margin-bottom: 15px;
                word-break: break-all;
            }
        }
    }

    .shift-grave-icon {
        width: 15px;
        height: 15px;
    }

    .shift-day-icon {
        width: 15px;
        height: 15px;
    }

    .shift-swing-icon {
        width: 15px;
        height: 15px;
    }
}

.template-taskcard-workstop {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
}