.contact-info {
    display:flex;
    font-size:1.6rem;
    .contact-info-container,.contact-date-container{
        display:flex;
        flex-wrap: nowrap;
        align-items: center;
        .contact-icon {
            width:13px;
            height:16px;
        }
        .clock-icon {
            width: 16px;
            height:16px;
        }
        .contact-text {
            width:200px;
            flex-wrap: nowrap;
            align-self: center;
            padding-left:5px;
        }
    }
    .contact-date-container {
        padding-left:10px;
    }
}

.flex-col {
    flex-direction: column;
    .contact-date-container {
        padding-top:5px;
        padding-left:0px;
    }
}
.flex-wrap{
    .contact-date-container {
      padding-top: 5px;
      padding-left: 0px;
  }
  }