.shift-calculator-control{
 width: 150px;
 border: 1px solid #333333;
 border-radius: 6px;
 display:flex;
 .cell {
     width:50px;
     text-align: center;     
     margin:auto;
     height:40px;
     display:flex;
     span {
         margin:auto;
         line-height:40px;
     }
 }
 .cell:first-child {
    border-right: 1px solid #333333;
    cursor: pointer;
 }
 .cell:last-child {
    border-left: 1px solid #333333;
    cursor: pointer;
 }
}
.shift-info {    
    display:flex;
    padding-top:7px;
    height:55px;
    .icon {
        padding-right: 5px;
    }
    .info {
        font-size: 10px;
        width:180px;
        color: #000000;
        font-style: italic;
    }
}