.dash1-notes-modal {
    padding: 0;
    border: none;
    width: 88rem;
    min-height: 50vh;
    max-height: 85vh;
    border-radius: 10px 10px 6px 6px;
    overflow-y: auto;

    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 5rem;
        padding: 1rem;

        h3 {
            color: white;
            font-weight: 600;
            margin-left: 1rem;
            font-size: 24px;
            font-family: 'Open Sans' Arial, Helvetica, sans-serif;
        }

        svg {
            margin-right: 1rem;
            height: 1.5rem;
        }
    }

    .modal-content-body {
        overflow-y: auto;

        .dash1-create-section {
            display: flex;
            flex-direction: column;
            margin: 0.5rem 2rem 2rem 2rem;

            .tabs {
                .tab-list-item {
                    width: 50%;
                }
            }

            .dropdowns {
                align-items: center;

                .customCheckbox {
                    margin-top: 2.7rem;

                    .workstop-check-text {
                        margin-left: 1.7rem !important;
                    }
                }
            }

            .notes-type-dropdown {
                margin-right: 2rem;
                width: 33%;
            }

            .notes-input-container {
                position: relative;

                .count {
                    position: absolute;
                    bottom: 1px;
                    right: 10px;
                }

                .input-notes {
                    display: flex;
                    flex-direction: column;

                    & textarea {
                        max-width: 100%;
                        height: 12rem;
                    }

                }

                .count {
                    font-size: 1.2rem;
                    font-style: italic;
                    color: #949494;
                }
            }
        }

        .notes-container {
            max-width: 100%;
            max-height: 27rem;
            margin: 2rem 2rem 2rem 2rem;
            font-size: 1.4rem;
            overflow-y: auto;

            &.nr-notes {
                max-height: 100%;
            }

            .list-item-note {
                display: flex;
                padding-bottom: 15px;

                &:nth-last-of-type(odd) {
                    background-color: rgba(211, 211, 211, 0.1);
                }

                .avatar {
                    max-height: 54px;
                    min-width: 54px;
                    background-color: #e6e6e6;
                    border-radius: 50%;
                    color: #333333;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 2rem;
                }

                .notes-block {
                    width: 100%;
                }

                .notes-info {
                    display: flex;
                    width: 100%;
                    margin-top: 1rem;

                    .notes-creation-info {
                        display: flex;
                        flex-direction: column;
                        width: 50%;

                        .added-by {
                            color: #333333;
                        }

                        .date {
                            color: #949494;
                            font-size: 12px;
                        }
                    }

                    .notes-tag-info {
                        display: flex;
                        width: 50%;

                        .notes-tags {
                            display: flex;
                            flex-direction: column;
                            width: 60%;
                        }

                        .notes-workstop {
                            display: flex;
                            align-items: flex-end;
                            width: 40%;
                            height: fit-content;
                            color: #FF1A1A;

                            .stop-icon {
                                height: 1.6rem;
                                width: 1.6rem;
                                margin-right: 1rem;
                            }
                        }
                    }

                }

                .note {
                    color: #000000;
                    padding: 2rem 0;
                }
            }

        }

        .modal-section-split {
            height: 1px;
            width: 100%;
            box-shadow: 0 2px 4px 0 rgba(148, 148, 148, 0.50);
        }

        .btn-section-modal {
            margin: unset;
            margin-top: 2rem;

            .btn {
                width: 120px;
                margin: 0;
            }
        }

        .ddl-container.notes-filter {
            margin: 2rem;
            width: 34rem;
        }

        .filter-notes-type-dropdown {
            margin: 2rem;
            width: 33%;
        }
    }

    .notes-spinner-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.modal-section {
    display: flex;

    .dd-wrapper {
        margin-right: 3rem;
    }

    .ddl-label {
        font-size: 14px;
    }
}

.max-h-100 {
    max-height: 100% !important;
}