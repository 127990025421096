.update-location {
    width:344px;
    height: 306px;
    font-size:14px;
    background-color: #ffffff;
    border:1px solid #E6E6E6;
    border-radius: 6px;
    padding-top: 20px;
    padding-bottom: 20px;
    &-title {
        font-size:28px;
        font-weight: $font-weight-bold;
        padding-bottom:20px;
    }
    .update-location-title img{
        float: right;
        cursor: pointer;
    }
    .location-section {
        .input-set {
            margin-bottom:20px;
            .datetime-input {
                width: 200px;
                height: 45px;
                border: 1px solid #333333;
                border-radius: 6px;
                padding:12px;
            }
        }
    }
    .buttons {
        margin-top: 40px;
    }    
    .btn.btn-secondary {
        width: 110px;
        height: 45px;
        font-family: Open Sans;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        background: #6244BB;
        border-radius: 6px;
    }


    // .btn-link {
    //     cursor: pointer;
    //     color: #6244BB;
    //     padding-top: 5px;
    // }

}
.link.log-book-link{
    font-weight:bold;
    font-size:2rem;
    display: block;
    svg {
        width: 16px;
        height: 16px;
        margin-left: 5px;
    }    
}

.rts-reason-container {
    padding-bottom:20px;
    .rts-reason {
        display:block;
        width:600px;
    }
}
