.taskcard-details {
  overflow-y: auto;
  margin-bottom: 100px;
  overflow-x: hidden;
  padding-left: 10px;

  .input-field-container {
    padding-right: 20px;
  }

  .input-class {
    width: 200px;
    height: 45px;
    border: 1px solid #333333;
    border-radius: 6px;
    font-size: $font-size-normal;
  }

  .not-valid {
    border: 1px solid red !important;
  }

  &>.nr-accordion {
    .accordion-item-content {
      z-index: 2 !important;
    }
  }

  &>.nr-accordion~.nr-accordion {
    .accordion-item-content {
      z-index: 1 !important;
    }
  }
}

.taskcard-overview {
  position: relative;
  z-index: 3 !important;

  .edit-button-ht {
    height: 5rem;
  }

  .overview {
    color: #000000;
    font-family: "Open Sans";
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 2.7rem;
  }

  justify-content: space-between;
  width: 100%;

  .content-holder {
    color: #333333;
    padding-bottom: 1.8rem;

    .content-label {
      font-size: $font-size-fs16;
    }

    .content-value {
      font-size: $font-size-fs16;
      font-weight: $font-weight-bold;
    }
  }

  .taskcard-overview-content {
    padding-bottom: 2rem;

    .ddlSmall {
      width: 200px;
    }

    .cellwidth {
      max-width: 275px;
      min-width: 275px;

      .addHeight {
        .dd-scroll-list {
          max-height: 400px !important;
          overflow-y: scroll;
        }
      }
    }

    .cellwidth-multisel {
      max-width: 380px;
      min-width: 380px;
    }

    .input-field {
      font-size: $font-size-normal;
      padding-right: 20px;
      padding-top: 20px;

      input {
        width: 175px;
        height: 45px;
        border: 1px solid #333333;
        border-radius: 6px;
        font-size: $font-size-normal;
      }
    }

    .jobnumber {
      &-label {
        z-index: 1;
      }

      &-value {
        z-index: 2;
        font-size: 1.8rem;
      }

      &-content {
        z-index: 3;
        padding-left: 0px;
        display: flex;
      }

      position: relative;

      .positionCode,
      .serialNumber {
        font-size: 14px;
        color: #0c2340;
      }

      .positionCodediv {
        padding-left: 0px;
        padding-right: 1rem;
      }
    }
  }

  .closing-req {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .toggle-title {
    font-size: 1.4rem;
    color: #333333;
    padding-right: 1rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 2.8rem;
    height: 2.1rem;
    margin-bottom: 0px;
  }

  .switch input {
    width: 2.6rem;
    height: 1.8rem;
  }

  .input-field {
    padding-top: 0rem !important;
  }

  .shift-selector-container {
    position: absolute;
    top: 20px;
    z-index: 10;
    padding: 15px;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    background-color: #ffffff;
    width: 607px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    cursor: default;
    right: -5px;

    &-title {
      font-size: $font-size-fs18;
      font-weight: $font-weight-semi-bold;
      color: $night-rider-gray;
      padding-bottom: 20px;
    }

    &-content {
      width: 100%;
      overflow-x: auto;
      display: flex;
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      font-size: $font-size-normal;
      font-weight: $font-weight-semi-bold;
      color: $united-purple;
      padding-top: 20px;

      span {
        cursor: pointer;
      }
    }

    &::after {
      content: "";
      background-color: white;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 550px;
      top: -10px;
      transform: rotate(45deg);
      border-top: 2px solid #e6e6e6;
      border-left: 2px solid #e6e6e6;
    }
  }
}

.task-card {
  width: 70%;
  display: flex;
  font-size: 1.6rem;
  margin-top: 1rem;
  border: 1px solid #949494;
  border-radius: 0.6rem;
  padding: 5px 15px 10px 15px;

  .taskcard-desc {
    font-weight: bold;
    font-size: 1.4rem;
  }

  .task-card-section,
  .override-section,
  .status-bar {
    display: flex;
    flex-flow: column;
    padding: 0 1rem;
    margin-right: 0px;
    margin-left: auto;
    align-self: center;

    span {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.4px;
    }

    .details-icon {
      width: 16px;
      height: 18px;
      cursor: pointer;
      //margin-left: 10px;
    }
  }
  .partial-indicator{
      position: relative;
      left: 0px;
      top: 0px;
      align-items: center;
      text-align: center;
      background-color: $united-purple;
      color: #ffffff;
      font-size: 12px;
      width: 150px;
  }
}

.dup-cards { 
  background-color: $outrageous-orange;
  border-radius: 15px;
  border: 1px solid $outrageous-orange;
  padding: 2px;
  margin-top: -3px;
  margin-left: 10px;
  font-size: 14px;
  width: 50px;
  height: 26px;
  text-align: center;
  &.enable-click{
    cursor: pointer;
  }
}
// .dependency {
//   margin: auto;
//   margin-left: 7.5rem;
// }

.dependent-taskcard-header {
  height: 4rem;
  margin-top: 2rem;
  background-color: #e6e6e6;
  align-items: center;
  display: grid;
  grid-template-columns: 55% 1fr 1fr 10%;
  .taskcard-label {
    width: 70%;
    font-size: 1.6rem;
    // margin-left: 1rem;
  }

  .dependency-label {
    font-size: 1.6rem;
    // margin-left: 7.5rem;
  }
}
.task-card-list {
  .task-card-container{
    display:grid;
    grid-template-columns: 55% 1fr 1fr 10%;
    justify-content: center;
    align-items: center;
    .nr-card {
      //display: flex;
      position: relative;
      flex-wrap: wrap;
      padding: 0px 20px 20px 20px;
      border: 1px solid $mercury-gray;
      //box-shadow: 1px 1px rgba(0, 0, 0, 0.07);
      box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
      border-radius: 6px;
      margin: 1rem 0;
      background-color: #ffffff;
      &-nrstatus {
        min-width: 150px;
        max-width: 150px;
        margin: auto 1px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: $font-size-main;
      }
    }
    .progress-col{
      &.no-padding{
        .dash1-progress-bar{
          padding:0!important;
        }
      }
      .partial-indicator {
        position: relative;
        left: 0px;
        top: -1px;
        align-items: center;
        text-align: center;
        background-color: $united-purple;
        color: #ffffff;
        font-size: 12px;
        width: 150px;
    }
    }
    .dependency{
      .row-section{
        margin:0;
        padding:0
      }
    }
  }
}

.add-link {
  .link-container {
    display: flex;
    cursor: pointer;
  }

  .icon {
    font-size: 3rem;
    padding-right: 10px;
    font-weight: $font-weight-normal;
  }

  display: inline-flex;
  cursor: pointer;
  color: $united-purple;
  font-weight: $font-weight-semi-bold;
  align-self: center;
  line-height: 30px;
}

.taskcard-link {

  // float: right;
  // width: 75%;
  a {
    color: #6422BB;
  }

  margin-left: 440px;

  .link-container {
    display: flex;
    cursor: pointer;
  }

  .icon {
    font-size: 3rem;
    padding-right: 10px;
    font-weight: $font-weight-normal;
  }

  display: inline-flex;
  cursor: pointer;
  color: $united-purple;
  font-weight: $font-weight-semi-bold;
  align-self: center;
  line-height: 30px;
}

.dependent-taskcard {
  .add-link-disable {
    cursor: not-allowed;
    pointer-events: all !important;
    color: #949494;
  }
}

.estimated-hours-template {
  padding-top: 2rem;
  width: 790px;
  min-width: 790px;

  .header {
    background-color: #e6e6e6;
    height: 4rem;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 1.6rem;
  }

  .input-class {
    width: 200px;
    height: 45px;
    border: 1px solid #333333;
    border-radius: 6px;
    font-size: 1.4rem;
  }

  .select-box--container {
    width: 200px;
  }
}

.dependent-tc-modal {
  width: 765px;
  background-color: #ffffff;
  border-radius: 6px;

  .template-search-fields {
    input {
      width: 330px;
    }
  }

  .dd-wrapper {

    // width: 45%!important;
    // max-width: 100%!important;
    // .dd-list{
    //   width: 350px;
    // }
    .dd-scroll-list {
      height: 370px;
      overflow: auto;
    }
  }

  .ddl-depend {
    input {
      width: 285px;
    }
  }

  .testHopInputs {
    input {
      width: 285px;
    }
  }

  .invalid>input {
    border: 1px solid red;
  }

  .modal-content-success-header {
    background-color: #208500;
    color: #ffffff;
    height: 5rem;
    padding: 0px 20px;
    display: flex;
    align-items: center;
  }

  .modal-content-header {
    border-radius: 6px 6px 0 0;
    background-color: #0c2340;
    align-items: center;
    height: 5rem;
    padding: 0px 20px;

    h3 {
      color: #ffffff;
      margin-left: 1rem;
    }
  }

  .modal-content-error {
    align-items: center;
    height: 5rem;
    font-weight: 600;
  }

  &-ddl {
    width: 350px;
    padding-right: 20px;
  }

  &-content {
    padding: 20px;
  }

  &-search-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .dependent-tc-modal-search-fields{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 0;
    .template-search-fields{
      padding-bottom: 0;
      font-size: 16px;
    }
    .dd-wrapper{
      width: 100%;
      max-width: 100%;
      padding-right: 20px;
    }
  }

  &-btn-container {
    display: flex;
    margin-left: auto;
    padding-top: 20px;
  }

  .ata-lookup-wrapper {
    width: 350px;
    padding-right: 20px;
  }

  .btn {
    width: 90px;
  }

  .modal-content-footer {
    border-radius: 0 0 6px 6px;
    height: 5rem;
    margin: 0px 20px 20px 20px;
    display: flex;
    flex-direction: row-reverse;

    .btn-large {
      width: 150px;
    }
  }

  .saving-in-progress {
    div {
      user-select: none;
    }
  }

  .task-card {
    width: 100%;

    .task-card-section {
      width: 225px;
      min-width: 175px;
    }
  }

  .btn.btn-link {
    width: 145px;
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    color: #949494;
  }

  .dependent-tc-search-results {
    overflow-y: auto;
    // max-height: 40rem;
    max-height: 25svh;
  }
}

.taskcard-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100px;
  background-color: #ffffff;
  z-index: 10;
}

.taskcard-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
}

.multiselect {
  border: 4px solid #6244bb;
}

.shift-summary-modal {
  padding: 0;
  border: none;
  width: 88rem;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 6px;

  .modal-content-success-header {
    background-color: #208500;
    color: #ffffff;
    height: 5rem;
    padding: 0px 20px;
    display: flex;
    align-items: center;
  }

  .modal-content-header {
    border-radius: 6px 6px 0 0;
    background-color: #0c2340;
    align-items: center;
    height: 5rem;
    padding: 1rem;

    h3 {
      color: white;
      font-weight: 600;
      margin-left: 1rem;
      font-size: 24px;
      font-family: 'Open Sans' Arial, Helvetica, sans-serif;
    }

    svg {
      margin-right: 1rem;
      height: 1.5rem;
    }
  }

  .modal-content-error {
    align-items: center;
    height: 5rem;
    font-weight: 600;
  }

  .modal-content-footer {
    border-radius: 0 0 6px 6px;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 20px;
  }

  .modal-content-body {
    overflow-y: auto;

    .cancel-btn {
      width: auto !important;
    }

    .summaryTitle {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .notes-input-container {
      position: relative;
      padding: 20px;

      .count {
        position: absolute;
        bottom: 1px;
        right: 10px;
      }

      .input-notes {
        display: flex;
        flex-direction: column;

        & textarea {
          max-width: 100%;
          height: 12rem;
        }

      }

      .count {
        font-size: 1.2rem;
        font-style: italic;
        color: #949494;
      }
    }

    .notes-container {
      max-width: 100%;
      max-height: 27rem;
      font-size: 1.4rem;
      overflow-y: auto;
      border-top: 1px solid #eee;
      margin-top: 20px;
      padding: 20px;

      &.nr-notes {
        max-height: 100%;
      }

      .list-item-note {
        display: flex;
        padding-bottom: 15px;

        &:nth-last-of-type(odd) {
          background-color: rgba(211, 211, 211, 0.1);
        }

        .avatar {
          max-height: 54px;
          min-width: 54px;
          background-color: #e6e6e6;
          border-radius: 50%;
          color: #333333;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2rem;
        }

        .notes-block {
          width: 100%;
        }

        .notes-info {
          display: flex;
          width: 100%;
          margin-top: 1rem;

          .notes-creation-info {
            display: flex;
            flex-direction: column;
            width: 50%;

            .added-by {
              color: #333333;
            }

            .date {
              color: #949494;
              font-size: 12px;
            }
          }

          .notes-tag-info {
            display: flex;
            width: 50%;

            .notes-tags {
              display: flex;
              flex-direction: column;
              width: 60%;
            }

            .notes-workstop {
              display: flex;
              align-items: flex-end;
              width: 40%;
              height: fit-content;
              color: #FF1A1A;

              .stop-icon {
                height: 1.6rem;
                width: 1.6rem;
                margin-right: 1rem;
              }
            }
          }

        }

        .note {
          color: #000000;
          padding: 2rem 0;
        }
      }

    }

    .modal-section-split {
      height: 1px;
      width: 100%;
      box-shadow: 0 2px 4px 0 rgba(148, 148, 148, 0.50);
    }

    .btn-section-modal {
      margin: unset;
      margin-top: 2rem;

      .btn {
        width: 120px;
        margin: 0;
      }
    }

    .ddl-container.notes-filter {
      margin: 2rem;
      width: 34rem;
    }
  }
}