.padding {
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
.card {
  padding: 0px;
  border-radius: 5px 5px 0 0;
}
.visit-card {
    width: 426px;
    margin: 0px 17px 17px 0px;
    border-radius:6px;
    border: 1px solid #E6E6E6;  
    box-shadow: 1px 1px .2em rgba(256, 0, 0, 0.07), -0.1em 0 .2em rgba(256, 0, 0, 0.07); 
    .card.no-gutters {
        width: 446px;
    }
}
.future, .history{
    .visit-card-header{
    background-color: #E6E6E6;
    color: #333333;
    }
    .visit-card-content{
        background-color: #FFFFFF;
    }
}
.visit-card-header {   
    background-color: #0C2340;
    color: white;
    border-radius: 6px 6px 0px 0px;    
    font-size:12px;
    .tail-number {
        font-size: 24px;
        font-weight: $font-weight-bold;
        display:flex;
        margin-top:-7px;
    }
    .location {
        display:flex;
        .location-icon {
            margin-top: -2px;
            svg{
                width:13px;
                height:13px;
            }
        }
        .location-text{
            padding-left: 2px;
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }  
    .check-type {
        font-weight: $font-weight-bold;
        font-size: $font-size-normal;
    }  
}
.visit-card-content {
    min-height: 77px;
    background-color: #FFFFFF;   
    color: #333333;
    .induction, .etr {
        width:125px;
        border-right: 1px solid #CCCCCC;
        height:77px;
    }    
    .induction-date {
        font-size: 16px;
        font-weight: 600;
    }
    .induction-time {
        font-size: 12px;
        font-weight: 600;
    } 
    .visit-stats {
        padding-left: 15px;
        &>div {
            padding-right: 10px;
            //width: 110px;
            .content-value {
                font-weight: $font-weight-bold;
                padding-left:20px;
                margin-left:auto;
            }
        }
    }
    .skeleton.visit-stats {
        .content-label, .content-value {
            width:52px;
        }
    } 
    .more-info {
        align-items: center;
        display: flex;
        margin-left: auto;
        svg {
            width:24px;
            height:24px;
        }
    }  
}
.visit-card-content-future {    
    background-color: #e6e6e6;
    .induction {
        border-right: 1px solid white;
    } 
}
.visit-card-scheduling {
    min-height: 35px; 
    padding-left:10px; 
    display: flex; 
    .contact-icon {
        width:16px;
        height:22px;
    }
}