.open-log-page {
    padding: 1rem 2rem;
    margin: 1rem;
    // display: flex;
    // align-items: center;
    .open-log-page-number {
      margin-left: 1rem;
    }
    .open-log-page-desc {
      align-items: center;
      .dash8 {
        font-size: $font-size-fs18;
        min-width: 16rem;
      }
      > div:not(:last-child) {
        padding: 1rem 1rem 0 0;
      }
      .sceptre {
        font-size: 1.4rem;
        min-width: 16rem;
        span {
          position: relative;
          display: block;
        }
      }    
      .sceptre-color {
        color: #d50032;
      }
  
      .icon-container {
        display: flex;
        padding-top: 1rem;
        > div {
          padding-right: 2rem;
        }
      }
    }
    &.routine-card {
      .card-contact {
        padding: 1rem 0 0;
        svg{
          margin-right: 0px;
        }
      }
    }

    .open-log-page-detail-section { 
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        .nr-card-status {
          background: $white;
          margin-right: 0;
        }
        .open-lp-desc {
          font-weight: bold;
          font-size: 1.4rem;
        }
        .lp-status {
          height: 18px;
          width: 79.2px;
          color: #647D8F;
          font-family: "Open Sans";
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          box-sizing: border-box;
          height: 22.75px;
          width: 90px;
          border: 1px solid #647D8F;
          border-radius: 32px;
          background-color: #FFFFFF;
        }
        .open-lp-section {
          display: flex;
          flex-flow: column;
          padding: 0 1rem;
          word-break: break-all;
          span {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.4px;
          }
        }
        .icon-right {
            padding-right: 55px;
            height: 24px;
            width: 24px;
        }
        .actions-container {
          position: relative;
        }
      }
  }