@import "./ViewNonRoutine/SubTasks/subtasks";
@import "./ViewNonRoutine/Activity/activity";
@import "./ViewNonRoutine/NonRoutineSummary/nonRoutineSummary";
@import "./ViewNonRoutine/Notes/notes";
@import "./NonRoutineActions/nonroutineactions";
@import "./ViewNonRoutine/NonRoutineImages/nonroutineimages";

.nr-accordion {
  &-header {
    line-height: 50px;
    background-color: #0c2340;
    border-radius: 6px;
    padding: 0px 20px;
    justify-content: space-between;

    .title {
      font-size: $font-size-fs20;
      font-weight: $font-weight-semi-bold;
      color: $white;

      .unscheduled-alert {
        width: 16px;
        height: 14px;
        margin-left: 20px;
      }
    }

    .accordion-item-icon {
      width: 24px;
      height: 24px;
      align-self: center;
      transform: rotate(90deg);
    }
  }
}

.subtask-status {
  font-size: 1.3rem;
  font-weight: 700;
  color: #41748d;
  border: 1px solid #41748d;
  border-radius: 32px;
  padding: 0px 5px;
}

.viewall-link {
  color: $united-purple;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
}

.view-non-routine {
  @media print {
    &.hide{
      display: none!important;
    }
  }
  .description {
    font-size: 20px;
    font-weight: $font-weight-bold;
  }

  .contact-info {
    padding: 10px 0;

    .contact-info-container {
      .contact-text {
        width: auto;
        padding-right: 30px;
      }
    }
  }

  .pb-4.section {
    padding: 0 !important;
  }

  color: #333333;
  margin-bottom: 30px;

  .tabs {
    padding-top: 25px;
    position: relative;
  }

  h3 {
    font-size: 2rem;
    padding: 2rem 0 0.5rem 0;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 600;
    padding-bottom: 1rem;
    padding-top: 2rem;
  }

  .materials-table {
    .row {
      min-height: 3.5rem;
      align-items: center;

      div {
        padding: 0 1rem;
      }
    }

    .header {
      background: #e6e6e6;
    }
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    color: #949494;
  }

  .accordion-item.disabled {
    .accordion-header {
      background-color: #d3d3d3;
    }
  }

  // Accordion style
  .accordion-item {
    .notes-container {
      max-width: 100% !important;

      .notes-header {
        display: none;
      }
    }

    &.accordion-item--opened {

      &.Attributes,
      &.Material {
        border: .5px solid lightgrey;
        padding-bottom: 5px;
      }

      .accordion-item-content {
        .notes-container {
          max-width: 100% !important;

          .notes-header {
            display: none;
          }
        }
      }
    }

    //overflow: hidden;
    .accordion-header {
      border-radius: 0;
      display: flex;
      //justify-content: center;
      align-items: center;

      // border-top-left-radius: 5px;
      .link {
        margin-left: auto;
        margin-right: 15px;
        color: #fff;
      }

      button {
        margin-left: auto;
        margin-right: 10px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        height: 40px;
        background: #0c2340;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }

      .manage-nr-button {
        margin-left: auto;
        margin-right: 10px;
        padding: 0 10px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        height: 40px;
        background: #0c2340;
        display: flex;
        align-items: center;
        justify-content: center;

        &.link-disabled,
        &:disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }

        &.link-disabled {
          pointer-events: none;
        }

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .accordion-item-content {
      .manage-border {
        border: 2px solid #e6e6e6;
        border-radius: 6px;

        .view-nr-attributes {
          display: flex;
          justify-content: space-between;
          padding: 1.5em;
          gap: 1.5em;

          &.col-xl-6 {
            div {
              flex: 1 1 0;
            }
          }

          .view-nr-workbin {

            .workbin-content,
            .assignedCrew-content {
              //text-align: center;
              width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            }

            .Tooltip-wrapper {
              display: block !important;

              .right {
                transform: translate(85%) !important;
                top: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

.logbook-inline {
  flex-direction: row;
  flex-wrap: nowrap !important;
}

.btn-section-modal {
  display: flex;
  justify-content: flex-end;
  margin: 2rem;

  .btn,
  .btn-link {
    margin: 0 2rem;
    width: 4.5rem;
  }
}

a:hover {
  text-decoration: none;
}

.no-data-found {
  margin-top: 10px;
  font-size: 1.6rem;
}

.logpage-card {
  border: 1px solid #949494;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  height: 80px;
  color: #333333;

  .logpage-details {
    display: flex;
    //flex-wrap: wrap;
    width: 100%;
    align-self: center;

    .logpage-number {
      font-size: 2rem;
      font-weight: $font-weight-bold;
      padding-left: 15px;
      padding-right: 25px;
    }

    .logpage-desc {
      font-size: 1.6rem;
      align-self: center;
      padding-left: 15px;
    }

    .logpage-status {
      color: #6244BB;
      font-size: 1.6rem;
      font-weight: $font-weight-bold;
      align-self: center;
      padding-left: 15px;
      width: 120px;
      min-width: 120px;
    }

    .logpage-ata {
      width: 190px;
      align-self: center;
      font-size: 16px;
      padding-left: 15px;
      min-width: 190px;

      span {
        font-weight: $font-weight-bold;
      }
    }
  }

  .logpage-navigation {
    padding-right: 25px;
    align-self: center;
  }
}

.logpage-card-bg {
  background-color: #FFFFFF;
}

.status-cleared {
  background-color: rgba(68, 136, 62, 0.4);
  border-color: rgba(68, 136, 62, 0.4);
}

.badge-cleared {
  color: #44883E;
  border-color: #44883E;
}

.status-open-e {
  background-color: rgba(0, 147, 130, 0.5);
  border-color: rgba(0, 147, 130, 0.5);
}

.badge-open-e {
  color: #009382;
  border-color: #009382;
}

.status-open-o {
  background-color: rgba(100, 125, 143, 0.5);
  border-color: rgba(100, 125, 143, 0.5);
}

.badge-open-o {
  color: #647D8F;
  border-color: #647D8F;
}

.status-open-cip {
  background-color: #C5E2FF;
  border-color: #C5E2FF;
}

.badge-open-cip {
  color: #0C2340;
  border-color: #C5E2FF;
}

.status-open-uip {
  background-color: rgba(76, 185, 231, 0.7);
  border-color: rgba(76, 185, 231, 0.7);
}

.badge-open-uip {
  color: #4CB9E7;
  border-color: #4CB9E7;
}

.status-open-ir {
  background-color: rgba(213, 68, 20, 0.3);
  border-color: #D54414;
}

.badge-open-ir {
  color: #D54414;
  border-color: #D54414;
}

.status-void {
  background-color: rgba(118, 118, 118, 0.4);
  border-color: rgba(118, 118, 118, 0.4);
}

.badge-void {
  color: #767676;
  border-color: #767676;
}

.material-labor-spacing {
  padding: 1.5em;
  border: 2px solid #e6e6e6;
  border-radius: 6px;
}

.wkload-dash-number-value {
  display: flex;
  align-items: center;
  margin-right: 15px;
  text-decoration: underline;

  a {
    color: #6244BB !important;

    .nr-card-number {
      cursor: pointer;
      text-decoration: underline;
      padding-right: 0;
    }
  }
}

.dependency-nr{
  font-size: 1.6rem;
  text-decoration: none;
  a {
    .nr-card-number {
      font-size: 1.6rem;
    }
  }
}

.number {
  width: 155px;

  .sub-item {
    font-size: 14px;
  }

  .task-card-section {
    &>span {
      a:first-child {
        margin-right: 8px;
      }
    }
  }
}