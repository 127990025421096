// .container-fluid {
//     padding: 0px !important;
// }
.clickable {
    cursor: pointer;
}
.station {
    font-size:28px;
    font-weight:600;
    //padding:0px 20px 20px 23px;
}
.generic-error-container {
    max-width:650px;
    min-height: 200px;
    text-align: center;
    background-color: #ffffff;
    display:table;
    padding:0 50px;
    position: relative;
    .generic-error {
        margin:auto;
        display:table-cell;
        vertical-align: middle;
        .link-to-refresh {
            color: #2172ba;
            text-decoration: underline;
            cursor:pointer;
            margin:0px;
        }
        .error-details{
            margin: 20px 0;
            border-top: 1px solid grey;
            overflow: auto;
            padding-top:20px;
            max-height:40svh;
            max-width: 750px;
        }
        .toggleBtn{
            font-size: 1.1rem;
            margin-left: 5px;
            cursor: pointer;
            position: absolute;
            right: 4px;
            bottom: 5px;
            border: solid #6244bb;
            transform: rotate(45deg);
            border-width: 0 2px 2px 0;
            padding: 3px;
            // margin-top: 7px;
        }
    }
}