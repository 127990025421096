.basedasboard-tab-container {
  display: flex;
  .filters-container .content ul li {
    min-width: 8.9rem;
  }
  .filters-container {
    &:not(.collapse-filter){
      width: auto!important;
    }
    .row.accordion-list{
      max-width: 300px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: .5rem .8rem;
    }
    .rdtPicker {
      color: #333333;
      position:relative;
    }
  }
}
.searchContainer {
  display: inline-flex;
  position: absolute;
  border-bottom: 2px solid #333333;
  // overflow: hidden;
  margin: 0px 0px 0px 0px;
  height: 26px;
  bottom: 33px;
}
.searchContainer input {
  border-bottom: 0px !important;
}
.searchContainer svg {
  width: 14px;
  height: 24px;
}
.searchIcon {
  padding: 0.5rem;
}
