.visit-container {
  padding-left:13px;
  .no-results{
    font-size: 1.6rem;
    font-weight:600;
  }
}
.collapsed {
  max-height:0px;
  //transition: max-height 0.6s ease;
}
button.accordion {
    width: 100%;
    background-color: whitesmoke;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s linear;
}
button.accordion:hover {
    background-color: #ddd;
}

.accordion-content {
    // padding: 0 10px 0 40px;
    // border-left: 1px solid whitesmoke;
    // border-right: 1px solid whitesmoke;
    // //max-height: 1000px;
    // transition: 1s;
    // overflow: hidden;
    //max-height: 2000rem;
    // transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    // transition-duration: 0.5s;
    // transition-property: max-height;
}
.rotate {
    top: 38%;
    left: 1.2%;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.rotatefuture {
    top: 46%;
    left: 1.3%;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

button.accordionfuture {
    width: 100%;
    background-color: whitesmoke;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s linear;
}
button.accordionfuture:hover {
    background-color: #ddd;
}
.accordionfuture-content {
    padding: 0 20px;
    border-left: 1px solid whitesmoke;
    border-right: 1px solid whitesmoke;
    //max-height: 0;
    transition: max-height 0.6s ease;
    overflow: hidden;
}

.label {
    font-weight: bold;
    font-size: 18px;
    color: #6244BB;
    letter-spacing: 0.32px;
    line-height: 20px;
    padding-left: 10px;
}
// label {
//     display: flex !important;
//     flex-direction: row !important;
//   }
// label:after{
//     content: "";
//     flex: 1 1;
//     border-bottom: 1px solid gray;
//     margin: auto;
//   }
  .visitcontainerdiv img {
      position: sticky;
      padding-left: 25px;
  }
  .visitcontainerdiv label {
      padding-left: 20px !important;
  }

.accordion-filter-padding {
padding-top: 4px;
padding-right: 10px;
.filter-text, .sort-text {
  font-size:11px;
  color:$united-purple;
}

.clickable {
  height:24px;
}
}
.accordion-filters {
    position: relative;
    display: inline-flex;
    margin: 0px 40px 0px auto;
}
.accordion-content {
  padding-top:15px;
}
    
.filter {
    height: 30px;
    width: 30px;
    text-align: center;
    display: inline-block;
}
.filter img {
    cursor: pointer;
}
  /* Float four columns side by side */
.column {
    float: left;
    width: 25%;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  
  /* Remove extra left and right margins, due to padding */
  // .row {margin: 0 -5px;}
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive columns */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    .rotate {
        top: 48.5%;
        left: 3.6%;
    }
  }
  
  /* Style the counter cards */
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 16px;
    text-align: center;
    background-color: #f1f1f1;
  }

.addvisitcard-modal {
.modal {
    width: 1021px !important;
    height: 335px !important;
    position: fixed !important;
    top: 30% !important;
    left: 25% !important;
    display: block !important;
    overflow: visible !important;
    border: 1px solid #E6E6E6;
    box-shadow: 0 2px 1px 0 rgba(0,0,0,0.07);
    border-radius: 6px;
    border-radius: 6px;
    background: #FFFFFF !important;
    transition: 1.1s ease-out;
    box-shadow: 
      -2rem 2rem 2rem rgba(black, 0.2);
    filter: blur(0);
    transform: scale(1);  
    opacity: 1;
    visibility: visible;
    
    @supports (offset-rotate: 0deg) {
      offset-rotate: 0deg;
      &.off {
        offset-distance: 100%;
      }
    }
    @media (prefers-reduced-motion) {
      offset-path: none;
    }
    .content {
      padding: 1rem;
    }
    .actions {
      border-top: 1px solid #ccc;
      background: #eee;
      padding: 0.5rem 1rem;
      button {
        border: 0;
        background: #78f89f;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        line-height: 1;
      }
    }
  }
  .addvisitcard-title label {
    padding: 20px;
    font-family: Open Sans;
    font-size: 28px !important;
    color: #000000;
    letter-spacing: 0;
    line-height: 34px !important;
  }
  .col-xs-2 {
      display: grid;
      padding-left: 25px;
  }
  .col-xs-3 {
    display: grid;
    padding-left: 25px;
    padding-right: 60px;
  }
  .col-xs-3 label {
        width: 230px !important;
  }
  .col-xs-3 input {
      width: 206px !important;
  }
  .form-check.form-check-inline label {
      padding-right: 10px;
  }
  .col-xs-2 label {
    font-family: Open Sans;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
  }  
  .col-xs-4 {
    padding-top: 25px;
    padding-left: 25px;
  }
  .col-xs-4 input {
      padding-right: 15px;
      width: 110px;
  }
  .btn:hover {
    background-color: #351987 !important;
    color: white !important;
}


}

.confirmation-modal {
  width:1021px;
  padding:30px;
  border: 1px solid #E6E6E6;
  box-shadow: 0 2px 1px 0 rgba(0,0,0,0.07);
  border-radius: 6px;
  background: #FFFFFF;
  box-shadow:
        -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);  
  opacity: 1;  
      .content {
        padding: 1rem;
      }
      .actions {
        border-top: 1px solid #ccc;
        background: #eee;
        padding: 0.5rem 1rem;
        button {
          border: 0;
          background: #78f89f;
          border-radius: 5px;
          padding: 0.5rem 1rem;
          font-size: 0.8rem;
          line-height: 1;
        }
      }    
    .col.modal-image img {     
      height: 48px;
      width: 48px;
      padding-top:15px;
    }
    .col.modal-maintext {
      font-size: 28px;
      color: #333333;
      text-align: center;
      padding: 2% 20% 3% 20%;
    }
    .col.modal-secondarytext {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      margin-bottom:3%
    } 
    .modal-buttons {
      //padding-left:30px;
    }
    .col-xs-4.form-group {      
      padding-right: 15px;
    }
    .col-xs-4 input {
        padding-right: 15px;
        width: 110px;
    }
    .btn.btn-primary {
      background-color: #6244BB;
    }
    .btn:hover {
      background-color: #351987 !important;
      color: white !important;
  }
  
  
  }
  .alert-icon {
    width: 48px;
    height: 48px;
    margin-top: 30px;
  }

  
.error-modal {
  .modal {
      width: 1021px !important;
      height: 399px !important;
      position: fixed !important;
      top: 30% !important;
      left: 25% !important;
      display: block !important;
      overflow: visible !important;
      border: 1px solid #E6E6E6;
      box-shadow: 0 2px 1px 0 rgba(0,0,0,0.07);
      border-radius: 6px;
      border-radius: 6px;
      background: #FFFFFF !important;
      transition: 1.1s ease-out;
      box-shadow: 
        -2rem 2rem 2rem rgba(black, 0.2);
      filter: blur(0);
      transform: scale(1);  
      opacity: 1;
      visibility: visible;
      
      @supports (offset-rotate: 0deg) {
        offset-rotate: 0deg;
        &.off {
          offset-distance: 100%;
        }
      }
      @media (prefers-reduced-motion) {
        offset-path: none;
      }
      .content {
        padding: 1rem;
      }
      .actions {
        border-top: 1px solid #ccc;
        background: #eee;
        padding: 0.5rem 1rem;
        button {
          border: 0;
          background: #78f89f;
          border-radius: 5px;
          padding: 0.5rem 1rem;
          font-size: 0.8rem;
          line-height: 1;
        }
      }
    }
    .col.modal-image img {
      vertical-align: middle;
      border-style: none;
      margin-left: 50%;
      margin-right: auto;
      height: 48px;
      width: 48px;
      margin-top: 5%;
    }
    .col.modal-maintext {
      font-size: 28px;
      font-family: Open Sans;
      color: #333333;
      width: 714px;
      height: 102px;
      text-align: center;
      margin-right: 20%;
      margin-left: 20%;
      margin-top: 2%;
    }
    .modal-buttons {
      margin-top: 10%;
      margin-left: 45%;
    }
    .col-xs-4.form-group {      
      padding-right: 15px;
    }
    .col-xs-4 input {
        padding-right: 15px;
        width: 110px;
    } 
  
  }
