.task-audit-container {
    background-color: #f7f7f7;
    padding: 0px;
    margin-right: -15px;
    margin-left: -15px;
    .action-items-container {
        left:0;
        .disabled { 
            cursor:not-allowed; 
            div {
                pointer-events: none;
            }
        }
    }

    .filters-container {
        position: relative;
        max-height: 100%;

        &.collapse-filter {
            height: 40px !important;
        }

        .divider {
            position: relative;
            margin-top: 2rem;
            border: 1px solid white;
            left: -2rem;
            width: calc(100% + 2rem);
        }
    }

    .filter-input-container {
        margin-bottom: 1rem;
    }

    .audit-checkbox-container {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        .header-block {
            position: relative;
            display: flex;

            h5 {
                font-weight: 700;
                font-size: 1.75rem;
                color: white;
                margin-bottom: 1.5rem;
            }

            .chevron-up,
            .chevron-down {
                position: absolute;
                right: 2rem;
            }

            .chevron-down {
                height: 21px;
                width: 21px;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: rotate(90deg);
                -webkit-transition: -webkit-transform 0.5s ease-in-out;
                -moz-transition: -moz-transform 0.5s ease-in-out;
                -o-transition: -o-transform 0.5s ease-in-out;
                transition: transform 0.5s ease-in-out;

                
                &.open {
                    transform: rotate(-90deg);
                    -webkit-transition: -webkit-transform 0.5s ease-in-out;
                    -moz-transition: -moz-transform 0.5s ease-in-out;
                    -o-transition: -o-transform 0.5s ease-in-out;
                    transition: transform 0.5s ease-in-out;
                }
            }

            .chevron-up {
                transform: rotate(-90deg);
                transition: transform 0.5s ease;
            }
        }

        .audit-checkbox-content {
            transition: all 0.5s ease;
            height: 78px;
            overflow: hidden;

            &.closed {
                height: 0px;
                transition: all 0.5s ease;
                overflow: hidden;
            }

            .cusutomCheckbox {
                margin-top: 1.5rem;
            }
        }


    }
}
.audit-dash1-accordion {
    //display:flex;
    //align-items: center;
    width: 90%;
    color: #333333;
    margin-bottom: 15px;
}
.audit-dash1-holder{
    display:flex;
    width: 100%;
}
.audit-dash1 {
    width: 100%;
    background-color: #FFFFFF;
}
.audit-dash1-card {
    display: flex;
    width:100%;
    border: 1px solid #CCCCCC;
    border-radius: 6px 6px 0px 0px;
    padding: 0px 15px 15px 15px;  
    align-items: center;  
    .audit-dash1-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div {
            padding-top: 15px;
            padding-right: 15px;
        }
            p {
             margin-bottom: 0px;
         }
         .taskcard-desc {
             
             span {
             font-weight: $font-weight-bold;
             }
         }
         .taskcard-no {
             width: 175px;
             .task-card-section{
                 label {
                     display:block;
                 }
                 span {
                     a:first-child {
                         margin-right:10px;
                     }
                 }
             }
         }
         .notes {
             width: 70px;
         }
         .pending-review {
             font-weight: $font-weight-bold;
             width: 190px;
             .contact-icon {
                 width: 20px;
                 height: 20px
             }
         }
         .total-blocks {
             margin-right: 50px;
             width: 170px;
            span {
            font-weight: $font-weight-bold;
            }
        }
        .notes {
            .notes {
                svg {
                    width: 23px;
                    height: 27px;
                }
                label {
                    display:none;
                }
                .notes-count {
                    right: 13px;
                }
                .green {
                    background-color: $success-green;
                }
            }
            label {
                display:none;
            }
        }
    }
    .chevron-right-icon {
        padding-top:15px;
        cursor: pointer;
    }
    .acc-expand {
        transform: rotate(-90deg);
        position:relative;
        right:20px;
        top:5px;
    }
    .acc-collapse {
        transform: rotate(90deg);
        transform-origin: left;
    }
}

.audit-activity-dash1-card {
    display: flex;
    width:100%;
    border: 1px solid #CCCCCC;
    border-radius: 6px 6px 0px 0px;
    padding: 0px 15px 15px 15px;  
    align-items: center;  
    .audit-activity-dash1-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div {
            padding-top: 15px;
            padding-right: 15px;
        }
            p {
             margin-bottom: 0px;
         }
         .taskcard-desc {
            display: flex;
            flex-direction: column;
            .assignees{
                display: flex;
                flex-direction: row;
                gap: 5px;
                font-weight: lighter;
                font-size: 12px;
                align-items: center;
                svg{
                    fill:#000!important;
                    height: 12px;
                    width: 12px;
                }
            }
             span {
             font-weight: $font-weight-bold;
             }
         }
         .taskcard-no {
             width: 600px;
             .task-card-section{
                 label {
                     display:block;
                 }
                 span {
                     a:first-child {
                         margin-right:10px;
                     }
                 }
             }
         }
         .total-blocks {
             margin-right: 50px;
             width: 170px;
            span {
            font-weight: $font-weight-bold;
            }
        }
        
    }
}

.audit-blocks {
    border: 1px solid #CCCCCC;
    border-radius: 0px 0px 6px 6px;
    padding: 20px;
    .audit-block {
        padding: 0px 15px;
        border-bottom: 1px solid #CCCCCC;
        display:flex;
        height:54px;
        align-items: center;
        justify-content: space-between;
        & > div:not(:last-child) {
            margin-right: 36px;
        }
        .block{
            width: 50px;
        }
        .description {
            min-width: 300px;
        }
        .no-action{
            width: 66px;
        }
        .approve {
            width: 57px;
        }
        .reject {
            width: 45px;
        }
        .comments {
            width: 400px; 
            margin-left:20px;
            display: flex;
            .required {
                input {
                border-color: red;
                }
            }
            input {
                width: 100%;
                height:40px;
                background-color: transparent;
                align-self: center;
                font-size: 1.4rem;
            }
            
            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }
        }        
    }
    
    .block-header {
        font-size: 14px;
        font-weight: $font-weight-semi-bold;
    }
    .block-body {
        font-size: 16px;
        .description {
            font-weight: $font-weight-semi-bold;
        }
        .block {
            text-align: center;
        }
        .no-action, .approve, .reject {
            text-align: center;
            .RadioButton {
                label {
                    width: 20px;
                    height: 20px;
                    padding: 0px;
                }
            }
        }
    }
    .audit-buttons {
        padding-top:20px;
        justify-content: flex-end;
        display: flex;  
        .audit-cancel{
            margin-right: 40px;
            font-weight: $font-weight-bold;
        }
    }
}

.go-to-block {
    width: 120px;
    color: $united-purple;
    cursor: pointer;
    font-size: 18px;
    font-weight: $font-weight-semi-bold;
    svg {
        margin-left: 15px;
    }
    a {
        color: $united-purple;
    }
}

.audit-blocks {
    border: 1px solid #CCCCCC;
    border-radius: 0px 0px 6px 6px;
    padding: 20px;
    .audit-activity-block {
        padding: 0px 15px;
        border-bottom: 1px solid #CCCCCC;
        height:54px;
       
        &.block-header{
            display: flex;
            .block{
                width: 6%;
            }
            .no-action {
                min-width: 300px;
                width: 36%;
            }
            .comments {
                width: 25%; 
            }
            .reject{
                width: 11%;

            }
            .user-name{
              width:22%
            }
        }
    }
    .audit-activity-details-block{
        padding: 0px 15px;
        border-bottom: 1px solid #CCCCCC;
        display:flex;
        height:54px;
        align-items: center;
        // grid-template-columns: 85px 500px 400px 110px 100%;
        // justify-content: space-between;
       
        .block{
            width: 6%;
        }
        .description {
            min-width: 300px;
            width: 36%;
        }
        .comments {
            // min-width: 300px;
            width: 25%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .status {
            display: flex;
            gap: .5em;
            width: 11%;
            .check-complete-icon {
                width:16px;
            }
        }
        .user-details {
            width: 22%;
        }
    }
    .block-header {
        font-size: 14px;
        font-weight: $font-weight-semi-bold;
    }
    .block-body {
        font-size: 16px;
        .description {
            font-weight: $font-weight-semi-bold;
        }
        .block {
            text-align: center;
        }
    }
}

.blocks-status-container{
    color: #333333;
    font-size: 16px;
    padding-bottom: 25px;
    .blocks-included {
        font-size: 20px;
        font-weight: $font-weight-semi-bold;        
        padding-bottom: 10px;
    }
    .blocks {
        font-weight: $font-weight-semi-bold; 
    }
}

.approved-modal {
    padding: 0;
    border: none;
    width: 65vw;
    height: 70vh;

    .details-modal-header {
        h3 {
            font-size: 20px !important;
        }
    }

    .body {
        overflow-y: auto;
        overflow-x: hidden;
        .approved-details {
            div {
                line-height: 2;
                margin-right: 1.5rem;
            }
    
            &.header {
                font-weight: 700;
            }
    
            .approver-name {
                width: 17em;
            }
    
            .approver-id {
                width: 8em;
            }
    
            .approved-block-number {
                width: 5em;
            }
    
            .approved-timestamp {
                width: 17em;
            }
    
            .approver-comments {
                word-wrap: break-word;
                width: 100%;
            }
        }
    }
}