.profile {
    float: right;
}

#profile {
    .expand {
        display: block;
    }
}

.feedback-modal {
    top: 0%;
    width: 50%;
    padding: 0;
    max-height: 90%;
    overflow-y: scroll;


    &::-webkit-scrollbar {
        width: 7px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background: grey;
    }

    // &::-webkit-scrollbar-track {
    //     background: #85838338;
    // }
}

.releasenotes-modal {
    top: 0%;
    width: 50%;
    padding: 0;
    max-height: 60%;
    
    .releasenotes-modal-body{
        overflow-y: scroll;
    }
    &::-webkit-scrollbar {
        width: 7px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background: grey;
    }

    // &::-webkit-scrollbar-track {
    //     background: #85838338;
    // }
}

.profile-display {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: $united-purple;
    display: flex;
    text-align: center;
    cursor: pointer;

    span {
        margin: auto;
        font-size: 20px;
        letter-spacing: 0.15px;
    }
}

.show {
    display: block;
}

.profile-container {
    position: absolute;
    top: 96%;
    right: 12px;
    display: none;
    background-color: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    z-index: 4;

    &::after {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 9px solid #ffffff;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid transparent;
        position: absolute;
        top: -17px;
        right: 12px;
    }

    &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-bottom: 10px solid #E6E6E6;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        position: absolute;
        top: -21px;
        right: 11px;
    }

    #profile-inner-container {
        width: 308px;
        max-width: 308px;
        display: block;
        padding: 10px 0px 0px 0px;
        font-size: 14px;
        color: #333333;
    }

    .row {
        line-height: 30px;
        padding-bottom: 5px;
    }

    .name {
        font-size: 18px;
        font-weight: 600;
        color: #0C2340;
    }

    .role,
    .profile-station {
        padding-bottom: 15px;

        span {
            font-weight: 600;
        }
    }

    .emp-id {
        font-size: 14px;
        color: #333333;
    }

    .viewing-station {
        padding-bottom: 15px;

        input[type=text] {
            width: 170px;
            border: 1px solid #333333;
            text-transform: uppercase;
            font-size: $font-size-large;
            padding-left: 10px;
        }

        input[type=button] {
            font-size: $font-size-large;
            min-width: 100px;
        }
    }

    .feedback-link {
        color: $united-purple;
        text-decoration: underline;
        cursor: pointer;
        display: none;
    }

    .link-signout {

        font-weight: $font-weight-bold;
        color: #6422BB;
        cursor: pointer;
        width: auto;
        padding-left: 15px;
        // & a {
        //     color: #6422BB;
        // }

        & a {
            color: #6422BB;
        }
    }

    .link-feedback {
        font-weight: $font-weight-bold;
        cursor: pointer;

        & a {
            color: #6422BB;
        }
    }

    .btn-signout {
        background-color: $united-purple;
        color: #ffffff;
        border-radius: 0 0 6px 6px;
        cursor: pointer;
        text-align: right;
        font-size: 18px;
        line-height: 40px;

        & a {
            color: #FFFFFF;
            text-decoration: none;
        }
    }
}