.image-modal-body {
    height: calc(80vh - 6rem);
}
.image-index{
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.nr-illustrations-modal {
    height: calc(80vh);
    width: 80%;
    padding: 0px;
    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 5rem;
        padding: 1rem;
        .image-controls {
            display:flex;
            flex: 1;
            justify-content: space-between;
        }
        h3 {
            color: $white;
            padding: 0;
            font-weight: 400;
            margin-left: 1rem;
        }
    }
    // .image-display {
    //     overflow-x: auto;
    //     overflow-y: auto;
    //     height: 500px;
    // }
}


.nr-image-navigation {
    display:flex;    
    overflow-y: auto; 
    overflow-x: auto;  
    width:100%;    
    .left-nav, .right-nav{
        display:flex;
        // position: absolute;
        // top: 45%;
        // background-color: rgba(100, 34, 187, 0.1);
        padding: 10px;
        width: 70px;
        height: calc(80vh - 10rem);
        justify-content: center;
        align-items: center;
        svg {
            width:26px;
            height:48px;            
            cursor: pointer;
        }
    }
    .right-nav {
        right:40px;
    }
    .left-nav{
        left:40px;
        svg {
            transform: rotate(180deg);
        }
    }
    .image-display {
        display:flex;
        justify-content: center;
        width: 100%;
        flex: 1 1 100%;
        align-items:center;
        min-width:0;
        height: calc(80vh - 10rem);
        .image-container {
            overflow-y: auto;
            overflow-x: auto;
            max-height: calc(80vh - 15rem);
        }
    }
}
.thumbnail-container{
    display:flex;
    .thumbnail-left-nav, .thumbnail-right-nav{

    }
    .thumbnail-display {
        .thumbail{
        width: 140px;
        height:140px;
        }
    }
}
