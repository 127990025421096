.actions-menu {
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &:hover {
      color: $white;
      background: rgba(96, 68, 187, 0.7);
      transform: scale(1.1);
      transition-duration: 0.3s;
      border-radius: 6px;
    }
    &.active {
      color: $white;
      background: $united-purple;
      transform: scale(1.1);
      transition-duration: 0.3s;
      border-radius: 6px;
    }
    &.disabled {
      color: $silver-gray;
      cursor: not-allowed;
      &:hover{
        color:$silver-gray;
        background:transparent;
      }
    }
    span {
      font-size: 3.5rem;
      font-weight: $font-weight-bold;
      height: 0.9rem;
      line-height: 0;
    }
  }
  .actions-container {
    position: absolute;
  }
  .action-items-container {
    position: absolute;
    color: #333333;
    background-color: $white;
    font-size: $font-size-normal;
    border: 1px solid #333333;
    border-radius: 6px;    
    width: 16rem;
    right:-45px;
    top:5px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .action-item {
        line-height:4rem;
        padding-left: 10px;
        &.disabled {
          background-color: lightgray;
          border-radius: 3px;
          pointer-events: none;
        }
  
        &.disabled:hover{
          cursor: not-allowed;
        }
    }
    .action-item:hover {
        background-color: rgba(98, 68, 187,0.7);
        color: $white;
        cursor: pointer;
    }
    .base-dashboard-action-item{
      color: black;
      border-bottom: 1px solid black;
      font-weight:600;
    }
  }