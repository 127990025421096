.zonal-card, .zonal-card-print {
    color: #333333;
    font-size: 16px;
    .zonal-card-header {
        display:flex;
        width:100%;
        justify-content: space-between;
        padding: 10px 0 30px 5px;
        .card-description{
            font-weight: $font-weight-bold;
            max-width: 600px;
        }
        .icons {
            display:flex;
            text-align: center;
            .tech-icon {
                width: 24px;
                height: 16px;
            }
            .insp-icon {
                width: 21px;
                height: 17px;
            }
        }
        .link {
            cursor: pointer;
        }
    }
    .bulk-action-buttons {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        .btn:not(:last-child) {
            margin-right: 20px;
        }
        .bulk-button {
            align-self: center;
        }
        .primary-action-button {
            flex-basis: 153px;
            flex-grow: 0;
            flex-shrink: 0;
        }
        .secondary-action-button {
            flex-basis: 200px;
            flex-grow: 0;
            flex-shrink: 0;
            .thumbsup-icon,
            .thumbsdown-icon {
                height: 24px;
                margin-right: 10px;
            }
            span {
                white-space: nowrap;
            }
        }
    }
    .zonal-card-content {
        @media print {
            &.hide{
                display: none!important;
            }
        } // added this to remove duplicate illustration printing when clicked on illustraions modal popup
        display: flex;
        margin: 0 -15px;
        border-top: 1px solid #979797;
        background-color: #F7F7F7;
        .info-icon {
            width:24px;
            height:24px;
            cursor: pointer;
        }
        .close-icon {
            width:16px;
            height:16px;
            align-self:center;
            cursor: pointer;
        }
        .zonal-general-instructions {
            height: calc(100vh - 275px);
            overflow-y:auto;                      
            width: 520px;    
            padding:15px;        
            .instructions{
                padding:15px;
                .generalinstructions-content {
                    .main-bullet-point {
                        white-space: pre-line;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    .sub-bullet-point {
                        padding-left: 25px;
                    }
                }
            }
            .instructions-title{
                display:flex;
                padding:15px;
                justify-content: space-between;
                font-weight: $font-weight-semi-bold;                
                padding: 15px 0;
                .title-label {
                    padding-left:8px;
                    display: flex;
                    align-self: flex-end;
                }
            }            
        }
        .active-instructions {
            overflow-y: auto;
            width: 520px;
            height: calc(100vh - 337px);
            box-shadow: 2px 2px 4px 0px rgba(148, 148, 148, .28);
            background-color: #FFFFFF;
            transition: width 400ms ease-out, height 400ms ease-out;
            }          
            .collapsed-instructions {
            overflow: hidden;
            width: 70px;
            height: 50px;
            border-radius: 0 6px 6px 0;
            transition: width 400ms ease-out, height 400ms ease-out;   
            padding:0px;      
            margin-top: 15px;   
            .instructions-title {
                background-color: #0C2340;
                transition: background-color 400ms ease-out;
                padding-left:20px;
                .title-label{
                    display:none;
                }
            }
            }
            
        .zonal-card-blocks {
            width: calc(100% - 520px);              
            background-color: #F7F7F7;
            padding: 15px;
            height: calc(100vh - 337px); 
            overflow-y: auto;           
            .nr-blocking-info-container {
                align-self: center;
                margin-bottom: 20px;
                .nr-blocking-info {
                    width: 340px;
                    font-size: $font-size-normal;
                    line-height: 45px;
                    background-color: rgba(244, 99, 53, 0.1);
                    border: 2px solid rgba(244, 99, 53, 0.2);
                    border-radius: 6px;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    svg {
                        width: 42px;
                        height: 42px;
                        padding-right: 20px;
                    }
                }
            }
            .zonal-block {
                width: 100%;
                margin-bottom: 15px;
                border-bottom: 2px solid #999999;
                border-radius: 0 0 6px 6px;
                &-header {
                    background-color: #0C2340;
                    color: #FFFFFF;
                    height: 52px;
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 6px 6px 0 0 ;
                    padding:20px;
                    .activity-link {
                        color: #FFFFFF;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
                &-close{
                    background-color: #FFFFFF;
                    padding: 20px;
                    border-right: 2px solid #999999;
                    border-left: 2px solid #999999;
                    display:flex;
                    .ok-close{
                        width:50%;
                    .close-checkbox {
                        display:flex;                        
                        align-items:center;
                        padding-bottom: 15px;
                        input[type="checkbox"]{
                            width: 2.6rem;
                            height: 1.8rem;
                            margin-right:5px;
                            }
                        }  
                    }
                    .ok-close-signoff-info{
                        width:50%;
                        p {
                            padding-left: 20px;
                        }
                    }                  
                }
                &-content {
                    background-color: #FFFFFF;
                    padding: 15px;
                    border-top: 1px solid #979797;
                    border-right: 2px solid #999999;
                    border-left: 2px solid #999999;
                }
                &-signoff {
                    display:flex;
                    border-right: 2px solid #999999;
                    border-left: 2px solid #999999;
                    padding: 20px 20px 15px 20px;
                    //align-items: center;
                    .signoff-section{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .mech-signoff{
                        //width: 50%;
                        display:flex;
                    }
                    .insp-signoff {
                        //width: 50%;
                        display:flex;
                        height:56px;
                        align-items: center;
                        label {
                            margin:0px;
                        }
                        .check-insp {
                            display: flex;
                            align-items: center;
                            input[type="checkbox"] {
                                width: 2.6rem;
                                height: 1.8rem;
                                margin-right:5px;
                                margin-left: -5px;
                                }
                        }
                        .dropdown {
                            display: flex;
                            flex-direction: column;
                        }
                        .ddl-insp {
                            padding-left: 100px;
                        }
                    }
                    .nr-link {
                        flex: 1 1 auto !important
                    }
                    .open-closed-items {
                        width: 20%;
                        text-align: center;
                    }
                    .secondary-action-section {
                        display: flex;
                        flex-direction: column;
                        padding-left: 3rem;
                    }
                }
                &-signoff-summary {
                    display:flex;
                    padding: 20px 20px 20px 20px;
                    border-right: 2px solid #999999;
                    border-left: 2px solid #999999;
                    .mech-signoff{
                        width: 50%;
                        display:flex;
                    }
                    .insp-signoff {
                        width: 50%;
                        //display:flex;     
                        p {
                            padding-left: 20px;
                        }                                                                    
                    }
                }

            }        
        }
        .line-viewonly {
            height: calc(100vh - 160px);
        }
    }
}
.zonal-details-print{
    font-size: 16px;
    position: absolute;
    left: 50%;    
    display: none;
    font-weight: bold;
    span{
        margin-left: 25px;
    }
    &.display {
        display: block;
    }
}

.zc-print-view {      
    .zonal-general-instructions{
        display: none;
    }  
    .zonal-card-header{
        padding-top: 40px;         
        line-height: 18px;
        .card-description{
          width: 45%;
          display:flex;
        }
    }     
    .zonal-card-content{

        .zonal-card-blocks {

            height: 100%;

        }

    }
}