.filters-container {
    width: 330px;
    //height:561px;
    // background-color: $alabaster;
    font-size: $font-size-normal;
    // color: $night-rider-gray;
    padding-left: 2rem;
    transition: all 1s ease;
    overflow: hidden;
    .filter-icon-container
    {
        padding: 10px 0 25px 0;
    }
    .filter-icon {
        width:24px;
        height:20px;
    }
    .filters-header {
        font-size: $font-size-fs20;
        font-weight: $font-weight-semi-bold;
    }
    .accountability-filters{
        overflow-y: auto;
        height: calc(100% - 75px);
    }
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        background-color: #fafafa;
        border: 2px solid #666666;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 9px;
        border-radius: 3px;
    }
    
    /* On mouse-over, add a grey background color */
    .filters-checkbox:hover input ~ .checkmark {
        background-color: #ccc;
    } 
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    /* Show the checkmark when checked */
    .filters-checkbox input:checked ~ .checkmark:after {
        display: block;
    }
    
    /* Style the checkmark/indicator */
    .filters-checkbox .checkmark:after {
        left: 2px;
        top: 2px;
        background-color: #6422BB;
        width: 14px;
        height: 14px;
        border-radius: 2px;
    }

    .checkbox-filters{
        -webkit-appearance: none;
        background-color: #fafafa;
        border: 2px solid #666666;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
        padding: 9px;
        border-radius: 3px;
        display: inline-block;
        position: relative;
        cursor: pointer;
    }
    .checkbox-filters:active, .checkbox-filters:checked:active {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);        
    }
    .checkbox-filters:focus{
        outline: 0;
    }    
    .checkbox-filters:checked {
        background-color: #e9ecee;
        border: 2px solid #6422BB;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
        color: #99a1a7;
    }
    .checkbox-filters:checked:after {
        content: '';
        font-size: 14px;
        position: absolute;
        top: 2px;
        width: 14px;
        left: 2px;
        color: #6422bb;
        border-radius: 3px;
        height: 14px;
        background-color: #6422bb;
        transition: 0.2s;
    }
    .checkbox-filters-label {
        position: absolute;
        padding-left: 10px;
        color: #333333;
        cursor: pointer;
    }
}

.collapse-filter{
    width:65px;
    height:40px;
    transition: all 1s ease;
    overflow: hidden;
    background-color: $united-purple;
    border-radius: 0px 5px 5px 0px;
}
