.notification {
    position: fixed;
    right:20px;
    bottom:20px;
    z-index:4;
    width: 510px;
    height:60px;
    display: flex;
    //justify-content: space-between;
    align-items: center;  
    padding: 20px;
    border-radius: 6px;  
    color:$white;
    z-index:10003;
    span {
        padding-left: 20px;
    }
    .close-icon {
        width:14px;
        height:14px;
        margin-left:auto;
    }
}
.success{
    background-color: $success-green;
}
.failure {
    background-color: $crimson-red;
}

.addvisit-success {
    width: 945px;
    height: 88px;
    background: #FFFFFF;
    border: 1px solid #208500;
    border-radius: 6px;
    margin-left: 170px;
}
.addvisit-successcontent {
    margin-left: 20px;
    margin-top: 10px;
}
.addvisit-successcontent img {
    margin-right: 8px;
    margin-bottom: 7px;
}
.successname {
    width: 280x;
    height: 33px;
    font-family: Open Sans;
    font-weight: $font-weight-semi-bold;
    font-size: 24px;
    color: #208500;
    letter-spacing: 0;
}
.closeicon {
    float: right;
}
.hide {
    display: none;
}