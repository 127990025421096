.calendar-control {
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    z-index:10;
    top:60px;
    left:20px;
    &-buttons-container {
        width:400px;
               
        display:flex;
        .calendar-day, .calendar-time {
            width:50%;
            text-align: center;
            cursor: pointer;
            height: 60px;
            display:flex;            
            color:#6244BB;
            border-top: 3px solid #6244BB;
            .calendar-day-button {
              margin: auto;
              display: flex;
              span {
                margin:auto;
                padding-left:5px;
                line-height:60px;
              } 
            }
        }    
        .active {
          border-top: 1px solid #949494;  
          color:#666666;          
        }    
    } 
}
.calendar-control::before {
    content:'';
    width:0px;
    border-bottom: 10px solid #FFFFFF;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 0px solid transparent;
    position: absolute;
    filter: drop-shadow(0 -2px 18px #333);
    top: -9px;
    left: 20px;
}

.calendar {
    .datetime-input {
        width: 200px;
        height: 45px;
        border: 1px solid #333333;
        border-radius: 6px;
        padding:12px;
    }
}
  .rdt {
      //position: absolute;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: transparent;
  }
  .rdtStatic .rdtPicker {
    position:relative;
    width:400px;
  }
  .rdtPicker {
    width:300px;
    padding-right:45px;
    padding-left:45px;
    padding-top:10px;
    font-size:14px;
    font-family: 'Open Sans', sans-serif !important;
  }
  .rdtPicker table {
    border-spacing: 0px;
  }
  .rdtPicker .rdtDays th.rdtSwitch {
    text-align:left;
    position:relative;
    left:-46px
  }
  .rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
    background-color: #6244BB;    
}
  .rdtPicker td.rdtToday {
    font-weight:700
  }
  .rdtPicker td.rdtDisabled {
    background-color:#E6E6E6;
    color:#666666;
  }
  .rdtPicker td.rdtDisabled:hover {
    background-color:#d2d2d2;
    color:#666666;
  }
  .rdtPicker th.rdtPrev {
    font-size:43px;
    font-weight:100;
    color:#6244BB;
    position:absolute;
    top:-11px;
    left:10px
  }
  .rdtPicker th.rdtNext {
    font-size:43px;
    font-weight:100;
    color:#6244BB;
    position:absolute;
    top:-11px;
    right:10px
  }
  .rdtSwitch {
    font-size:18px;
    text-align:left;
  }

  .time-content {
    height:241px;
  }
  .time-control-title {
    font-size:18px;
    color:#333333;
    font-weight:$font-weight-semi-bold;
    text-align:center;
    padding-top:20px;
  }
  .time-control-sub-title {
    font-size:18px;
    color:#333333;
    font-weight:$font-weight-semi-bold;
    text-align:center;
    padding-top:10px;
    padding-bottom:10px;
  }
  .time-control {      
      display:flex;
      margin-right:auto;
      margin-left:auto;
      .time-content-container {
        margin-right: auto;
        margin-left: auto;
        flex-direction: row;
        display: flex;
        .seperator {
            display:flex;
            flex-direction: column;
            width:20px;
            height:120px;
            margin:auto;
            span {
                margin:auto;
            }
        }
        .time-control-content {
            display:flex;
            flex-direction: column;
            width:50px;
            height:120px;
            border: 1px solid #333333;
            border-radius: 6px;
            margin-top:auto;
            margin-bottom:auto;
            .cell {
                width:50px;
                text-align: center;     
                margin:auto;
                height:40px;
                display:flex;
                span {
                    margin:auto;
                    line-height:40px;
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
                }
                }
                .cell:first-child {
                    border-bottom: 1px solid #333333;
                    cursor: pointer;
                }
                .cell:last-child {
                    border-top: 1px solid #333333;
                    cursor: pointer;
                }
        }
    }
  }