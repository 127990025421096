.hide {
    display: none;
}
// .show {
//     display:block;
// }
.options-container {
    position: absolute;
    background-color: white;
    width: 100%;
    max-height: 350px;
    color: black;
    font-size: 14px;
    margin-top: 3px;
    border-radius: 0 0 3px 3px;
    border: 1px solid #797979;
    margin-left: -14px;
    overflow-y: auto;
    border-radius: 6px;
    z-index: 200;
    .option {
        cursor: pointer;
        padding: 0px 10px;
        color: #333333;
        line-height: 45px;
        margin-top: auto;
        margin-bottom: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:hover {
            background-color: #dadbed;
            color: #333333;
        }
    }
    .selected {
        background-color: $united-purple;
        color: #ffffff;
        &:after {
            content: "\2713";
            position: absolute;
            right: 10px;
        }
    }
}

.typeahead-container {
    overflow: auto;
    // display: flex;
    // flex-direction: column;
    // & input {
    //     background-color: transparent;
    //     color:#ffffff;
    //     font-size: 14px;
    //     width: 170px;
    //     padding-left: 5px;
    // }
    & input:focus {
        outline: none;
    }
}
.ata-lookup-wrapper {
    position: relative;
    .ata-lookup-container {
        width: 100%;
        .ata-lookup {
            border: 1px solid #333333;
            border-radius: 6px;
            background-color: #ffffff;
            height: 45px;
            width: 100%;
            text-overflow: ellipsis;
            padding-right: 37px;
        }
    }
    .select-box--arrow {
        top: 26px;
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }
    .options-container {
        margin-left: 0;
    }
}
