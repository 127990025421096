.my-shift-container {
    padding-left: 5px;
    .my-shift-wrapper{
        .flex{
            display: flex!important;
            flex-direction: row;
            .filter-wrapper{
                min-width: 80px;
            }
            .my-shift-content{
                flex-grow: 1;
                .execution-toggle{
                    position: relative;
                    .expand-collapse{
                        left: 0!important;
                    }
                }
            }
        }
    }
}

.my-shift-crew-dropdown {
    width: 200px;
    margin-bottom: 20px;
}
.my-shift-tabs {
    border-top: 1px solid #CCCCCC;
    padding-top:10px;
}
.disabled {
    .tab-list-item{
        color: $silver-gray;
        cursor: not-allowed;
    }   
}
.my-shift {
    .info {
        height: 300px;    
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .search-accordion-item-header {
        background-color: #0c2340;
        color:#FFFFFF;
        border-radius: 6px 6px 0 0;
        cursor: default;
        &.searchactive {
            .accordion-icon{
                transform: rotate(90deg);
                transition: .5s;
            }
        }
        .accordion-icon {
            transition: .5s;
            margin: 0px 20px 0px auto;
            svg {
                cursor: pointer;
            }
        }
    }
    .execution-toggle {
        display: flex;
        justify-content: flex-end;
        gap: 1em;
        padding-top: 20px;
        padding-bottom: 20px;
        .expand-collapse {
            color: #6244BB !important;
            font-weight: 700;
            text-decoration: underline;
            position: absolute;
            left: 41px;
            cursor: pointer;
        }
    }
    .search-accordion .search-accordion-item-body {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        background: rgba(230, 230, 230, 0.3);
        border-width: 0px;
        .nr-card, .routine-card  {
            margin:0px;
            border-radius: 0px;
        }
        .search-accordion-item-body-content {
            padding:0px;
            .nr-card {
                margin:0px;
                border-radius: 0px;
            }
        }
        .action-items-container {
            width:17rem;
            text-align: left;
        }
        
    }
    .search-accordion-item-body > div:last-child {
        margin-bottom: 67px !important;
    }
    .actions-container:last-child {
        top:55px;
        left: -50px;
    }
    .routine-card .card-details .slp-title, .routine-card .card-details .dash8-number {
        height:auto !important;
        color:#FFFFFF;
    }
    .routine-card{
        &.dash1-card{
            .dash1-card-details-holder{
                .card-details{
                    max-width: 20%!important;
                    + .card-info.dash8-number{
                        margin-right: 20px!important;
                    }
                }
            }
        }
    }
    .view-by-tech{
        .search-accordion-item-header{
            height: 50px;
            .accordion-title{
                padding: 0 10px;
                font-size: 16px;
                text-transform: capitalize;
            }
        }
    }
}

.crew-workload-container, .unassigned-work-container{
    .routine-card.dash1-card {
        position: relative;
        &:last-child{
            height: 135px!important;
        }
    }
}


.customCheckbox input.active-user:checked ~ .checkmark {
    background-color: green!important;
}