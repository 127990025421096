﻿@import'../utilities/_variables.scss';

html{
    font-size: $font-size-main;
}

body {
    font-family: $font-family-standard;    
    font-size: $font-size-normal;
    color:$black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $font-weight-light;
    font-size: $font-size-normal;
    clear: both;
    color: $content-color;
    line-height: 1;
    position: relative;
}

h1 {
    margin: 2rem 0 1.5rem;
    font-size: 4rem;
    line-height:5.6rem;
    color:$united-blue;
}

h2 {
    font-size: 3.2rem;
    line-height:4rem;
}
h3 {
    font-size: $font-size-fs24;
    font-weight: bold;
}

h4 {
    font-size: $font-size-large;
}

a {
    color: $link-color;
    text-decoration: none;

    &:active {
        color: $black;
        text-decoration: underline;
    }
}