@import "./NonRoutineFilters/nonroutinefilters";

.nonroutines-tab-container {
  display: flex;
  .nonroutines-data-container {
    //height: calc(100vh - 225px);
    height: calc(100vh - 245px);
    overflow-y: auto;
    padding-right: 15px;
    a {
      color: #333333;
    }
  }
}

.nr-create-link {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 37%;
  margin-top: 12px;
  border-left: 1px solid #666666;
  padding-left: 30px;
  color: $united-purple;
}

.nr-card {
  //display: flex;
  position: relative;
  flex-wrap: wrap;
  padding: 0px 20px 20px 20px;
  border: 1px solid $mercury-gray;
  //box-shadow: 1px 1px rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  margin: 1rem 0;
  background-color: #ffffff;

  &-info {
    margin-right: 60px;
    > div {
      padding-top: 20px;
    }
    //width: calc(100% - 30px);
    //width: 
  }
  &-description {
    min-width: 440px;
    //max-width: calc(100% - 545px);
    flex-grow: 10;
    color: $night-rider-gray;
  }
  &-number {
    font-size: $font-size-fs24;
    font-weight: $font-weight-bold;
    padding-right: 20px;
  }
  &-nrstatus {
    min-width: 150px;
    max-width: 150px;
    margin: auto 1px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: $font-size-main;
  }
  &-status {
    font-size: 1.3rem;
    font-weight: $font-weight-bold;
    color: #41748d;
    border: 2px solid #41748d;
    border-radius: 32px;
    height: 2.7rem;
    width: 90px;
    align-self: center;
    text-align: center;
    line-height: 2.4rem;
    margin-right: 25px;
  }
  &-od {
    font-size: $font-size-fs20;
    font-weight: $font-weight-semi-bold;
    align-content: center;
    align-self: center;
    line-height: 24px;
  }
  &-discrepency {
    font-size: $font-size-normal;

    & div {
      font-weight: $font-weight-bold;
      & span {
        overflow-wrap: break-word;
      }
    }
  }
  &-dates-info {
    display: flex;
    .closed {
      border-left: 2px solid #e6e6e6;
      padding-left: 1rem;
    }
    & div {
      font-weight: $font-weight-bold;
      padding: 0 1rem;
    }
  }
  &-icons {
    min-width: 140px;
    max-width: 140px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: $font-size-main;
    & svg {
      width: 20px;
      height: 22px;
    }
    .icon-holder {
      height: 40px;
      width: 20px;
      color: $night-rider-gray;
      &.ws-display{
        display: flex;
        gap: 1rem;
      }
      .insp-icon {
        width: 24px;
        height: 24px;
      }
      .blocked-icon {
        width: 20px;
        height: 22px;
      }
      .notes{
        position: relative;
        .notes-icon {
          cursor: pointer;
        }
        .notes-count{
          height: 16px;
          width: 16px;
          background-color: #D50032;
          color: #fff;
          font-size: 8px;
          font-weight: bold;
          border-radius: 50%;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: -8px;
          right: -5px;      
        }
      }
    }
  }
  &-skills-zones-container {
    min-width: 260px;
    max-width: 260px;
    color: $night-rider-gray;
  }
  &-skills-subtasks-container {
    min-width: 170px;
    max-width: 170px;
    color: $night-rider-gray;
  }
  &-skills-zones {
    border-left: 2px solid #e6e6e6;
    height: 100%;
    padding-left: 15px;
    display: flex;
    font-size: $font-size-normal;
    &-info {
      align-self: center;
      .lbl {
        width: 40px;
        margin-right: 3rem;
      }
      .value {
        font-weight: $font-weight-bold;
        &.skill-content{
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }
      
    }
  }
  &-subtasks {
    min-width: 200px;
    max-width: 200px;
    &-info {
      border-left: 2px solid #e6e6e6;
      height: 100%;
      padding-left: 20px;
    }
  }
  &-chevron {
    position: absolute;
    right: 0px;
    top: 0px;
    min-width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
  }
  &-assign-actions {
    position: absolute;
    right: 60px;
    top: -24px;
    min-width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 25px;
    &-assign-holder {
      height: 40px;
      width: 20px;
    }
  }
}

// .nr-card:hover {
//   border: 2px solid #683dc2 !important;
// }
.common-pill {
  display: inline-block;
  font-weight: 600;
  font-size: 1.4rem;
  border-radius: 3rem;
  display: inline-flex;
  align-items: center;
  margin: 0 1rem 1rem 0;
  height: 3.2rem;
}
.clear-pill-item {
  background-color: #6244bb;
  color: #ffffff;
  padding: 0 1.5rem 0 1.5rem;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    background-color: #949494;
  }
}
.pill-item {
  color: #6244bb;
  border: 1.5px solid #6244bb;
  padding: 0 0.4rem 0 1rem;
  &.disabled {
    cursor: default;
    background-color: rgba(182, 184, 220, 0.5);
  }
  &.uppercase {
    text-transform: uppercase;
  }
}
.pill-item > .button {
  color: #6244bb;
  background-color: #ffffff;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 1rem;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-pills {
  flex: 1;
  overflow: hidden;
}
.nonroutine-show-more {
  margin-top: 15px;
  margin-right: 15px;
  float: right;
}

.skeleton {
  img,
  span,
  label,
  .card-desc,
  svg,
  button.btn-secondary,
  button.btn-primary,
  input,
  li,
  h3,
  h4,
  table,
  th,
  td,
  .materials-table {
    background: linear-gradient(325deg, #e6e6e6, #d3d3d3);
    background-size: 400% 400%;

    -webkit-animation: loaddata 2s ease infinite;
    -moz-animation: loaddata 2s ease infinite;
    animation: loaddata 2s ease infinite;
    color: transparent !important;
    line-height: initial;
    border-color: #e6e6e6;
    animation: loaddata 2s infinite;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  svg,
  img {
    display: none;
  }
  label::after {
    background: linear-gradient(325deg, #e6e6e6, #d3d3d3);
    background-size: 400% 400%;

    -webkit-animation: loaddata 2s ease infinite;
    -moz-animation: loaddata 2s ease infinite;
    animation: loaddata 2s ease infinite;
    color: transparent;
    border-color: #e6e6e6;
    animation: loaddata 2s infinite;
  }
  span::before {
    background: linear-gradient(325deg, #e6e6e6, #d3d3d3);
    background-size: 400% 400%;

    -webkit-animation: loaddata 2s ease infinite;
    -moz-animation: loaddata 2s ease infinite;
    animation: loaddata 2s ease infinite;
    color: transparent;
    border-color: #e6e6e6;
    animation: loaddata 2s infinite;
  }
  input::placeholder{
    opacity: 0;
  }
  .card-desc-holder {
    background-color: transparent;
  }
  .nr-accordion-header .title,
  .notes-container .notes-header .title {
    color: transparent;
  }
}
.not-valid {
  border: 1px solid red;
}
.linked-nr {
  .header {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 1rem;
  }
  .linked-nr-header {
    label {
      font-size: 1.2rem;
    }
    .value {
      font-size: 1.8rem;
      display: block;
      width: max-content;
    }
    .task-card-details {
      font-weight: bold;
    }
  }
}

// Inside this, we have to set the action container display : none
.assign-modal-action-button{
  .actions-container{
    display: none;
  }
}

.assign-technitian-modal{
  max-height: 80vh;
  .modal-content-body {
    overflow-y: auto;
  }
  .technicians-container{
    width: 100%;
    padding:0 20px;
    .technicians-list{
      .dd-list-item{
        padding: 0;
      }
    }
  }
}