.nr-addNotes-modal {
  min-height: 35rem;
  max-width: 60rem;
  margin: 4rem;
  padding: 0;
  .modal-content-header {
      border-radius: 6px 6px 0 0;
      background-color: #0c2340;
      align-items: center;
      height: 5rem;
      padding: 1rem;
      h3 {
          color: $white;
          padding: 0;
          font-weight: 400;
          margin-left: 1rem;
      }
  }
  .modal-content-body {
      padding: 2rem;
      padding-top: 0.5rem;
      .input-signoff-modal {
          display: flex;
          flex-direction: column;
          & textarea {
              max-width: 50rem;
              height: 17rem;
          }
      }
  }
}

.notes-container {
  padding-left: 0;
  max-width: 600px;
  .notes-header {
    border: 1px solid #0c2340;
    border-radius: 6px 6px 0 0;
    background-color: #0c2340;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    height: 50px;
    .link {
      color: $white;
    }
    .title {
      font-size: 2rem;
      font-weight: 600;
      color: $white;
    }
  }
  .note-item {
    border: 1px solid #cccccc;
    padding: 1rem;
    .note-text {
      font-weight: bold;
      overflow-wrap: break-word;
    }
    .contact-info{
      padding-top: 1rem;
    }
  }
}

// View non-routine style
.view-nonroutine-notes{
  display: flex;
  justify-content: space-between;
  > div{
    flex-grow: 1;
    flex: 1;
  }
  .contact-info{
    padding-top: 0!important;
    display: flex!important;
    justify-content: flex-end!important;
    flex-direction: column;
  }
}