.assign-crew-header {
    background-color: rgba(12, 35, 64, 0.1);
    margin: 0 -15px;
    padding: 15px 35px 0px 35px;
    display: flex;

    .assign-crew-header-title {
        font-size: 20px;
        font-weight: 600;
        align-items: flex-end;
    }
}

.assign-crew {
    background-color: rgba(12, 35, 64, 0.1);
    margin: 0 -15px;
    padding: 35px;
    display: flex;
    overflow-x: auto;
    min-height: calc(100vh - 223px);

    @media screen and (max-width: 1920px) {
        padding: 0;

        .available-crew {
            margin: 1rem;
        }

        .crew-workload {
            margin: 1rem;
            width: calc(100vw - 43rem);
        }
    }


}

.available-crew {
    width: 369px;
    min-width: 369px;
    max-width: 369px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    color: #333333;
    font-size: 14px;
    border-radius: 6px;
    position: relative;

    .tabs {
        overflow: auto;
        margin-bottom: 10px;

        .tab-list {
            display: flex;
            margin-bottom: 0px;

            .tab-list-item {
                display: flex !important;
                height: 60px;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .toggle-all {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5px;
        width: 50px;
        min-width: 30px;
        min-height: 30px;
        z-index: 9999;

    }

    .toggle-title {
        font-size: 1.4rem;
        color: #333333;
        padding-right: 1rem;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 2.8rem;
        height: 2.1rem;
        margin-bottom: 0px;
    }

    .switch input {
        width: 2.6rem;
        height: 1.8rem;
    }

    .chevron-right-icon {
        cursor: pointer;
        height: 1.5rem;
        width: 1.5rem;
        z-index: 2;
    }

    .loading,
    .no-data {
        // height: 200px;
        // min-height: 200px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }

    .group-header {
        display: flex;
        width: 100%;
        align-items: center;
        // padding-top: 10px;
        padding-left: 15px;
        justify-content: space-between;

        &.workbins-content {
            width: 95%;
            padding-left: 10px;
            position: relative;

            .toggle-all {
                // width: 100%;
                position: absolute;
                top: 0;
            }

            .work-bin-filters {
                margin-top: 5px;
            }

            .crew-workbin-filter-container.active {
                width: 100%;
            }
        }

        // position: relative;

        // margin-top: 10px;
        .toggle-all {
            justify-content: flex-start;
            flex-direction: row;
            width: auto;
            z-index: 9999;
        }
    }

    .work-bin-filters {
        position: sticky;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        width: 100%;

        .crew-workbin-filter-container {
            // top: 5px;
            // width: 95%;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;

            // position:unset;
            &.active {
                top: 55px;
            }

            svg {
                top: 10px;
            }

            .filter-contents {
                top: 40px;
                position: absolute;
                left: 10px;
                width: 90%;

                .filter-accord {
                    position: relative;
                }
            }
        }
    }

    .work-bin-content {
        min-height: calc(100vh - 415px);
        max-height: calc(100vh - 415px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 13px;
        margin-top: 13px;

        &::-webkit-scrollbar {
            width: 13px;
        }

        &::-webkit-scrollbar-thumb {
            background: grey;
        }

        &::-webkit-scrollbar-track {
            background: #85838338;
        }

        .selected-bin {
            // border-left-color: #0C2340;
            // background-color: rgba(105, 179, 231, 0.2);
            border: 3px solid #6244bb !important;
            position: relative;
            border-radius: 6px;

            &:after {
                width: 20px;
                height: 20px;
                content: " ";
                right: -11px;
                position: absolute;
                border-top: 3px solid #6244bb;
                border-right: 3px solid #6244bb;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                background-color: #ffffff;
            }
        }
    }

    &-header {
        background-color: #002244;
        color: #ffffff;
        height: 52px;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 20px;
            font-weight: $font-weight-semi-bold;
            margin-left: 1.5rem;
        }

        .crew-refresh-icon {
            margin-right: 1.5rem;
        }
    }

    &-subheader {
        border-bottom: 1px solid #CCCCCC;
        height: auto;
        min-height: 95px;
        display: flex;
        padding: 0 6px 6px 15px;
        font-size: 14px;
        // justify-content: space-between;
        align-items: end;
        flex-direction: column;
        position: relative;

        .goto-current-day {
            cursor: pointer;
            font-size: 16px;
            color: #6244BB;
        }

        .date {
            font-size: 16px;
            color: #333333;
        }

        &.align-center {
            align-items: center !important;
        }

        .group-header {
            display: flex;
            width: 100%;
            padding-left: 0;

            .toggle-all {
                width: 80%;
                flex-direction: row;
                justify-content: flex-start;
                z-index: 9999;
            }

            .crew-workbin-filter-container {
                // position: absolute;
                width: 96%;
                padding: 0;

                &.active {
                    top: 55px;
                }

                // top: 10px;
                svg {
                    right: 0;
                }

                .filter-contents {
                    position: absolute;
                    width: 90%;
                    top: 100%;

                    .clear-all {
                        .btn {
                            padding: 0;
                            min-height: 0;
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }

    &-content {
        display: flex;
        min-height: calc(100vh - 465px);
        max-height: calc(100vh - 465px);
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 13px;
        }

        &::-webkit-scrollbar-thumb {
            background: grey;
        }

        &::-webkit-scrollbar-track {
            background: #85838338;
        }

        &-groups {
            width: 359px;
            min-width: 359px;

            .group-header {
                font-size: 14px;
                display: flex;
                align-items: center;
                height: 63px;
                padding-left: 15px;
                border-bottom: 1px solid #cccccc;

                .crew-title {
                    width: 130px;
                    min-width: 130px;
                }

                .shift-title {
                    width: 150px;
                    min-width: 150px;
                }
            }

            .avaialble-crew-card {
                border-bottom: 1px solid #cccccc;
                font-size: 16px;
                padding: 15px;
                display: flex;

                .tile-link,
                .tile-link:hover {
                    font-size: 18px;
                    color: $united-purple;
                    font-weight: $font-weight-semi-bold;

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }

                .info-icon {
                    width: 35px !important;
                    height: 35px !important;
                    cursor: pointer;
                }

                .avaialble-crew-schedule {
                    width: 260px;
                    min-width: 260px;

                    .avaialble-crew-content {
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;

                        div {
                            width: 50%;
                        }

                        div.crew-count {
                            width: 60px;
                            min-width: 60px;
                        }

                        div.crew-info {
                            width: 210px;
                            min-width: 210px;
                        }

                        span {
                            padding-right: 4px;
                        }

                        .schedule-info {
                            font-weight: $font-weight-bold;
                        }

                        .crew-info {
                            font-size: 16px;
                            color: #333333;
                            padding: 0px 18px 12px 18px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 200px;

                            span {
                                position: relative;
                                top: 1px;
                            }
                        }

                        .shift-grave-icon,
                        .shift-day-icon,
                        .shift-swing-icon {
                            width: 20px;
                            height: 20px;
                        }

                        .manicon {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }

    &-footer {
        height: 52px;
        box-sizing: border-box;
        border: 1px solid #FFFFFF;
        border-radius: 0 0 6px 6px;
        background-color: #FFFFFF;
        box-shadow: 0 -3px 4px 0 rgba(230, 230, 230, 0.5);
        align-items: center;

        &.w-100 {
            width: 100% !important;
            justify-content: space-between;
        }

        .prev-day {
            cursor: pointer;
            padding-left: 15px;
            color: #6244BB;

            svg {
                transform: rotate(180deg);
                width: 15px;
                height: 15px;
            }
        }

        .next-day {
            cursor: pointer;
            color: #6244BB;
            margin-right: 15px;
            // margin-left: auto;
        }

        .disabled {
            cursor: not-allowed;
            pointer-events: none;
            color: #949494;

            svg {
                path {
                    fill: #949494;
                }
            }
        }
    }
}

.crew-workload {
    margin-left: 30px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    color: #333333;
    font-size: 14px;
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;

    &-header {
        background-color: #002244;
        color: #ffffff;
        height: 52px;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 20px;
            font-weight: $font-weight-semi-bold;
            margin-left: 1.5rem;
        }

        .expand-btn {
            height: 100%;
            background-color: transparent;
            color: white;
            font-size: 1.5rem;
            font-weight: 600;
            border: none;
            margin-right: 1.5rem;
            margin-top: 1.7rem;
        }

        button {
            border: none;
        }
    }

    &-content {
        position: relative;
        height: auto;
        display: flex;
        flex-direction: column;

        .filter-workbin-workload {
            >div {
                padding: 10px;
                justify-content: center;
                align-items: center;
                display: flex;
                width: 350px;

                label {
                    width: auto;
                    padding-right: 3px;
                }
            }
        }

        &-search {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            right: 0;
            background-color: white;
            height: 0px;
            overflow: hidden;
            z-index: 1;

            &.expanded {
                height: auto;
                overflow: inherit;
                padding-bottom: 9rem; //added 

                @media screen and (min-width: 1920px) {
                    width: 100%;
                }
            }

            &>div {
                max-width: 275px;
                min-width: 275px;
                padding-left: 25px;
                padding-top: 20px;
                align-self: center;
                padding-bottom: 10px;
            }

            .btn-group {
                padding-top: 40px;
            }

            .title-placeholder {
                &>svg {
                    padding: 0 5px;
                }
            }

            .list-item {
                height: 34px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &>svg {
                    padding: 0 5px;
                }
            }
        }

        .ddlSmall {
            width: 200px;
        }

        .cellwidth {
            max-width: 275px;
            min-width: 275px;
        }

        .workload-search-fields {
            align-self: center;
            padding-bottom: 10px;
            font-size: 1.4rem;

            //padding-top:0px;
            .select-box--container {
                background-color: #FFFFFF;
                color: #333333;
            }

            .options-container {
                margin-left: 0px;
                width: 200px;
            }
        }

        .input-field {
            font-size: $font-size-normal;

            input {
                width: 250px;
                height: 45px;
                border: 1px solid #333333;
                border-radius: 6px;
                font-size: $font-size-normal;
            }
        }

        .select-box--container {
            .input-field {
                input {
                    width: 200px;
                }
            }
        }

        .button-container {
            position: absolute;
            bottom: -15px;
            right: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin-bottom: 2rem;
            max-width: 100%;
            width: 100%;

            .mx-dropdown {
                margin-left: 20px;
            }
        }

        .btn-search {
            width: 120px;
            font-size: 14px;
            min-height: 34px;
        }

        .type-select-container {
            height: 60px;
            min-height: 60px;
            border-top: 1px solid #CCCCCC;
            align-items: center;
            padding: .5rem 0;

            .type-ddl {
                align-items: center;
                position: absolute;
                right: 0;

                div {
                    margin-right: 1.5rem;
                }
            }
        }

        .work-bins-container {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .dd-wrapper {
        .dd-list {
            max-height: 350px;
            overflow-x: hidden;
            overflow-y: auto;
            width: fit-content;
        }
    }
}

.body-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .scroll-indicator {
        position: absolute;
        bottom: 0px;
        padding: 0 15px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: auto;
        background-color: #dddddd;
    }
}

.popover {
    background: #ffffff;
    border-radius: 4px;
    width: 700px;
    min-width: 700px;
    max-width: 700px;
    padding: 15px 15px 15px 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    min-height: calc(100vh - 270px);
    max-height: calc(100vh - 270px);
    overflow-y: auto;
    overflow-x: hidden;

    .popover-header {
        height: 30px;
        background-color: white;
        border: none;
        cursor: pointer;
    }

    .description,
    .workload-title {
        font-size: 20px;
        font-weight: 600;
        padding: 15px 0;
    }

    .crew-technicians,
    .crew-leads {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .content-holder {
        font-size: 1.6rem;
        display: flex;

        .content-label {
            display: flex;
            flex-direction: row;
            text-align: right;
            width: 140px;
            line-height: 26px;
            margin-bottom: 10px;
            padding-right: 35px;
        }

        .content-label-lead {
            display: flex;
            flex-direction: row;
            text-align: right;
            width: 140px;
            line-height: 26px;
            margin-bottom: 10px;
            padding-right: 66px;
        }

        .content-value {
            color: #333333;
            font-weight: 600;
            flex: 0 0 200px;
            margin-left: 10px;
            width: 200px;
            min-width: 200px;
            max-width: 200px;
        }
    }

    .assigned-workload {
        .empty-label {
            width: 50px;
        }

        .taskcard-label {
            font-size: 14px;
            width: 150px;
            line-height: 26px;
            margin-bottom: 10px;
        }

        .taskcard-desc-label {
            font-size: 14px;
            width: 250px;
            line-height: 26px;
            margin-bottom: 10px;
        }

        .taskcard-value {
            color: #333333;
            font-size: 14px;
            font-weight: 600;
            width: 150px;
            min-width: 150px;
            max-width: 150px;
            margin: 15px 0;
        }

        .taskcard-desc-value {
            color: #333333;
            font-size: 14px;
            font-weight: 600;
            // width: 250px;
            // min-width: 250px;
            // max-width: 250px;
            margin: 15px 0;
        }

        .toggle-all {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 5px;
            width: 50px;
            min-width: 50px;
        }

        .toggle-title {
            font-size: 1.4rem;
            color: #333333;
            padding-right: 1rem;
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 2.8rem;
            height: 2.1rem;
            margin-bottom: 0px;
        }

        .switch input {
            width: 2.6rem;
            height: 1.8rem;
        }
    }
}

.progress-col{
    .dash1-progress-bar {
        align-items: center;
    }

    .dash1-progress-bar:before {
        /* create a full-height inline block pseudo-element */
        content: " ";
        display: inline-block;
        vertical-align: middle;
        /* vertical alignment of the inline element */
        height: 100%;
    }

    .progress-bar-holder {
        display: inline-block;
    }

    .partial-indicator {
        position: relative;
        left: 0px;
        top: -3px;
        align-items: center;
        text-align: center;
        background-color: $united-purple;
        color: #ffffff;
        font-size: 12px;
        width: 100px;
    }
}
.popover-container {
    width: 200px;
    margin: 50px auto;
    position: absolute;
    left: 368px;
    top: -50px;
}

.assign-crew-workload-header {
    font-size: 16px;
    min-height: 70px;
}

.assign-crew-workload-body {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-weight: $font-weight-semi-bold;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .routine-card {
        .nr-card-assign-actions {
            top: auto !important;
            margin-top: 0 !important;
            height: auto !important;
        }
    }
}
.notes-content{
    background-color: rgb(234, 234, 243);
    padding : 3px 20px;
}
.assign-crew-workload {
    font-size: 16px;
    display: flex;
    padding: 0px 20px;
    min-height: 70px !important;
    border-bottom: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
    align-items: center;

    &>div {
        margin-right: 20px;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .arrow-down-icon {
        top: 5px;
        position: relative;
    }

    .arrow-up-icon {
        transform: rotate(180deg);
        height: 24px;
        top: -5px;
        position: relative;
    }

    .header {
        cursor: pointer;
        user-select: none;
    }

    .shift-display {
        &>span:first-child {
            padding-right: 10px;
        }

        &>span:last-child {
            padding-left: 10px;
        }

        svg {
            height: 16px;
            width: 16px;
        }
    }

    .notes-icon {
        width: 22px;
        height: 22px;
        cursor: pointer;
    }

    .notes .notes-count {
        width: 13px;
        height: 13px;
        right: 11px;
        top: 0px;
        cursor: pointer;
    }

    .dependency-indicator{
        width: 20px;
        height: 75px;
        background-color:#D50032;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        // padding-left: 0px !important
        position: absolute;
        left: 0;
    }

    .number {
        .task-card-section {
            &>span {
                a:first-child {
                    margin-right: 8px;
                }
            }
        }

        .subitems-container {
            display: flex;
            align-items: center;

            .sub-item {
                font-size: 14px;
            }
        }
    }

    input[type="checkbox"] {
        width: 2.6rem;
        height: 1.8rem;
    }

    @media screen and (max-width: 1920px) {
        .checkbox {
            width: 30px;
        }

        .number {
            width: 155px;
        }

        .description {
            width: 349px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: break-spaces;
        }

        .shift-col {
            width: 100px;
            &.ac-milestone{
                width: 150px;
            }
        }

        .work-area {
            width: 50px;
        }

        .milestone {
            width: 110px;
        }

        .assigned-crew {
            width: 110px;
        }

        .progress-col {
            width: 100px;
            margin-right: 10px;

            .outer-shell {
                width: 100px;
            }         
        }

        .notes {
            width: 40px;
            margin-right: 0px;
        }

        .workstop {
            width: 40px;
            margin-right: 0px;
            text-align: center;

            .stop-container {
                font-size: 10px;
            }
        }

        .ac-icons {
            width: 40px;
            margin-right: 0px;   
            &.full-width{
                position: relative!important;
                width: 29%!important;
                height:30px;
                .more-details-icon{
                    position: absolute;
                    right: 10px;
                }
            }         
            svg {
                width: 22px;
                height: 22px;
            }

            .more-details-icon {
                transform: rotate(90deg);
                position: relative;
                top: 10px;

                .expanded {
                    transform: rotate(180deg);
                }
            }
        }

        .skills {
            width: 50px;
        }

        .quick-actions {
            margin-right: 0px;
            .actions-menu {
                width: 1rem;
            }
            .actions-container {
                .action-items-container {
                    right: -15px;
                }
            }
        }
    }

    @media screen and (min-width: 1921px) {
        .checkbox {
            min-width: 30px;
            max-width: 30px;
            width: 2.4%;
        }

        .number {
            min-width: 155px;
            max-width: 155px;
            width: 11.2%;
        }

        .description {
            min-width: 350px;
            max-width: 350px;
            width: 33.4%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: break-spaces;
        }

        .shift-col {
            min-width: 100px;
            max-width: 100px;
            width: 8.2%;
            &.ac-milestone{
                max-width: 150px;
            }
        }

        .work-area {
            min-width: 50px;
            max-width: 540px;
            width: 4%;
        }

        .milestone {
            min-width: 110px;
            max-width: 110px;
            width: 10.4%;
        }

        .assigned-crew {
            min-width: 110px;
            max-width: 110px;
            width: 14.5%;
        }

        .progress-col {
            min-width: 100px;
            max-width: 100px;
            width: 12%;
            margin-right: 10px;

            .outer-shell {
                width: 100px;
            }           
        }

        .notes {
            min-width: 40px;
            max-width: 40px;
            width: 2.4%;
            margin-right: 0px;
        }

        .skills {
            min-width: 85px;
            max-width: 85px;
            width: 5%;
        }

        .workstop {
            width: 40px;
            margin-right: 0px;
            text-align: center;

            .stop-container {
                font-size: 10px;
            }
        }

        .ac-icons {
            width: 40px;
            margin-right: 0px;
            &.full-width{
                position: relative!important;
                width: 29%!important;
                height:30px;
                .more-details-icon{
                    position: absolute;
                    right: 10px;
                }
            }
            svg {
                width: 22px;
                height: 22px;
            }

            .more-details-icon {
                transform: rotate(90deg);
                position: relative;
                top: 10px;

                .expanded {
                    transform: rotate(180deg);
                }
            }
        }
        .quick-actions {
            margin-right: 0px;
            .actions-menu {
                width: 1rem;
            }
        }
    }

    .crew-refresh-icon {
        width: 22px;
        height: 22px;
        margin: auto;
        cursor: pointer;
    }
}

.work-bin-card {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 15px;
    border-bottom: 1px solid #CCCCCC;
    color: #333333;
    font-size: 14px;
    border-left: 3px solid #FFFFFF;

    &-content {
        min-width: 260px !important;
        padding-right: 5px;

        &>div {
            padding-bottom: 5px;
        }

        .work-bin-header {
            display: flex;

            .work-bin-title {
                font-size: 16px;
                font-weight: $font-weight-semi-bold;
            }

            .work-bin-icons {
                width: 60px;
                margin-left: auto;
                margin-right: 30px;
                display: flex;
                justify-content: space-between;

                svg {
                    cursor: pointer;
                    width: 20px;
                    height: 24px;
                }

                .delete-icon {
                    width: 18px;
                    height: 20px;
                }
            }
        }

        .work-bin-description {
            color: #000000;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .work-bin-counts {
            .counts {
                font-weight: $font-weight-bold;
                padding-left: 5px;
            }
        }
    }

    &-navigation {
        width: 20px;

        svg {
            cursor: pointer;
        }
    }
}

.available-crew-workbin-modal {
    max-width: 50vw;
    padding: 0;
    border: none !important;

    .modal-content-header {
        padding: 0 1rem;
        height: 50px;
        background-color: #0c2340;
        align-items: center;

        h3 {
            color: #fff;
        }

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(72deg) brightness(104%) contrast(104%);
            //margin: 13px 10px 0 0;
        }
    }

    .modal-content-body {
        height: 76vh;

        .nr-accordion-header {
            background: #fff;

            .title {
                color: grey;
            }

            img {
                filter: brightness(0) saturate(100%) invert(23%) sepia(69%) saturate(2172%) hue-rotate(241deg) brightness(95%) contrast(82%);
            }
        }

        .w-load-accordion {
            .accordion-item-content {
                overflow: hidden;
                height: 40vh;

                .assigned-workload-contents {
                    max-height: 37vh;
                    overflow-y: auto;
                }
            }
        }

        .crew-bin-accordion {
            max-height: 32vh;

            .accordion-item-content {
                height: 30vh;
            }
        }

        .accordion-item-content {
            display: block !important;
            overflow-y: scroll;

            .contents {
                padding-left: 2rem;
                padding-right: 2rem;

                .acc-title {
                    font-size: 1.9rem;
                    font-weight: 600;
                    color: grey;
                    margin-top: 7px;
                    margin-bottom: 14px;
                }

                .user-list {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    //margin-bottom: 10px;
                    //border: .5px solid lightgrey;
                    min-height: 36px;

                    //padding: 1rem;
                    .skills {
                        .user-list-label {
                            .skills-list {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }

            .hidden {
                display: none;
            }

            // Workload contents
            .toggle-all {
                margin-left: 2rem;
                margin-bottom: 10px;

                label {
                    padding: 0 !important;
                }
            }

            .assigned-workload-contents {
                padding: 2rem;

                .workload-item {
                    min-height: 60px;
                    border: 0.5px solid lightgrey;
                    padding: 1rem 1rem;
                    display: grid !important;
                    grid-template-columns: 5% 90%;
                    margin-bottom: 10px;
                    align-items: center;

                    .number {
                        width: auto !important;
                    }

                    .taskcard-value {
                        font-weight: bold;
                    }

                    .toggle-all {
                        margin: 0 !important;

                        .switch {
                            margin: 0;
                        }

                        .switch input[type="checkbox"] {
                            width: 2.6rem;
                            height: 1.8rem;

                        }
                    }

                    .wrkload-group-nr,
                    .task-card-section {
                        display: flex;
                        gap: 1.5rem;
                    }
                }
            }
        }
    }

    .modal-footer {
        border-top: 0px;
    }

    .unassign-btn {
        .subheader-buttons {
            margin-bottom: 20px;
        }
    }
}

.assign-crew-action-container {
    .active {
        color: #6244BB !important;
        border-color: #6244BB !important;
        background-color: #FFFFFF !important;
    }

    .btn-add-wl-workbin {
        width: 200px;
    }

    .btn-move-remove {
        width: 200px;
        border: 1px solid #666666;
        color: #666666;
        background-color: #E6E6E6;
        border-color: #E6E6E6;
        cursor: default;
        box-shadow: none;
    }

    .work-bins-workload-header {
        display: flex;
        align-items: center;
        width: 100%;

        .work-bin-title {
            display: none;
        }

        .workload-buttons {
            display: flex;
            margin-left: 5px !important;

            .btn {
                width: 200px;
                margin-right: 40px;
                border: 1px solid #666;
                color: #666;

                svg {
                    width: 18px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
        }
    }
}

//   Filters
.crew-workbin-filter-container {
    // padding: 0 2rem;
    // position: relative;
    // position: absolute;
    top: 0;
    width: 100%;

    // padding: 5px;
    .filter-header-btn {
        display: flex;
        align-items: center;

        .clear-all {
            min-height: 30px;

            .btn {
                padding: 0 !important;
                margin: 0;
                justify-content: flex-start !important;
                text-align: start;
                display: none;
                min-height: 25px;
                line-height: 1.5;

                &.active {
                    display: flex;
                }
            }
        }

        span {
            font-weight: 700;
            font-size: 1.6rem;
            margin-left: auto;
            margin-right: 20px;
        }
    }

    // svg{
    //     // position: absolute;
    // }
    .filter-contents {
        display: none;
        // background: #fff;
        // height: 55vh;
        position: relative;

        &.active {
            display: block;
        }

        .clear-all {
            .btn {
                padding: 0;
                min-height: 0;
                justify-content: flex-end;
            }
        }

        .action-btn {
            position: absolute;
            // bottom: 0;
            right: 0;
            display: flex;

            button {
                width: auto;
                min-width: auto;
            }
        }

        // Accordion style
        .filter-accord {
            .header {
                margin: 15px 0 15px 0;
                display: flex;
                justify-content: space-between;

                .title {
                    font-size: 18px;
                    font-weight: bold;
                }
            }

            .accod-body {
                height: 0px;
                transition-delay: .5s;
                overflow: hidden;

                .options {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    label {
                        margin-bottom: 0;
                    }

                    input[type="checkbox"],
                    input[type="radio"] {
                        width: 2.6rem;
                        height: 1.8rem;
                        margin-right: 12px;
                    }

                    .shift-skill-font {
                        font-size: 1.4rem;
                    }
                }

                &.active {
                    transition-delay: .5s;
                    height: 100%;
                }
            }
        }
    }
}

.more-details-container {
    display: flex;
    font-size: 16px;
    color: #333333;
    padding: 20px 45px;

    .info-pair {
        display: flex;

        div {
            color: rgba(51, 51, 51, 0.8);
        }

        .last-signoff-user {
            font-size: 13px;
        }
    }

    .details-1 {
        width: 45%;

        .info-pair {
            label {
                min-width: 120px;
                width: 120px;
                padding-right: 20px;
            }
        }

    }

    .details-2 {
        width: 40%;

        .info-pair {
            label {
                min-width: 200px;
                width: 200px;
                padding-right: 20px;
            }
        }
    }

    .details-3 {
        width: 30%;
        height: max-content;

        .info-pair {
            justify-content: flex-end;
            align-items: center;
            height: max-content;
            flex: 1;

            label {
                padding-right: 20px;
                margin: 0;
            }

            .cardDetailBadge {
                height: max-content;
                background: none !important;
                display: flex;
                align-items: center;
                color: #111;
                border: 1px solid #6C756E !important;
            }
        }
    }
}

.dependency-cards-container {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 20px 45px;
    .dependency-card{
        display: flex;
        gap: 1rem;
        .task-card-section{
            display: flex;
            gap: 1rem;
        }
    }
    .checkbox{
        display: flex;
        gap: 1rem;
        margin-bottom: 10px;
        input{
            width:18px;
            height:18px;
        }
    }
}