.work-bins-container{
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    border-top: 2px solid #CCCCCC;
    height: calc(100vh - 222px);
    .work-bins-section {
        width: 375px;
        border-right: 1px solid #CCCCCC;  
        &-header {
            height: 50px;
            display:none // emable when implementing filters
        }
        &-footer {
            height: 50px;
            color: $united-purple;
            display: flex;
            align-items: center;
            padding-left: 18px;
            border-top: 1px solid #CCCCCC;
            font-weight: $font-weight-semi-bold;
            span {
                cursor: pointer;
            }
        }
        .work-bins {  
            //height: calc(100vh - 280px); 
            height: calc(100vh - 270px); 
            overflow-y: auto;
       
        .selected-bin {
            border-left-color: #0C2340;
            background-color: rgba(105, 179, 231, 0.2);
        }
    }
}

    .work-bins-workload{
        &-header {
            display: flex;
            padding: 15px;
            border-bottom: 2px solid #CCCCCC;
            align-items: center;
            font-weight: $font-weight-semi-bold;
            .work-bin-title {
                font-size: 22px;
                color: #000000;
            }
            .workload-buttons {
                display:flex;
                svg {
                    margin-right: 10px;
                }
                .delete-icon {
                    width: 18px;
                    height: 20px;
                }
                .btn {
                    width: 200px;
                    margin-right: 40px;
                    border: 1px solid #666666;
                    color: #666666;
                    
                }
                .active {
                    color: $united-purple;
                    border-color: $united-purple;
                }
            }
        }

        &-body {
            padding : 15px;
            height: calc(100vh - 300px);
            overflow-y: auto;
            .template-taskcard {
                display: flex;
                position: relative;
                flex-wrap: wrap;
                padding: 0px 20px 10px 15px;
                border: 1px solid $mercury-gray;
                box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
                border-radius: 6px;
                margin: 1rem 0;
                background-color: #ffffff;
              
                &-info {
                  > .col {
                    padding-top: 20px;
                  }
                  width: calc(100% - 30px);
                }
                &-card{
                    min-width: 18rem;
                    div{
                        display: flex;
                        flex-direction: column;
                    }
                }
                &-description {
                  min-width: 440px;
                  flex-grow: 5;
                  color: $night-rider-gray;          
                  font-weight: $font-weight-bold;
                }    
                &-skills-zones-container, &-workarea-container {
                  min-width: 300px;
                  max-width: 300px;
                  color: $night-rider-gray;
                }
                &-skills-zones {
                  border-left: 2px solid #e6e6e6;
                  height: 100%;
                  padding-left: 20px;
                  display: flex;
                  font-size: $font-size-normal;
                  &-info {
                    align-self: center;
                    display: flex;
                    .lbl {
                      width: 40px;
                    }
                    .value {
                      font-weight: $font-weight-bold;
                    }
                  }
                }   
                &-workarea {
                    border-left: 2px solid #e6e6e6;
                    height: 100%;
                    padding-left: 20px;
                    display: flex;
                    font-size: $font-size-normal;
                    width: 430px;
                    &-info {
                        align-self: center;
                        width: 430px;
                        .lbl {
                        width: 120px;
                        }
                        .value {
                        font-weight: $font-weight-bold;
                        }
                    }
                }       
                &-chevron {
                  position: absolute;
                  right: 0px;
                  top: 0px;
                  min-width: 50px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  align-content: center;
                  cursor: pointer;
                }
                .details-icon {
                    width: 16px;
                    height: 18px;
                    cursor: pointer;
                    //margin-left: 10px;
                }
                .content-holder {
                    min-width: 24rem;
                    padding: 2px 10px 2px 0px;
                    font-size: $font-size-large;
                    label {
                        font-weight: $font-weight-semi-bold;
                        color: $cod-gray;
                    }
                    span {
                        color: $night-rider-gray;
                        margin-bottom: 15px;
                        word-break: break-all;
                    }
                }
              }
        }
        
    }
}
.work-bin-card {
    width: 100%;
    display:flex;
    align-items: center;
    padding: 15px 20px 15px 15px;
    border-bottom: 1px solid #CCCCCC; 
    color: #333333;
    font-size: 14px;   
    border-left: 3px solid #FFFFFF;        
    &-content {
        min-width: 320px;
        padding-right: 5px;
        & > div {
            padding-bottom: 5px;
        }
        .work-bin-header {
            display:flex;
            .work-bin-title {
                font-size: 16px;
                font-weight: $font-weight-semi-bold;
            }
            .work-bin-icons{
                width: 60px;
                margin-left:auto;
                margin-right: 30px;
                display: flex;
                justify-content: space-between;
                svg {
                    cursor: pointer;
                    width: 20px;
                    height: 24px;
                }
                .delete-icon {
                    width: 18px;
                    height: 20px;
                }
            }
        }
        .work-bin-description {
            color: #000000;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .work-bin-counts {
            .counts {
                font-weight: $font-weight-bold;
                padding-left: 5px;
            }
        }
    }
    &-navigation {
        width: 20px;
        svg {
            cursor: pointer;
        }
    }
}
.add-bin-modal {
    width: 765px;    
    .textarea-holder{
        label {
          display:block;
        }     
    }
    .buttons-holder {
        display: flex;
    }
}

.add-workload {
    background-color: rgba(12, 35, 64, 0.1);
    margin: 0 -15px;
    padding: 10px;
    display: flex;
    overflow-x: auto;
    height: calc(100vh - 220px);
    min-height: calc(100vh - 220px);
    max-height: calc(100vh - 220px);
}

.add-workload-workbins{
    height: calc(100vh - 290px);
    overflow-y: auto;
    .work-bin-card {
        .work-bin-card-content {
            min-width: 280px;
        }

    }
}
.workload-selector {
    width: 2.6rem;
    height: 1.8rem;
    margin-right: 25px;
}

.move-workload-modal, .delete-workload-modal {
    width: 765px;
    font-size: 16px;
    font-weight: $font-weight-semi-bold;
    color: #333333;
    max-height: 85vh;
    .sub-headers {
        font-size: 20px;
        font-weight: $font-weight-bold;
    }
    .description {
        font-weight: 400;
    }
    .buttons-container {
        display:flex;
        margin-top: 45px;
    }
    .work-bins-dropdown {
        width: 345px;
    }
    .workload-list {
        height: 45vh;
        overflow-y: auto;
    }
    .workbins-list {
        height: 10vh;
        overflow-y: auto;
    }
    .addScroll {
        max-height: 45vh;
        overflow-y: auto;
    }
    
}