.feedback-container {
    color: #333333;
    padding: 8px;

    .feedback-title {
        margin-bottom: 24px;

        p:first-child {
            font-size: 20px;
            margin-bottom: 5px;
        }

        p:last-child {
            font-size: 16px;
            margin-bottom: 5px;
        }
    }

    .feedback-ip {
        position: relative;
        margin-bottom: 24px;

        .category-dl {
            width: 33% !important;
        }

        .feedback-alert {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .textarea-holder {
            padding: 0;
        }

        textarea {
            max-width: 100%;
        }

        .feedback-textarea-commentlength {
            position: absolute;
            right: 3%;
            bottom: 0;
        }
    }

    .feedback-taskcard {
        .highlight {
            font-weight: 600;
        }
    }

    .feedback-taskcard {
        margin-bottom: 24px;
    }

    .feedback-category {
        margin-bottom: 24px;

        .workload-search-fields {
            min-width: 80%;

            input {
                width: 100%;
            }
        }
    }

    .uploaded-file-list {
        max-height: 150px;
        overflow-y: auto;
    }
    .add-border {
        border-bottom: #333333 solid 2px;
    }

    .feedback-attachments {
        margin-bottom: 24px;
        position: relative;

        .info-icon {
            margin: 8px;

            img {
                vertical-align: baseline;
                cursor: pointer;
            }
        }

        .tooltip-txt {
            position: absolute;
            top: -75px;
            left: 0;
            display: inline-block;
            border-radius: 4px;
            padding: 6px;
            color: #000;
            background-color: #fff;
            border: 0.2px solid lightgrey;

            &:before {
                content: "";
                left: 100px;
                position: absolute;
                bottom: -10px;
                width: 0;
                transform: rotate(0deg);
                border-top: 10px solid lightgrey;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }
        }

        .large {
            font-size: 16px;
            margin: 0;
        }

        .small {
            font-size: 12px;
        }

        .add-color {
            color: #CD202C;
        }

        .add-image {
            margin-bottom: 10px;
            display: inline-block;

            &:disabled {
                color: #eee;
            }
        }

        .add-image,
        .remove-file {
            color: #6244BB;
            cursor: pointer;
        }


        input[type='file'] {
            display: none;
        }

        .uploaded-file-list-item {
            display: flex;
            flex-direction: row;
            padding: 5px 0;

            p:first-child {
                flex: 40% 0 0;
                margin-bottom: 0;
            }

            span {
                margin-left: 5px;
            }

            .error-item {
                color: red;
                font-style: italic;
                font-size: 12px;
                align-self: center;
            }
            .remove-file, .uploading-file {
                margin-left: 11px;
            }
        }

        .remove-space {
            padding: 0px !important;
        }
    }

    .feedback-controls {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 10px;

        .spinner-container {
            top: 0;
            left: 0;
            height: 100%;
        }
    }

    .filepond--wrapper {
        .filepond {
            max-height: 40px;
            background-color: #eee;
        }

        .filepond--list-scroller,
        .filepond--panel,
        .filepond--drip {
            display: none;
        }
    }

    .filepond--drop-label.filepond--drop-label label {
        font-size: 16px;
    }

    .filepond--drop-label {
        background-color: #eee;
    }

    .filepond--credits {
        display: none;
    }
}