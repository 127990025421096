.CircularProgressbar-text {
    fill: #0C2340;
    font-size: 16px;
    font-family: Open Sans;
    font-weight: 600;
    text-anchor: middle;
}
.maintext {
    font-size: 24px;
}
.statusbar {
    margin-top: 30px;
    margin-left: 100px;
    text-align: -webkit-center;
}