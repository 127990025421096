
.addvisit.form-group.visit.row {
    margin-left: 0px;
    height: 120px;
  .col-xs-2 {
    width: 190px;
    height: 65px;
    display: grid;
    margin-right: 100px;
  }
  .col-xs-3 {
    width: 190px;
    height: 65px;
    display: grid;
    margin-right: 100px;
  }
  .col-xs-2 label {
    font-family: Open Sans;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
  }
  .col-xs-3 label {
    font-family: Open Sans;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
  }    
  .col-xs-3.hideShiftDiv {
      display: none;
  }
  .col-xs-2.hideZonalDiv {
    display: none;
}
  .addvisit-radiobutton {
    width: 50px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
  .addvisit-radiobutton > input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .addvisit-radiobutton > i { 
    display: inline-block;
    vertical-align: middle;
    width:  16px;
    height: 16px;
    border-radius: 50%;
    transition: 0.2s;
    box-shadow: inset 0 0 0 8px #fff;
    border: 2px solid #666666;
    background: gray;
  }
  .addvisit-radiobutton > input:checked + i {
    box-shadow: inset 0 0 0 1px #fff;
    background: #6244BB;
    border: 2px solid #6244BB;
  }
  .vsDisabled {
    // box-shadow: inset 0 0 0 0 #fff !important;
    background: #949494 !important;
    border: 2px solid #949494 !important;
    cursor:not-allowed; 
    }
  .vsLabelDisabled {
    color: #666666 !important;
  }
  .form-control {
      font-size: 14px;
  }
  .form-check-input {
      width: 16px;
      height: 16px;
      margin-right: 5px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border: 2px solid #666666;
    background-color: #6244BB;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #6244BB;
  }
  .form-check-label {
    width: 29px;
    height: 19px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  input, textarea {
    background-color: white;
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 10px;
}

input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.addvisit-input-group input[type='button'] {
  background-color:white;
  min-width: 50px;
  width: auto;
  transition: all 300ms ease;
}

.addvisit-input-group .button-minus {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #6244BB;
  font-weight: bold;
  height: 40px;
  padding: 0px;
  border: 1px solid #333333;
  width: 50px;
  position: relative;
}

.addvisit-input-group .button-plus {
    
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #6244BB;
  font-weight: bold;
  height: 40px;
  padding: 0px;
  border: 0px;
  border: 1px solid #333333;
  width: 50px;
  position: relative;
}

.addvisit-input-group .quantity-field {
  //position: relative;
  height: 40px;
  left: -6px;
  text-align: center;
  width: 50px;
  display: inline-block;
  font-size: 13px;
  padding: 2px 0 0 0;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-right: none;
  border-left: none;
  //margin: 0 0 5px;
  resize: vertical;
}

input[type="number"] {
  -moz-appearance: textfield !important;
  -webkit-appearance: none;
}
.shiftCalculator-text {
    font-family: Open Sans;
    font-style: italic;
    font-weight: 600;
    font-size: 10px;
    color: #000000;
    letter-spacing: 0;
    display: flex;
    height: 30px;
}

.shiftCalculator-text.hideText {
    display: none;
}

}