.shift-hand-over-report-modal {
    width: 40%;
    align-self: flex-start;
    margin-top: 1%;
}

.shr-test-hop-modal {
    .calendar-time {
        display: none;
    }
}

.historical-reports-modal {
    height: 60rem;

    .modal-content-body {
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 16px 24px;

        /* spinner */
        .spinner-container-holder {
            position: absolute;
            height: 100%;
            left: 0;

            .spinner-container {
                position: relative;
                background-color: transparent;
                min-height: 50px;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .modal-content-body-top {
            display: flex;
            align-items: center;

            .modal-content-form-section {
                display: flex;
                flex-direction: column;

                .date-range-labels {
                    display: flex;

                    .date-range-label {
                        font-size: 16px;
                        line-height: 32px;
                        color: #333333;
                        flex: 46% 0 0;
                    }
                }

                .date-range-controls {
                    flex-direction: row;
                    display: flex;
                    border: 1px solid #333333;
                    border-radius: 5px;

                    .date-range-input {
                        font-size: 16px;
                        padding: 12px;
                        color: #666666;
                        border: none;
                        border-right: 1px solid #333333;
                        border-radius: 0;
                        margin: 1px;
                        font-style: italic;
                    }

                    .date-range-icon {
                        padding: 10px;
                    }
                }
            }

            .modal-btn-container {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                align-self: flex-end;

                button {
                    width: max-content !important;
                    font-size: 16px;
                    line-height: 24px;

                    &:disabled {
                        border: 1px solid #666666;
                        border-radius: 4px;
                        background-color: #e6e6e6;
                        color: #666666;
                    }
                }
            }


            .date-range-show {
                .rdrMonthAndYearWrapper,
                .rdrMonths {
                    left: -60px;
                    top: 35px;
                }

                .rdrMonths {
                    top: 95px;
                }
            }
        }

        .divider {
            position: relative;
            margin-top: 24px;
            margin-bottom: 32px;
            border: 1px solid lightgray;
        }

        .modal-content-body-bottom {
            display: flex;
            flex-direction: column;

            .modal-content-body-bottom-title {
                p {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 20px;
                    margin-bottom: 24px;
                    color: #333333;
                }
            }

            .modal-content-body-bottom-list {
                display: flex;
                flex-direction: column;
                height: 294px;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #6244BB;
                    border-radius: 5px;
                    border: 1px solid #6244BB;
                }

                &::-webkit-scrollbar-track {
                    background: #85838338;
                }

                p {
                    border-bottom: 1px solid #6244BB;
                    font-size: 16px;
                    line-height: 24px;
                    color: #6244BB;
                    display: inline-block;
                    cursor: pointer;
                    font-weight: bold;
                    line-height: 18px;
                }

                .no-reports {
                    font-weight: bold;
                    font-style: italic;
                }
            }

            .alert-history-modal {
                margin-block: 1rem;
            }
        }
    }
}

.refresh-icon-outer {
    margin-left: auto;
    margin-right: 20px;

    &.disable-refresh {
        opacity: 0.3;
    }
}

.shift-hand-over-report {
    .shr-fullpage-spinner {
        .spinner-container {
            top: 0;
        }
    }

    .update-icon {
        cursor: pointer;
    }

    .no-data {
        font-weight: 600;
        text-align: center;
    }

    .shift-hand-over-report-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;

        button {
            width: max-content;
        }
    }

    .shift-hand-over-report-card-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: "Open Sans";
        color: #25282A;

        .chevron-right-icon {
            transform: rotate(90deg);
        }

        .shift-hand-over-report-card {
            p {
                font-size: 22px;
                line-height: 24px;
                margin-bottom: 16px;
            }

            flex: 32% 0 0;
            padding: 16px;
            background-color: #ffffff;
            box-shadow: 4px 4px 8px 2px rgba(0, 0, 0, 0.08);
            border: 1px solid rgba(204, 204, 204, 0.75);
            border-radius: 8px;
            border-top-width: 8px;
            border-top-color: #0C2340;
            min-height: 576px;

            .shift-hand-over-report-card-info-section {
                display: flex;
                flex-direction: column;
                padding: 16px 21px 14px 16px;
                margin-bottom: 16px;
                border: 1px solid rgba(204, 204, 204, 0.75);
                border-radius: 4px;
                background-color: #FFFFFF;
                box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
                box-sizing: border-box;
                height: 208px;

                .shift-hand-over-report-card-info-section-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 5px;

                    &.margin-bottom-5 {
                        margin-bottom: 5px;
                    }

                    .shift-hand-over-report-card-info-section-column {
                        flex: 50% 0 0;

                        &.base-50 {
                            flex: 50% 0 0;
                        }

                        &.base-100 {
                            flex: 100% 0 0;
                        }

                        &.base-32 {
                            flex: 32% 0 0;
                        }

                        &.base-45 {
                            flex: 45% 0 0;
                        }

                        &.separator {
                            flex-basis: 2px;
                            background-color: rgba(204, 204, 204, 0.5);
                        }

                        p,
                        span {
                            margin: 0;

                            &.coloredheader {
                                color: #0C2340;
                                font-size: 16px;
                                letter-spacing: 0;
                                line-height: 22px;
                                font-weight: 600;
                            }

                            &.total-remaining-card {
                                height: 34px;
                                width: 100px;
                                color: #666666;
                                font-family: "Open Sans";
                                font-size: 12px;
                                letter-spacing: 0;
                                line-height: 17px;
                                display: flex;
                                align-items: center;
                            }

                            &.total-scheduled-card {
                                height: 34px;
                                width: 110px;
                                color: #666666;
                                font-family: "Open Sans";
                                font-size: 12px;
                                letter-spacing: 0;
                                line-height: 17px;
                            }

                            &.total-routines-card {
                                height: 34px;
                                width: 93px;
                                color: #666666;
                                font-family: "Open Sans";
                                font-size: 12px;
                                letter-spacing: 0;
                                line-height: 17px;
                            }

                            &.small {
                                font-size: 12px;
                                color: #6a6a6a;
                                font-family: "Open Sans";
                                letter-spacing: 0;

                                &.lessheight {
                                    line-height: 17px;
                                    font-weight: 500;
                                }
                            }

                            &.large {
                                font-size: 32px;
                                color: #333333;

                                &.lessheight {
                                    line-height: 43px;
                                    letter-spacing: 0;
                                    font-weight: 700;

                                    .stats-link {
                                        cursor: pointer;
                                        color: #6244BB;
                                        text-decoration: underline;
                                    }
                                }
                            }

                            &.cdp {
                                color: #333333;
                                font-family: "Open Sans";
                                font-size: 28px;
                                letter-spacing: 0;
                                line-height: 38px;
                                font-weight: 700;
                            }

                            &.bold {
                                font-weight: 700;
                            }
                        }

                        .par-title-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: baseline;

                            .large {
                                color: #0C2340;
                                height: 43px;
                                width: 37px;
                            }

                            .small {
                                color: #666666;
                                font-size: 12px;
                                letter-spacing: 0;
                                line-height: 17px;
                            }

                            &>span {
                                margin: 0 8px 0 7px;
                            }
                        }
                    }
                }
            }

            .shift-hand-over-report-card-stats-section {
                border-radius: 4px;
                background-color: rgba(204, 204, 204, 0.2);
                padding: 16px;
                color: #0C2340;
                font-family: "Open Sans";

                .shift-hand-over-report-card-stats-sub-section {
                    margin-bottom: 12px;
                }

                .title {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                    margin-bottom: 12px;
                    font-weight: 600;
                }

                .shift-hand-over-report-card-stats-section-details {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .shift-hand-over-report-card-stats-section-detail-item {
                        margin-bottom: 5px;

                        &.base-50 {
                            flex: 50% 0 0;
                        }

                        &.base-100 {
                            flex: 100% 0 0;
                        }

                        .bold {
                            font-weight: 700;
                        }

                        .stats-link {
                            .large {
                                cursor: pointer;
                                color: #6244BB;
                                text-decoration: underline;
                                margin-right: 12px;
                            }
                        }

                        .last-visit-summary {
                            word-break: break-all;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        .large {
                            font-size: 24px;
                            letter-spacing: 0;
                            line-height: 33px;
                            margin-bottom: 0;
                        }

                        .small {
                            font-size: 14px !important;
                            letter-spacing: 0;
                            line-height: 24px;
                        }

                        .normal {
                            font-weight: normal;
                        }

                        .italic {
                            font-style: italic;
                        }

                        .par-title-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: baseline;

                            &>span {
                                margin-right: 12px;
                            }

                            .lessheight {
                                line-height: 43px;
                                letter-spacing: 0;
                                font-weight: 700;

                                .stats-link {
                                    cursor: pointer;
                                    color: #6244BB;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .align-percentage{
                        max-width: 33.33%;
                    }
                }
            }
        }

        .work-load-status {
            .shift-hand-over-report-card-info-section .shift-hand-over-report-card-info-section-row {
                .shift-hand-over-report-card-info-section-column {
                    .par-title-container {
                        .large {
                            width: 54px;
                            min-width: 54px;
                        }

                        .small {
                            color: #666666;
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 17px;
                        }

                        span.total-remaining-card {
                            width: auto;
                        }

                        &>span {
                            margin: 0 0 0 7px;
                        }

                        &>span:first-child {
                            margin: 0 0 0 0;
                        }

                        span.large {
                            font-size: 24px;
                            text-align: center;
                        }
                    }
                }
            }
        }

    }

    .shift-hand-over-report-level-summary {
        position: relative;
        margin-top: 5px;

        /* spinner */
        .spinner-container-holder {
            position: relative;
            height: 100%;

            .spinner-container {
                position: relative;
                min-height: 50px;
                top: 0;
                left: 0;
                height: 100%;
                background-color: #fff;
            }
        }

        .shift-hand-over-report-snapping-message {
            background-color: #ffffff;
            box-shadow: 4px 4px 8px 2px rgba(0, 0, 0, 0.08);
            border: 1px solid rgba(204, 204, 204, 0.75);
            border-radius: 8px;
            padding: 10px;
            color: #6422BB;

            span {
                cursor: pointer;
                text-decoration: underline;
                font-weight: 600;
            }
        }

        .shift-hand-over-report-shift-selection {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 20px;

            &>p {
                color: #333333;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                padding: 8px;
                margin: 0;
            }
        }

        .end {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            align-items: center;
            position: absolute;
            right: 0px;
            //top: 50px;

            .progress-text {
                font-style: italic;
            }

            .finalize-report {
                cursor: pointer;

                img {
                    margin-right: 8px;
                }
            }
        }

        .action-button {
            margin: 10px 10px 20px 10px;
            color: #6244BB;
            font-family: "Open Sans";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            border: none;
            background: none;
            font-weight: 600;

            &:disabled {
                opacity: 0.5;
                cursor: default;
            }
        }

        .bold {
            font-weight: 500;
        }
    }

    // accordion component
    .shift-hand-over-report-accordion-outer {
        padding: 16px;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
        font-family: 'Open Sans';
    }

    .shr-workload {
        padding: 5px 0;
        border-bottom: 1px solid #F8F8F8
    }

    .tech-header {
        background-color: rgba(174, 201, 235, 0.4);
    }

    .shift-hand-over-report-accordion-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 10px;

        p {
            margin-bottom: 2px;
        }

        &.hide {
            display: none;
        }

        &.margin-bottom-5 {
            margin-bottom: 5px;
        }

        &.separator {
            height: 1px;
            background-color: #CCCCCC;
            margin-bottom: 16px;
        }

        &.header {
            background-color: #0C2340;
            align-items: center;
            justify-content: center;
        }

        &.crew-level-header {
            background-color: #F6F6F6;
            align-items: center;
            justify-content: center;
            padding: 5px;
        }

        .center {
            text-align: center;
        }

        &:last-child {
            margin-bottom: 0px;
        }

        &.last-note {
            margin-top: -10px;

            p {
                padding: 5px;
                background: #EAEAF3;
                margin: 0;
                width: 100%;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
            }
        }

        .shift-hand-over-report-accordion-column {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            flex: 50% 0 0;

            &.base-40 {
                flex: 40% 0 0;
            }

            &.base-50 {
                flex: 50% 0 0;
            }

            &.base-70 {
                flex: 70% 0 0;
            }

            &.base-100 {
                flex: 100% 0 0;
            }

            &.base-30 {
                flex: 30% 0 0;
            }

            &.base-20 {
                flex: 20% 0 0;
            }

            &.base-25 {
                flex: 25% 0 0;
            }

            &.base-15 {
                flex: 15% 0 0;
            }

            &.base-10 {
                flex: 10% 0 0;
            }

            &.base-12 {
                flex: 12% 0 0;
            }

            &.base-5 {
                flex: 5% 0 0;
            }

            &.base-0 {
                flex: 0 1 0;
            }

            &.justify-end {
                justify-content: flex-end;
            }

            &.margin-right-1 {
                margin-right: 1%;
            }

            &.comments-section {
                justify-content: flex-end;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-grow: 1;

                .content-value {
                    word-break: break-all;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                }

                .crew-tech-comment-section {
                    display: flex;
                    flex: 100% 0 0;
                    flex-direction: row;
                    text-align: center;
                    justify-content: flex-end;
                    align-items: center;

                    .input-field-section {
                        position: relative;
                        flex: 80% 1 1;
                        display: flex;
                        margin-bottom: 15px;

                        .count {
                            position: absolute;
                            right: 120px;
                            bottom: -18px;
                            font-size: 1.2rem;
                            font-style: italic;
                            color: #949494;
                        }
                    }

                    .input-field {
                        flex: 80% 1 1;
                    }

                    input {
                        width: 100%;
                    }
                }
            }

            &.comments-header-column {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                flex-shrink: 1;
            }

            &.end {
                display: flex;
                justify-content: flex-end;
                flex-grow: 1;
                align-items: center;
                position: relative;
            }

            &.center {
                display: flex;
                justify-content: center;
            }

            &.expand-all-container {
                justify-content: center;
                align-items: center;
                display: flex;
                margin: 0 10px;
            }

            .notes-count-view {
                flex: 30% 0 0;
            }

            .stop-container {
                flex: 40% 0 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .outer-shell {
                margin-bottom: 10px;
            }

            .crew-notes-icon {
                height: 24px;
                width: 23px;
            }

            .crew-tech-comment-section {
                span {
                    margin-right: 10px;
                }
            }

            .link {
                color: #6244BB;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
                text-decoration: underline;
            }

            .comment-btn {
                height: 40px;
                color: #6244BB;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                border: 1px solid #6244BB;
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 8px 16px;
                font-weight: 600;

                &:disabled {
                    color: gray;
                    border-color: gray;
                }
            }

            .cancel-btn {
                margin-right: 10px;
            }

            .label {
                box-sizing: border-box;
                height: 24px;
                width: 80px;
                border: 1px solid #666666;
                border-radius: 32px;
                background-color: rgba(255, 255, 255, 0.8);
                color: #666666;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                padding: 2px 10px;
                margin-right: 12px;
            }

            .labelr {
                background-color: #EDB72B;
                color: #333333;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                display: inline-block;
                padding: 4px 8px;
                margin-right: 16px;
                font-weight: 600px;
            }

            .bold {
                font-weight: 600px;
            }

            .header-txt {
                color: #FFFFFF;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
            }

            .crew-level-header-text {
                color: #333333;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
            }

            .hint-text {
                color: #666666;
                font-family: "Open Sans";
                font-size: 16px;
                font-style: italic;
                letter-spacing: 0;
                line-height: 24px;
                padding-left: 5px;
            }

            .expand-all {
                color: #6244BB;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 22px;
                text-align: center;
                font-weight: 600;
                cursor: pointer;
            }

            &.note-text {
                background-color: #EAEAF3;
                flex: 73% 0 0;

                p {
                    color: #333333;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 17px;
                    padding: 8px;
                    margin: 0;
                    font-weight: 600;
                }

            }

            p {
                font-size: 16px;
                letter-spacing: 0;
                line-height: 24px;
                color: #333333;
            }

            .bold {
                font-weight: 600;
            }

            .wkload-dash-number-value {
                font-size: 16px;
                font-weight: 600;
            }

            .partial-indicator {
                position: relative;
                left: 0px;
                top: -11px;
                align-items: center;
                text-align: center;
                background-color: $united-purple;
                color: #ffffff;
                font-size: 12px;
                width: 150px;
            }
        }
    }

    .shift-hand-over-report-crew-level-summary {
        border: 1px solid #eee;

        .middle-align {
            justify-content: center;
            display: flex;
            align-items: center;
        }

        .crew-level-summary-loader {
            position: absolute;

            .spinner-container {
                background-color: transparent;
            }
        }

        .shift-hand-over-report-accordion-row {
            border-bottom: 1px solid #eee;
            font-size: 16px;

            .tech-assignment-counts {
                align-items: center;
                display: flex !important;
            }
        }

        .shift-hand-over-report-accordion-column {
            &.justify-right {
                display: flex;
                justify-content: flex-end;
                align-items: center
            }

            &.comments-section {
                flex: 40% 1 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            &.margin-right-1 {
                margin-right: 1%;
            }

            .labelr {
                line-height: 11px;
                height: 20px;
                padding: 5px;
            }

            p {
                margin: 0;
                padding: 5px 0;
            }
        }

        .shift-hand-over-report-crew-summary-expand {
            display: flex;
            flex: 100% 0 0;
            justify-content: center;
            flex-direction: column;

            .shift-hand-over-report-accordion-row {
                border-bottom: none;

                &.separator {
                    margin-left: 10%;
                    padding: 0;
                    width: 60%;
                }

                .shift-hand-over-report-accordion-column {
                    &.note-text {
                        flex: 60% 0 0;
                        margin-left: 10%;
                    }
                }
            }
        }
    }

    .crew-chevron-click {
        cursor: pointer;
        margin: 0 auto;
        height: 2.4rem;
        width: 4.5rem;
        text-align: center;
    }

    .collapse-visit {
        transform: rotate(-90deg);
    }

    .expand-visit {
        transform: rotate(90deg);
    }

    .hr-line {
        border-top: 1px solid #CCCCCC;
    }

    .notes-count-view {
        margin-top: 10px;

        svg {
            height: 22px;
            width: 40px;
        }
    }
}

.invalid-start {
    color: #D50032;
}

.invalid-start-message {
    width: 250px;
    padding-top: 5px;
    font-size: 12px;
    font-style: italic;
}


.shift-summary-modal {
    .modal-content-body {
        position: relative;

        .summary-notes-loader {
            position: absolute;
        }
    }
}