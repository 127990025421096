.addvisit.form-group.etr.row {
    margin-left: 0px;
    height: 235px;
  // .col-xs-2 {
  //     margin-right: 50px;
  //     height: 80px;
  // }
  // .col-xs-2 label {
  //   font-family: Open Sans;
  //   font-size: 14px;
  //   color: #333333;
  //   letter-spacing: 0;
  // }  
  .form-control {
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 6px;
    height: 45px;
    font-size: 14px;
  }  
  //  div.rdt {
  //   margin-right: 10px;
  // }
  .input-field {
    padding-right: 0px;
  }
  #PlannedInduction, #ETRDate {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .visit-calendar-icon {
    width: 45px;
    height: 45px;
  }
  .addvisit-info-msg {
    color: red;
  }
  .addvisit-calendar:hover {
    // background: #361A87;
  }
  .fa-angle-left {    
    transform: rotate(180deg);
    margin-left: 10px;
    margin-right: 7px;
  }
  .fa-angle-left-disabled {    
    transform: rotate(180deg);
    pointer-events: none;
    margin-left: 10px;
    margin-right: 7px;
  }
  .fa-angle-right {    
    margin-right: 10px;
  }
  .disabled {
    pointer-events: none;
    background-color: #E6E6E6;
  }
  .chevicon-disabled, .footer-disabled {
    pointer-events: none;
  }
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px; 
  margin-left: 0px; 
}
.not-valid { border: 1px solid red !important;}
.hideText {
  display: none;
}
.alert-icon {
  width: 16px;
  height: 16px;
  margin-top: 0;
  margin-right: 10px;
}
}