.filters-side-panel {
  // overflow-x: hidden;
  // overflow-y: auto;
  min-width: 328px;
  display: block;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  position: absolute;
  top: 0;
  right: 0;
  width: 328px;
  height: 70%;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  top: 240px;
  z-index: 1;
}
.filter-title {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
.filter-close {
  margin: 0px auto 0px 280px;
}
.filter-container {
  max-height: 57vh;
  overflow-x: hidden;
  overflow-y: auto;
}

fieldset.checkbox-filter-container {
  legend {
    font-size: 14px;
    font-weight: 600;
    padding-top: 14px;
    padding-left: 18px;
    border: none;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-bottom: 5px;
      margin-top: 5px;
    }

    button {
      margin-right: 17px;
      background: transparent;
    }
  }
}

.list-unstyled {
  &.show {
    display: inline-block;
    position: relative;
    max-height: 2000px;
    overflow: hidden;
    transition: max-height 0.65s ease-in;
    &:after {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 30px;
      left: 0;
    }
  }

  &.collapse {
    display: flex;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.05s ease-out;
  }
}
.filter-contents.show {
  &.show {
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 14px;
      left: 0;
      background-color: $alice-blue;
    }
  }

  &.collapse {
    display: flex;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.05s ease-out;
  }
}

fieldset.checkbox-filters {
  position: relative;
  display: block;
  .single-filter {
    margin-left: 18px;
  }
  legend {
    background-color: $white;
    font-size: 14px;
    font-weight: 600;
    padding-left: 18px;
    border: none;
    margin-bottom: 4px;
    width: 300px;

    label {
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 14px;
    }
  }

  label {
    color: $content-color;
    font-size: 14px;
  }

  ul {
    list-style: none;
    padding: 0 18px;
    margin-bottom: 0;
    //border: 1px solid #979797;

    li {
      float: left;
      //width: 33%;
      min-width: 87px;
      margin-top: 18px;
      display: inline-flex;
      border-bottom: 0.5px solid #979797;
    }
  }

  input {
    margin-right: 10px;
    width: 21px;
    height: 20px;
    vertical-align: text-top;
  }
  .checkbox-custom-label {
    z-index: 1;
  }
}
.filter-menu-section-calendar {
  .col-md-8 {
    padding-left: 35px;
    padding-bottom: 10px;
  }
  .col-md-8 label {
    font-size: 14px;
    font-weight: 300;
  }
  .rdt input {
    font-size: 14px;
    border: 1px solid $white;
    background: #41748d;
    color: $white;
    &::placeholder {
      color: white;
    }
  }
  .rdtTimeToggle {
    pointer-events: none;
    display: none;
  }
  .induction-range{
    display:flex;
    flex-direction: row;
    input{
      width: 15rem;
    }
  }
  .rts-range{
    display:flex;
    flex-direction: row;
    input{
      width: 15rem;
    }
  }
}

.col-xs-4.filter-button {
  padding-left: 20px;
}
.col-xs-6.filter-button {
  padding-left: 15px;
}
.col-xs-4.form-group.filter-button input {
  width: 130px;
  font-size: 13px;
  font-weight: 300;
  border: 1px solid #6244bb;
  color: #6244bb;
}
.col-xs-6.form-group.filter-button.disabled input {
  cursor: not-allowed;
  width: 130px;
  color: #666666;
  background: #e6e6e6;
  font-size: 13px;
  font-weight: 300;
  border-color: #666666;
}

.col-xs-6.form-group.filter-button input {
  cursor: pointer;
  width: 130px;
  font-size: 13px;
  font-weight: 300;
}
.btn.btn-outline-secondary.cancel-button:hover {
  background-color: #351987;
  color: white;
}
.btn.btn-primary.apply-filter:hover {
  background-color: #351987;
  color: white;
}
