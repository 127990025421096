$totalSummaryheight: $summaryHeight+$headerHeight ;
.dash8-panel {
    height: calc(100vh - #{$totalSummaryheight});
    width: $dash8PanelWidth;
    border-right: 1px solid #949494;
    overflow-y: auto;
    padding-right: 0px;
    .dash8-skeleton {
      img, span , label, .card-desc, svg {
        background: linear-gradient(325deg, #E6E6E6, #D3D3D3);
        background-size: 400% 400%;

        -webkit-animation: loaddata 2s ease infinite;
        -moz-animation: loaddata 2s ease infinite;
        animation: loaddata 2s ease infinite;
        color: transparent;
        border-color: #E6E6E6;
        animation: loaddata 2s infinite;
      }  
      svg, img, .switch, .btn-container.workload-search{
        display:none;
      }    
      label::after {
        background: linear-gradient(325deg, #E6E6E6, #D3D3D3);
        background-size: 400% 400%;

        -webkit-animation: loaddata 2s ease infinite;
        -moz-animation: loaddata 2s ease infinite;
        animation: loaddata 2s ease infinite;
        color: transparent;
        border-color: #E6E6E6;
        animation: loaddata 2s infinite;
      }
      span::before {
        background: linear-gradient(325deg, #E6E6E6, #D3D3D3);
        background-size: 400% 400%;

        -webkit-animation: loaddata 2s ease infinite;
        -moz-animation: loaddata 2s ease infinite;
        animation: loaddata 2s ease infinite;
        color: transparent;
        border-color: #E6E6E6;
        animation: loaddata 2s infinite;
      }
      .card-desc-holder {
        background-color:transparent;
      }
    }
}


//dsh8toggle css styles
.dash8-toggle {
    margin-top:20px;
    margin-bottom:20PX;
    display:flex;
    align-items: center;
    padding-right: 15px;
    .workload-label {
      font-size: 2rem;
      font-weight: $font-weight-bold;
    }
    .aircraft-no {
      font-weight:600;
      span {
        font-size:18px
      }
    }
.toggle-title {
    font-size:14px;
    color:#333333;
    padding-right:10px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 21px;
    margin-bottom:0px;
  }
  
  .switch input { 
    width: 26px;
    height: 18px;
  }
  .workload-search {
    margin-top: 0px;
    margin-left: auto;
  }
  
  input:checked {
    background-color: $united-purple;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $united-purple;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }
  
  .my-shift-link {
      color: #6244BB !important;
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: 700;
      text-decoration: underline;
  }
}

.time-remaining{
  padding: 0px 18px 12px 18px;
  display: flex;
  flex-direction: row;
    .work-stop-container{
      padding-left:20px;
      color: #d50032;
      margin-left: auto;
      margin-right: 40px;
    }
}