﻿@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;   
}
@mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
  }
  @mixin border-radius-left($radius) {
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
  }
  @mixin border-radius-right($radius) {
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
  }

  @mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    }
    @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin image($image) {
    background-image: url(#{$image});
}


@mixin image2x($image, $width: $img-sprite-width, $height: $img-sprite-height) {
    @media (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 2.0dppx) {
        // on retina, use image that's scaled by 2
        background-image: url(#{$image});
        background-size: $width $height;
    }
}

@mixin image3x($image, $width: $img-sprite-width, $height: $img-sprite-height) {
    @media (min--moz-device-pixel-ratio: 3), (-o-min-device-pixel-ratio: 3/1), (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3), (min-resolution: 3.0dppx) {
        // on retina, use image that's scaled by 3
        background-image: url(#{$image});
        background-size: $width $height;
    }
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}