.spinner-container-holder {
    position: fixed;    
    width: 100%;
    top: 0px;
    z-index: 10006;
}
.spinner-container {
  display:flex;
  height:100vh;
  width: 100%;
  position: absolute;
  top:130px;
  left: -15px;
  background-color:rgba(0,0,0,0.2);
  z-index:5;
  justify-content: center;
  align-items: center;
}
.spinner-overlay-container {
  position: absolute;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 95%;
  align-items: center;
  height: 95%;
  z-index: 10;
}
.spinner {
    //margin: 100px auto 0;
    width: 70px;
    margin:auto;
    height:50px;
  }
  
  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;  
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }



  .lds-ring, .lds-ring-mini {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  // .lds-ring-mini {    
  //   width: 40px;
  //   height: 40px;
  // }
  .lds-ring div, .lds-ring-mini div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    //border-color: #878CFC transparent transparent transparent;
    border-color: #5B5FC7 transparent transparent transparent;
  }
  .lds-ring-mini div{
    width: 32px;
    height: 32px;
  }
  .lds-ring div:nth-child(1), .lds-ring-mini div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2), .lds-ring-mini div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3), .lds-ring-mini div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }