.paper-container-header {
  background: $white;
  padding: 1rem 0;
  button {
    width: 20rem;
    line-height: normal;
    height: 4.5rem;
    &:hover {
      width: 20rem;
      // line-height: normal;
    }
  }
}
.more-actions-ellipsis {
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  &:hover {
    color: $white;
    background: rgba(96, 68, 187, 0.7);
    transform: scale(1.1);
    transition-duration: 0.3s;
    border-radius: 6px;
  }
  &.active {
    color: $white;
    background: $united-purple;
    transform: scale(1.1);
    transition-duration: 0.3s;
    border-radius: 6px;
  }
  &.disabled {
    color: $silver-gray;
    cursor: not-allowed;
    &:hover{
      color:$silver-gray;
      background:transparent;
    }
  }
  span {
    font-size: 3.5rem;
    font-weight: $font-weight-bold;
    height: 0.9rem;
    line-height: 0;
  }
}
.actions-container {
  position: relative;
}
.actions {
  // position: absolute;
  color: #333333;
  background: $white;
  font-size: $font-size-normal;
  border: 1px solid #333333;
  border-radius: 6px;
  // right: 10%;
  margin: 1rem;
  width: 16rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &.disabled {
    color: $silver-gray;
    cursor: not-allowed;
    &:hover{
      color:$silver-gray;
      background:transparent;
    }
  }
}
.paperImageIcon {
  padding-right: 3px;
}
.paper-completed {
  background-image: linear-gradient(green, green);
  background-repeat: no-repeat;
  background-size: 10px 100%, calc(100% - 4px) 100%;
  background-position: left;
}
.defer-removed-card {
  background-color: $pattens-blue !important;
}
.hashed-out {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(151, 151, 151, 0.5) 4px, rgba(151, 151, 151, 0.5) 6px);
}
.auto-na{
  background-image: repeating-linear-gradient(135deg, transparent, transparent 1px, rgba(151, 151, 151, 0.5) 4px, rgba(151, 151, 151, 0.5) 6px);
  margin: 0px -20px 0px -20px;
}
.exclude-cards {
  visibility: hidden;  
}
.exclude-item {
  font-size: 1.6rem;
}