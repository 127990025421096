.visit-info {
    padding-left: 10px;
    background-color: #0c2340;
    color: #fff;
    opacity: 0.9;
    .pr-6 {
        padding-right: 10rem;
    }
    .ml-6 {
        margin-left: 10rem;
    }
    .header {
        font-size: 1.8rem;
        font-weight: 400;
        padding-bottom: 2rem;
    }
    .visit-overview {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }    
    .tail-no,
    .check-type,
    .induction-date,
    .rts-date,
    .etr-date,
    .span-val {
        font-weight: bold;
        font-size: 2.4rem;
    }
    label {
        font-size: 1.6rem;
    }
    .icon-sets {
        display: flex;
        svg {
            height: 24px;
        }
        span {
            padding-left: 5px;
        }
    }
    .location-click {
        cursor: pointer;
        text-decoration: underline;
    }
    .box-content {
        .time {
            font-weight: $font-weight-bold;
        }
        .dates {
            display: flex;
            align-items: flex-end;
        }
        svg {
            width: 60px;
            height: 36px;
        }
    }
    .etr-change-count-value {
        height: 38px;
        width: 54px;
        border-radius: 13.06px;
        background-color: #cccccc;
        color: #002244;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    .action-link {
        font-size: 1.8rem;
        cursor: pointer;
        svg {
            height: 36px;
        }
    }
    .btn-container {
        display: flex;
        align-items: center;
    }
    .timeline {
        font-size: 1.6rem;
        color: #6244bb;
        font-weight: 600;
        line-height: 2rem;
    }
    .vs-dates,
    .fleet-info {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .calendar-only-icon {
        height: 2rem;
    }
    .calendar-icon {
        padding: 1rem 0;
    }
    .btn-primary,
    .btn-primary:hover,
    .btn-primary:active,
    .btn-primary.active {
        color: #333333;
        background-color: #fff;
        width: 13.5rem;
        font-weight: 400;
        font-size: 1.6rem;
    }
    .btn-secondary,
    .btn-secondary:hover,
    .btn-secondary:active,
    .btn-secondary.active {
        color: #fff;
        background-color: #0c2340;
        border: 1px solid #fff;
        width: 13.5rem;
        font-weight: 400;
        font-size: 1.6rem;
    }
}
.visit-dashboard-stats {
    padding: 35px;
    width: 97%;
    margin: 0 -15px;
    .stats-header-title {
        font-size: 20px;
        font-weight: $font-weight-semi-bold;
        padding-bottom: 15px;
    }
    .tile-large {
        //width: 40%;
        min-width: 440px;
        max-width: 440px;
        padding-right: 15px;
    }
    .vertical-line {
        border-left: 1px solid #CCCCCC;
        margin-right: 30px;
        margin-left: 15px;
        height: 18.3em;
    }
    // .tile-medium {
    //     width: 30%;
    // }
    .tile-small {
        //width: 20%;
        min-width: 215px;
        max-width: 215px;
        padding-right: 15px;
    }
    .tile-medium {
        min-width: 300px;
        max-width: 300px;
    }
    .tile-xlarge {
        min-width: 455px;
        max-width: 455px;
        padding-right: 15px;
    }
    .stats-tile {
        height:255px;
        width: 100%;
        .stats-baseline-date{
            svg{
                margin-left: 10px;
            }
        }
    }
    .btn-view-workload{
        font-size: 18px;
        font-weight: $font-weight-semi-bold;
        width: auto;
    }
}
.loading {
    label,
    span,
    .btn-visit-info,
    .visit-info.visit-info-section-content.box.etr-change-count-value,
    .schedule-group-btn {
        //background-color: #E6E6E6 !important;
        background: linear-gradient(325deg, #e6e6e6, #d3d3d3);
        background-size: 400% 400%;

        -webkit-animation: loaddata 2s ease infinite;
        -moz-animation: loaddata 2s ease infinite;
        animation: loaddata 2s ease infinite;
        color: transparent !important;
        border-color: #e6e6e6;
        animation: loaddata 2s infinite;
    }
    .visit-info-section-content .box-visit-stats .visit-stats-link .inactive-link,
    .etr-change-count-value {
        color: transparent;
    }
    svg {
        display: none;
    }
}

@-webkit-keyframes loaddata {
    0% {
        background-position: 0% 23%;
    }
    50% {
        background-position: 100% 78%;
    }
    100% {
        background-position: 0% 23%;
    }
}
@-moz-keyframes loaddata {
    0% {
        background-position: 0% 23%;
    }
    50% {
        background-position: 100% 78%;
    }
    100% {
        background-position: 0% 23%;
    }
}
@keyframes loaddata {
    0% {
        background-position: 0% 23%;
    }
    50% {
        background-position: 100% 78%;
    }
    100% {
        background-position: 0% 23%;
    }
}

.shift-details {
    width: 120px;
    background-color: #b6b8dc;
    height: 64px;
    margin-top: 10px;
    color: #0c2340;
    font-size: $font-size-small;
    font-weight: $font-weight-semi-bold;
    align-items: center;
    &-header,
    &-content {
        border: 1px solid #0c2340;
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        div:first-child {
            border-right: 1px solid #0c2340;
        }
    }
    &-header {
        > div {
            width: 50%;
            height: 24px;
            line-height: 24px;
        }
        .shift-date {
            color: #ffffff;
            background-color: #0c2340;
        }
    }
    &-content {
        border-top: 0px;
        font-size: $font-size-large;
        > div {
            width: 50%;
            height: 36px;
            line-height: 24px;
        }
    }
}

.shift-details-title {
    font-size: 1.4rem;
    font-weight: 400;
    color: #333333;
}
.plane-img {
    transform: rotate(-90deg);
    height: 40px;
}
.skeleton {
    .shift-details {
        background: none;
        &-header,
        &-content {
            border-color: #e6e6e6;
            div:first-child {
                border-right: #e6e6e6;
            }
            .shift-date {
                background: none;
            }
        }
    }
}
.start-visit-modal {
    min-height: auto;
    max-width: 76rem;
    margin: 4rem;
    padding: 0;
    .modal-content-header {
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 5rem;
        padding: 1rem;
        h3 {
            color: white;
            margin-left: 1rem;
        }
    }
    .btn-outline-primary {
        width: auto;
        border: none;

        &:hover {
            border: none;
            color: #6244bb;
            background-color: white;
        }
    }
    .modal-content-body {
        padding: 2rem;
        .not-valid {
            border: 1px solid red;
        }
    }
    .modal-content-footer {
        padding-bottom: 2rem;
        padding-right: 2rem;
    }
    .day-selection {
        background-color: #f7f7f7;
        padding: 1rem;
        .informational {
            color: #666666;
            font-size: 12px;
            &.warning {
                color: #d50032;
            }
            .alert-icon {
                width: 16px;
                height: 16px;
                margin-top: 0;
                margin-right: 10px;
            }
        }
    }
}
.pick-a-template-container {
    display: flex;
    justify-content: center;
    color: #333333;
    height: 67vh;
    background-color: #e6e6e6;
    margin-left: -15px;
    margin-right: -15px;
    .pick-a-template {
        color: #333333;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 90px;
        .header {
            font-size: 2.8rem;
            font-weight: bold;
            padding-bottom: 1rem;
        }
        .content {
            font-size: 1.6rem;
            padding-bottom: 1rem;
        }
        .btn-primary {
            font-size: 1.6rem;
        }
    }
}

.error-modal {
      .errorColor{
          padding-bottom: 50px; 
        }
       .addButtonMargin{
        margin-left: 80%;
       } 
      .alert-icon {
        width: 38px;
        height: 38px;
        margin-top: 20px;
        margin-left: 30px;
      }
      .col.modal-rts {
        font-family: Open Sans;
        color: #333333;
        width: 714px;
        height: 160px;
        margin-top: 2%;
        .error-header {
            font-size: 20px;
            font-weight: 700;
        }
        .info {
            font-size: 18px;
            padding-top:15px;
            padding-right: 20px;
            padding-bottom: 20px;
        }
        .pending-work-items {
            display: flex;
            // height:215px;
            overflow-y: auto;
            flex-direction: row;
            // padding-left: 30px;
            font-weight: 600;
            color:#666666;
            // height: 150px;
            // overflow-y: scroll;
            margin-top: 20px;
            .col-8, .col-4 {
                padding: 0px;
            }
            .title {
                color: #999999;
                font-size: 16px;
                padding-bottom: 5px;
            }
            
        }
        .rts-list-container{
            height: 160px;
            overflow-y: auto;
            .rts-list {
                display: flex;
                color: #6244BB;
                font-size:18px;
                .col-8{
                    padding-left: 0;
                }
            }
        }
      }    
    }
  
