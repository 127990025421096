.modal-content {
    // width: 88rem;
    // border: none;
    // padding: 0;
    // border-radius: 10px 10px 6px 6px;

    .modal-header {
        display: flex;
        justify-content: space-between;
        border-radius: 6px 6px 0 0;
        background-color: #0c2340;
        align-items: center;
        height: 5rem;
        padding: 1rem;

        h3 {
            color: white;
            font-weight: 600;
            margin-left: 1rem;
            font-size: 24px;
        }

        .close-icon {
            margin-right: 1rem;
            height: 1.5rem;
            cursor: pointer;
        }
    }

    .modal-body {

    }

    .modal-footer {

    }
}

.spc-modal-content   {
    width: 88rem;
    border: none;
    padding: 0;
    border-radius: 10px 10px 6px 6px;
}