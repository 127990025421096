@import './Templates/templates';
@import './TaskCardDetails/taskcarddetails';
@import './TemplateDetails/templatedetails';

.defaultMissingSearch {
    .RadioButton {
        label {
            &:after {
                background: #fff;
            }
        }

        input[type="radio"] {
            &+label {
                &:before {
                    border-color: #fff;
                }
            }

            &:checked {
                &+label {
                    &:after {
                        background: #fff !important;
                    }

                    &:before {
                        border-color: #fff !important;
                    }
                }
            }
        }
    }
}

.taskcard-details-icon-container {
    position: relative;
    width: 32px;

    .missing-items-marker {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        top: 0;
        right: 0;
    }
}

div.task-card-section div.dash1-status-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .dash1-status-container-columns {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
}

.dash1-status {
    display: inline;
    align-self: center;
    border-radius: 12px;
    padding: 3px 10px 3px 10px;
    font-size: 14px;
    background-color: $carrot-orange;
    margin: 0 0 5px 0px;
    font-weight: 600;
}

.exclude-status {
    margin-left: 43px;
}