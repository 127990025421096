.releasenote-container {
    color: #333333;
    padding: 8px;

    .releasenote-title {
        margin-bottom: 24px;

        p:first-child {
            font-size: 20px;
            margin-bottom: 5px;
        }

        p:last-child {
            font-size: 16px;
            margin-bottom: 5px;
        }

        
            .highlight {
                font-weight: 600;
            }
        
    
    }
    .releasenote-section {
        margin-bottom: 24px;

        .releasenote-details{
            margin-bottom: 20px;
        }
        
        p:first-child {
            font-size: 20px;
            margin-bottom: 5px;
        }

        p:last-child {
            font-size: 16px;
            margin-bottom: 5px;
        }

        
            .highlight {
                font-weight: 600;
            }
        
    
    }
}